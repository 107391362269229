import React, { useState, useEffect, memo } from 'react';
import {
  Modal,
  TextField,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { useSnackbar } from 'notistack';
import { fetchInterest, updateInterest } from 'slices/interest';
import { CloseIcon, EditIcon, SuccessIcon } from 'components/Icon/icons';
import { Interest } from 'types/interest';
import AddInterest from './AddInterest';
import 'theme/styles/table.scss';

function InterestProbs() {
  const dispatch = useDispatch<AppDispatch>();
  const interests = useSelector((state: RootState) => state.interest.interestList);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedInterest, setSelectedInterest] = useState<{
    id: number;
    name: string;
    percent: number;
  } | null>(null);

  useEffect(() => {
    dispatch(fetchInterest());
  }, [dispatch]);

  const handleOpenModal = (interest: { id: number; name: string; percent: number }) => {
    setSelectedInterest(interest);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedInterest(null);
    setModalOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    if (selectedInterest) {
      setSelectedInterest({
        ...selectedInterest,
        [name]: name === 'percent' ? parseFloat(value) || 0 : value, // Перетворюємо тільки percent
      });
    }
  };

  const validateInterest = (name: string, percent: number): string | null => {
    if (!name.trim()) {
      return 'Назва націнки не може бути порожньою.';
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(percent) || percent <= 0) {
      return 'Значення націнки має бути числом більше 0.';
    }
    return null;
  };

  const handleSave = async () => {
    if (!selectedInterest) return;
    const validationError = validateInterest(selectedInterest.name, selectedInterest.percent);
    if (validationError) {
      // eslint-disable-next-line no-alert
      alert(validationError);
      return;
    }
    try {
      await dispatch(updateInterest(selectedInterest));
      await dispatch(fetchInterest());
      enqueueSnackbar(`Націнку оновлено.`, {
        variant: 'success',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(`Помилка під час оновлення націнки.`, {
        variant: 'error',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } finally {
      handleCloseModal();
    }
  };

  return (
    <>
      <Table className="table-cell">
        <TableHead>
          <TableRow>
            <TableCell className="table-cell-id">ID</TableCell>
            <TableCell className="table-cell-percent">Націнка, %</TableCell>
            <TableCell className="table-cell-description">Призначення</TableCell>
            <TableCell className="table-cell-icon" align="right">
              <AddInterest />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {interests.map((interest: Interest) => (
            <TableRow key={interest.id} className="Accordion-row">
              <TableCell>{interest.id}</TableCell>
              <TableCell>{interest.percent}</TableCell>
              <TableCell>{interest.name}</TableCell>
              <TableCell align="right">
                <Tooltip title="Редагувати націнку" arrow>
                  <SvgIcon
                    fontSize="small"
                    style={{
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                    onClick={() =>
                      handleOpenModal({
                        id: interest.id,
                        name: interest.name,
                        percent: interest.percent,
                      })
                    }
                  >
                    <EditIcon />
                  </SvgIcon>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* Модальне вікно */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>ID {selectedInterest?.id}</h2>
          <TextField
            name="name"
            label="Призначення"
            value={selectedInterest?.name}
            onChange={handleChangeRate}
            fullWidth
          />
          <TextField
            name="percent"
            label="Нова націнка"
            type="number"
            value={selectedInterest?.percent || ''}
            onChange={handleChangeRate}
            fullWidth
          />
          <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon color="red" />
            </SvgIcon>
            <SvgIcon
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
            >
              <SuccessIcon color="green" />
            </SvgIcon>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default memo(InterestProbs);
