import React, { FC, memo, useEffect, useRef, DOMAttributes } from 'react';
import clsx from 'clsx';

import { formatToStringWithPx } from 'utils/format';
import type { IconType } from './IconType';
import { iconTypes } from './IconType';
import './Icon.scss';

const getIcon = (type: string) => iconTypes.get(type);

interface IProps extends DOMAttributes<HTMLSpanElement> {
  className?: string;
  dataTestId?: string;
  height?: number;
  size?: number;
  type: IconType;
  width?: number;
}

const Component: FC<IProps> = ({ className, dataTestId = 'Test__icon', height, width, size, type, ...rest }) => {
  const iconRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (iconRef.current) {
      if (size && !height && !width) {
        iconRef.current.style.setProperty('--icon-height', formatToStringWithPx(size));
        iconRef.current.style.setProperty('--icon-width', formatToStringWithPx(size));
      } else if (!size && height && width) {
        iconRef.current.style.setProperty('--icon-height', formatToStringWithPx(height));
        iconRef.current.style.setProperty('--icon-width', formatToStringWithPx(width));
      }
    }
  }, [height, size, width]);

  return (
    <div className={clsx('Icon', className)} data-testid={dataTestId} ref={iconRef} {...rest}>
      {getIcon(type)}
    </div>
  );
};

export const Icon = memo(Component);
