import React, { useState } from 'react';
import { Modal, TextField, SvgIcon, Tooltip, Select, MenuItem } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { CloseIcon, SuccessIcon, PlusIcon } from 'components/Icon/icons';
import { useSnackbar } from 'notistack';
import { createDiscount, fetchDiscount } from 'slices/discount';
import 'theme/styles/modal.scss';

function AddDiscount() {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [name, setName] = useState('');
  const [percent, setPercent] = useState<number | ''>('');
  const [supplierId, setSupplierId] = useState<string | ''>('');

  const supplierOptions = useSelector((state: RootState) =>
    state.suppliers.suppliersList.map(supplier => ({
      id: supplier.id,
      name: supplier.name,
      active: supplier.active,
    })),
  );

  const handleOpenModalAddDiscount = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setName('');
    setPercent('');
    setSupplierId('');
    setModalOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const validateDiscount = (name: string, percent: number): string | null => {
    if (!name.trim()) {
      return 'Назва знижки не може бути порожньою.';
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(percent) || percent <= 0) {
      return 'Значення знижки має бути числом більше 0.';
    }
    return null;
  };

  const handleSave = async () => {
    const validationError = validateDiscount(name, Number(percent));
    if (validationError) {
      // eslint-disable-next-line no-alert
      alert(validationError);
      return;
    }

    try {
      const result = await dispatch(
        createDiscount({
          name,
          percent: Number(percent),
          supplierId,
        }),
      );
      if (createDiscount.fulfilled.match(result)) {
        enqueueSnackbar(`Нову знижку створено.`, {
          variant: 'success',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        await dispatch(fetchDiscount());
      } else {
        enqueueSnackbar(`Помилка під час зберігання знижки.`, {
          variant: 'error',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        // eslint-disable-next-line no-console
        console.error(result.payload || 'Unknown error occurred');
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(`Помилка під час зберігання знижки.`, {
        variant: 'error',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } finally {
      handleCloseModal();
    }
  };

  return (
    <div style={{ margin: -12 }}>
      <Tooltip title="Додати нову знижку" arrow>
        <SvgIcon
          fontSize="small"
          style={{
            marginRight: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
          onClick={handleOpenModalAddDiscount}
        >
          <PlusIcon />
        </SvgIcon>
      </Tooltip>
      {/* Модальне вікно */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>Додати нову знижку</h2>
          <TextField
            label="Призначення"
            value={name}
            onChange={e => setName(e.target.value)}
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <Select
            name="supplierId"
            value={supplierId || ''}
            onChange={(e: React.ChangeEvent<{ name?: string; value: unknown }>) =>
              setSupplierId(e.target.value as string)
            }
            fullWidth
            style={{ marginBottom: 20 }}
          >
            {supplierOptions
              .filter(option => option.active) // Фільтруємо тільки активних постачальників
              .map(option => (
                <MenuItem key={option.id} value={option.id}>
                  {option.name}
                </MenuItem>
              ))}
          </Select>
          <TextField
            label="Знижка, %"
            type="number"
            value={percent}
            onChange={e => setPercent(Number(e.target.value))}
            fullWidth
          />
          <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon color="red" />
            </SvgIcon>
            <SvgIcon
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
            >
              <SuccessIcon color="green" />
            </SvgIcon>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddDiscount;
