import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'store';
import type { Material } from 'types/material';
import type {
  TextileComponentKey,
  WebbingComponentKey,
  ComponentGroupKey,
  FurnitureComponentKey,
  CurtainRodComponentKey,
  AccessoryComponentKey,
} from 'types/ProductMaterials';
import apiClient from '../api/coreAPI';

type SelectedMaterials = {
  textile: Record<TextileComponentKey, Material | undefined>;
  webbing: Record<WebbingComponentKey, Material | undefined>;
  furniture: Record<FurnitureComponentKey, Material | undefined>;
  curtainRod: Record<CurtainRodComponentKey, Material | undefined>;
  accessory: Record<AccessoryComponentKey, Material | undefined>;
  /**
   * Попри назву властивості у множинній формі, вона призначена для
   * зберігання матеріалу на одну єдину довільну складову.
   * Таке рішення доцільне, оскільки інтерфейсу на вибір матеріалів кількох довільних складових ніколи не буде.
   */
  freeformComponents: Material | undefined;
};
type SelectedMaterialGroup = Exclude<ComponentGroupKey, 'productFulfillment'> | 'freeformComponents';
type MaterialsHistory = Record<SelectedMaterialGroup, Material[]>;

interface MaterialsState {
  materialsList: Material[];
  materialsHistory: MaterialsHistory;
  selectedMaterials: SelectedMaterials;
  loading: boolean;
}

const initialState: MaterialsState = {
  materialsList: [],
  materialsHistory: {
    textile: [],
    webbing: [],
    furniture: [],
    curtainRod: [],
    accessory: [],
    freeformComponents: [],
  },
  selectedMaterials: {
    textile: {
      mainTextile: undefined,
      secondaryTextile: undefined,
      thirdTextile: undefined,
      fourthTextile: undefined,
      edgingTextile: undefined,
      liningTextile: undefined,
      doublerine: undefined,
    },
    webbing: {
      webbing: undefined,
      webbing4Stick: undefined,
      webbingAdhesive: undefined,
      webbingDecorative: undefined,
    },
    furniture: {
      grommet: undefined,
      sRings: undefined,
      magnets: undefined,
      zipper: undefined,
      syntheticFluff: undefined,
      sintepon: undefined,
    },
    curtainRod: {
      romanBlindSystem: undefined,
      sticks: undefined,
      fixation: undefined,
      weightingAgent: undefined,
      chain: undefined,
      chainWeighter: undefined,
      miniCurtainRod: undefined,
      curtainRod: undefined,
      curtainRod2: undefined,
      curtainProfile: undefined,
      curtainProfile2: undefined,
      handpiece: undefined,
      handpiece2: undefined,
      cap: undefined,
      cap2: undefined,
      stopper: undefined,
      stopper2: undefined,
      pipeConnector: undefined,
      pipeConnector2: undefined,
      clipsFasten: undefined,
      bracketFasten: undefined,
      wallBracket: undefined,
      bracketExtension: undefined,
      hookSigma: undefined,
      runners: undefined,
      runners2: undefined,
      rings4CurtainRod: undefined,
      rings4CurtainRod2: undefined,
      motor: undefined,
      thirdPipeHolder: undefined,
      bendingCurtainRod: undefined,
      lambrequinLath: undefined,
    },
    accessory: {
      hookDrapery: undefined,
      rosette: undefined,
      pin: undefined,
      tieBack: undefined,
      curtainGuides: undefined,
      powerSupplyUnit: undefined,
      charger: undefined,
      remoteControlUnit: undefined,
      smartHub: undefined,
    },
    freeformComponents: undefined,
  },
  loading: false,
};
// TODO for future implementation
export const fetchMaterials = createAsyncThunk(
  'fetchMaterials',
  async (filter: Record<string, string | string[] | Record<string, string>>) => {
    const { data } = await apiClient.get('/materials', {
      params: { filter },
    });
    return data;
  },
);

const slice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setMaterialsList: (state, { payload }) => {
      if (Array.isArray(payload)) state.materialsList = payload;
      else state.materialsList = [];
    },
    setSelectedMaterials: (
      state,
      {
        payload,
      }: {
        payload: {
          selection: any;
          group: SelectedMaterialGroup;
        };
        type: string;
      },
    ) => {
      state.selectedMaterials[payload.group] = payload.selection;
    },
    resetSelectedMaterials: (state, { payload }: PayloadAction<SelectedMaterialGroup | undefined>) => {
      if (!payload) state.selectedMaterials = initialState.selectedMaterials;
      switch (payload) {
        case 'textile':
          state.selectedMaterials.textile = initialState.selectedMaterials.textile;
          break;
        case 'webbing':
          state.selectedMaterials.webbing = initialState.selectedMaterials.webbing;
          break;
        case 'furniture':
          state.selectedMaterials.furniture = initialState.selectedMaterials.furniture;
          break;
        case 'curtainRod':
          state.selectedMaterials.curtainRod = initialState.selectedMaterials.curtainRod;
          break;
        case 'accessory':
          state.selectedMaterials.accessory = initialState.selectedMaterials.accessory;
          break;
        case 'freeformComponents':
          state.selectedMaterials.freeformComponents = undefined;
          break;
        default:
          break;
      }
    },
    setMaterialsHistory: (
      state,
      {
        payload,
      }: {
        payload: {
          materials: Material[];
          group: SelectedMaterialGroup;
        };
        type: string;
      },
    ) => {
      const historyMaterialIds = state.materialsHistory[payload.group]
        .map(item => item.id)
        .filter(item => typeof item === 'string');
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const uniqueMaterialsForHistory = payload.materials.filter(item => !historyMaterialIds.includes(item.id!));
      state.materialsHistory[payload.group].unshift(...uniqueMaterialsForHistory);
    },
    resetMaterialsHistory: (
      state,
      { payload }: PayloadAction<Exclude<ComponentGroupKey, 'productFulfillment'> | undefined>,
    ) => {
      if (payload) state.materialsHistory[payload] = [];
      else state.materialsHistory = initialState.materialsHistory;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchMaterials.pending, state => {
      state.loading = true;
    });
    builder.addCase(fetchMaterials.fulfilled, (state, { payload }) => {
      state.materialsList = payload;
      state.loading = false;
    });
    builder.addCase(fetchMaterials.rejected, state => {
      state.loading = false;
    });
  },
});

export const getMaterialsList = (state: RootState): Material[] => state.materials.materialsList;

export const getSelectedMaterials = (state: RootState): SelectedMaterials => state.materials.selectedMaterials;
export const getSelectedTextileMaterials = (state: RootState): Record<TextileComponentKey, Material | undefined> =>
  state.materials.selectedMaterials.textile;
export const getSelectedWebbingMaterials = (state: RootState): Record<WebbingComponentKey, Material | undefined> =>
  state.materials.selectedMaterials.webbing;
export const getSelectedFurnitureMaterials = (state: RootState): Record<FurnitureComponentKey, Material | undefined> =>
  state.materials.selectedMaterials.furniture;
export const getSelectedCurtainRodMaterials = (
  state: RootState,
): Record<CurtainRodComponentKey, Material | undefined> => state.materials.selectedMaterials.curtainRod;
export const getSelectedAccessoryMaterials = (state: RootState): Record<AccessoryComponentKey, Material | undefined> =>
  state.materials.selectedMaterials.accessory;
export const getSelectedFreeformComponentMaterial = (state: RootState): Material | undefined =>
  state.materials.selectedMaterials.freeformComponents;
export const getMaterialsHistory = (state: RootState) => state.materials.materialsHistory;
export const getTextileMaterialsHistory = (state: RootState): Material[] => state.materials.materialsHistory.textile;
export const getWebbingMaterialsHistory = (state: RootState): Material[] => state.materials.materialsHistory.webbing;
export const getFurnitureMaterialsHistory = (state: RootState): Material[] =>
  state.materials.materialsHistory.furniture;
export const getCurtainRodMaterialsHistory = (state: RootState): Material[] =>
  state.materials.materialsHistory.curtainRod;
export const getAccessoryMaterialsHistory = (state: RootState): Material[] =>
  state.materials.materialsHistory.accessory;
export const getFreeformComponentMaterialsHistory = (state: RootState): Material[] =>
  state.materials.materialsHistory.freeformComponents;
export const {
  reducer,
  actions: {
    setMaterialsList,
    setSelectedMaterials,
    setMaterialsHistory,
    resetMaterialsHistory,
    resetSelectedMaterials,
  },
} = slice;
