import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { pull } from 'lodash';

import { RootState } from 'store';
import type { Material } from 'types/material';
import type { CreatingDocumentState } from 'domain/DocGenerator/CreatingDocumentState';
import { loadState } from 'utils/localStorage';
import { ROWS_LIMIT } from 'constants/table';

export interface Filters {
  selectedTypes: string[];
  filteredColours: any;
  filteredBrands: any;
  selectedBrands: string[];
  selectedColours: string[];
  category: string;
  supplier?: string;
  supplierId?: string;
  search: string;
  page: number;
  limit: number;
  brand?: string[];
  colour?: string[];
}

interface Materials {
  allMaterials: Material[];
  selectedMaterials: string[];
  filteredMaterials: string[];
  filters: Filters;
  awaitedDocuments: CreatingDocumentState[];
}

const persistedState = loadState('materials_tool');
const initialState: Materials = {
  selectedMaterials: [],
  allMaterials: [],
  filteredMaterials: [],
  filters: persistedState?.filters ?? {
    category: 'textile', // Set default category to 'textile'
    supplier: 'all',
    supplierId: 'all',
    search: '',
    page: 0,
    limit: ROWS_LIMIT,
    selectedBrands: [],
    selectedColours: [],
  },
  awaitedDocuments: persistedState?.awaitedDocuments ?? [],
};

const slice = createSlice({
  name: 'representative',
  initialState,
  reducers: {
    setSelectedMaterial: (state, { payload }: PayloadAction<string>) => {
      state.selectedMaterials.push(payload);
    },
    removeSelectedMaterial: (state, { payload }: PayloadAction<string>) => {
      state.selectedMaterials = pull(state.selectedMaterials, payload);
    },
    setAllMaterials: (state, { payload }: PayloadAction<Material[]>) => {
      state.allMaterials = payload;
    },
    setFilteredMaterials: (state, { payload }) => {
      state.filteredMaterials = payload;
    },
    toggleAllSelectedMaterials: (state, { payload }) => {
      state.selectedMaterials = payload;
    },
    setFilter: (state, { payload }) => {
      if ('page' in payload === false) {
        payload.page = 0;
      }
      if ('page' in payload) {
        const totalPages = Math.ceil(state.allMaterials.length / state.filters.limit);
        payload.page = Math.max(0, Math.min(payload.page, Math.max(totalPages - 1, 0)));
      }
      state.filters = {
        ...state.filters,
        ...payload,
      };
      const filteredMaterials = (Array.isArray(state.allMaterials) ? state.allMaterials : []).filter(material => {
        const matchesCategory = state.filters.category === 'all' || material.type === state.filters.category;
        const matchesSupplier = state.filters.supplierId === 'all' || material.supplierId === state.filters.supplierId;
        const matchesBrand =
          state.filters.selectedBrands.length === 0 || state.filters.selectedBrands.includes(material.brand || '');
        const matchesColour =
          state.filters.selectedColours.length === 0 || state.filters.selectedColours.includes(material.colour || '');
        const matchesSearch =
          state.filters.search === '' || material.nameUk.toLowerCase().includes(state.filters.search.toLowerCase());
        return matchesCategory && matchesSupplier && matchesBrand && matchesColour && matchesSearch;
      });
      state.filteredMaterials = filteredMaterials
        .map(material => material.id)
        .filter((id): id is string => id !== undefined);
    },
    setAwaitedDocument: (state, { payload }) => {
      const index = state.awaitedDocuments.findIndex(
        docState => docState.templateKey === payload.templateKey && docState.dataHash === payload.dataHash,
      );
      if (index < 0) state.awaitedDocuments.push(payload);
      else state.awaitedDocuments[index] = payload;
    },
    deleteAwaitedDocument: (state, { payload }) => {
      const index = state.awaitedDocuments.findIndex(
        docState => docState.templateKey === payload.templateKey && docState.dataHash === payload.dataHash,
      );
      if (index === 0) state.awaitedDocuments.shift();
      else if (index === state.awaitedDocuments.length - 1) state.awaitedDocuments.pop();
      else if (index > 0) state.awaitedDocuments.splice(index, 1);
    },
  },
});

const selectSelf = (state: RootState): Materials => state.representative;

export const getSelectedMaterials = createSelector(selectSelf, state => state.selectedMaterials);
export const getAllMaterials = createSelector(selectSelf, state => state.allMaterials);
export const getFilters = createSelector(selectSelf, state => state.filters);
export const getFilteredMaterials = createSelector(selectSelf, state => state.filteredMaterials);
export const getAwaitedDocuments = createSelector(selectSelf, state => state.awaitedDocuments);
export const {
  reducer,
  actions: {
    setSelectedMaterial,
    removeSelectedMaterial,
    setAllMaterials,
    setFilter,
    toggleAllSelectedMaterials,
    setFilteredMaterials,
    setAwaitedDocument,
    deleteAwaitedDocument,
  },
} = slice;
