const categoryOptions = [
  {
    localeId: 'product.component.group.textile',
    value: 'textile',
  },
  {
    localeId: 'product.component.group.webbing',
    value: 'webbing',
  },
  {
    localeId: 'product.component.group.furniture',
    value: 'furniture',
  },
  {
    localeId: 'product.component.group.curtainRodAndAccessory',
    value: 'curtainRod',
  },
];

// constants/unitOptions.ts
const UNIT_OPTIONS = [
  { label: 'рулон', value: 'рулон' },
  { label: 'м.п.', value: 'м.п.' },
  { label: 'шт.', value: 'шт.' },
  { label: 'уп.', value: 'уп.' },
  { label: 'комплект', value: 'комплект' },
  { label: 'м2', value: 'м2' },
];

export default { categoryOptions, UNIT_OPTIONS };
