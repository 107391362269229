import type { ProjectStatus } from './project/status';

/**
 * Таблиця дозволів на доступ до певних інструментів за ролями користувачів
 *
 * Ключем таблиці є строка шляху до інструменту у одному з можливих форматів:
 * - повний шлях з усіма підтеками та символом '/' на початку
 * - лише базова тека шляху без символу '/'.
 * У другому варіанті налаштування доступу розповсюджується на всі внутрішні шляхи які НЕ задано окремим ключем першого варіанту.
 */
export const ROLE_CAN_ACCESS_TOOL_BY_ROUTE: Record<string, string[]> = {
  projects: ['admin', 'support', 'designer', 'manager', 'consultant', 'production'],
  'new-project': ['admin', 'support', 'designer', 'manager', 'consultant'],
  order: ['admin', 'support', 'designer', 'manager', 'consultant'],
  consultation: ['admin', 'support', 'designer', 'manager', 'consultant'],
  Multy: ['admin', 'support'],
  materials: ['admin', 'support', 'director', 'representative', 'accountant', 'consultant', 'designer', 'production'],
  '/materials/imports': ['admin', 'support', 'director', 'representative', 'accountant'],
  '/materials/imports/:importId': ['admin', 'support', 'director', 'representative', 'accountant'],
  '/materials/edit/:id': ['admin', 'support'],
  '/materials/add-textile': ['admin', 'support'],
  '/materials/add-webbing': ['admin', 'support'],
  '/materials/add-furniture': ['admin', 'support'],
  '/materials/add-curtain': ['admin', 'support'],
  'user-administration': ['admin', 'director'],
};

/**
 * Таблиця дозволів на редагування проєкту певного статусу по ролям користувачів його учасників
 *
 * TODO: для більшості статусів налаштований умовний початковий перелік ролей
 * тому потрібно доповнювати його в процесі розробки Платформи
 */
export const ROLE_CAN_EDIT_PROJECT_IN_STATUS: Record<ProjectStatus | 'all', string[]> = {
  all: ['admin', 'support', 'designer', 'manager'],
  new: ['consultant', 'designer', 'manager'],
  scheduledVisit: ['consultant', 'designer', 'manager'],
  measuring: ['designer', 'manager'],
  hover: ['consultant', 'designer', 'manager'],
  reserve: ['consultant', 'designer', 'manager'],
  prepaid: ['consultant', 'designer', 'manager'],
  started: ['designer', 'manager'],
  production: ['designer', 'manager'],
  completeSet: ['designer', 'manager'],
  mount: ['designer', 'manager'],
  mountDone: ['designer', 'manager'],
  hanging: ['designer', 'manager'],
  delivery: ['designer', 'manager'],
  surchargeReceived: [],
  refactoring: ['designer', 'manager'],
  repairing: ['designer', 'manager'],
  repairOrRefactorDone: ['designer', 'manager'],
  completed: ['designer', 'manager'],
  refusal: ['consultant', 'designer', 'manager'],
};

/**
 * Таблиця дозволів на реєстрування платежів щодо проєкту певного статусу по ролям користувачів його учасників
 *
 * TODO: для більшості статусів налаштований умовний початковий перелік ролей
 * тому потрібно доповнювати його в процесі розробки Платформи
 */
export const ROLE_CAN_REGISTER_PAYMENT_FOR_PROJECT_IN_STATUS: Record<ProjectStatus | 'all', string[]> = {
  all: ['admin', 'support'],
  new: ['consultant', 'designer', 'manager'],
  scheduledVisit: ['consultant', 'designer', 'manager'],
  measuring: ['consultant', 'designer', 'manager'],
  hover: ['consultant', 'designer', 'manager'],
  reserve: ['consultant', 'designer', 'manager'],
  prepaid: ['consultant', 'designer', 'manager'],
  started: ['consultant', 'designer', 'manager'],
  production: ['consultant', 'designer', 'manager'],
  completeSet: ['consultant', 'designer', 'manager'],
  mount: ['consultant', 'designer', 'manager'],
  mountDone: ['consultant', 'designer', 'manager'],
  hanging: ['consultant', 'designer', 'manager'],
  delivery: ['consultant', 'designer', 'manager'],
  surchargeReceived: ['consultant'],
  refactoring: ['consultant', 'designer', 'manager'],
  repairing: ['consultant', 'designer', 'manager'],
  repairOrRefactorDone: ['consultant', 'designer', 'manager'],
  completed: [],
  refusal: [],
};

/**
 * Набір дозволів на перегляд чернеток виробів у проєкті по ролям користувачів його учасників
 */
export const ROLE_CAN_SEE_DRAFT_PRODUCTS: string[] = ['admin', 'support', 'consultant', 'designer', 'manager'];

/**
 * Набір дозволів на перегляд даних клієнта у проєкті по ролям користувачів його учасників
 */
export const ROLE_CAN_SEE_CLIENT_DATA: string[] = [
  'admin',
  'support',
  'director',
  'accountant',
  'consultant',
  'designer',
  'manager',
  'production',
  'mounter',
  'hanger',
  'courier',
];

/**
 * Набір дозволів на перегляд даних з цінами матеріалів виробів у проєкті по ролям користувачів його учасників
 */
export const ROLE_CAN_SEE_PRODUCT_MATERIALS_PRICES: string[] = [
  'admin',
  'support',
  'director',
  'accountant',
  'consultant',
  'designer',
  'manager',
  'production',
];

/**
 * Таблиця дозволів на модифікацію матеріалів (додавання/редагування) по ролям користувачів
 */
export const ROLE_CAN_MODIFY_MATERIALS: string[] = ['admin', 'support', 'representative'];

/**
 * Таблиця заборони на експорт документів з асортиментом матеріалів по ролям користувачів
 */
export const ROLE_CAN_NOT_EXPORT_MATERIALS: string[] = [
  'intern',
  'courier',
  'hanger',
  'mounter',
  'distributor',
  'atelier',
];

/**
 * Таблиця дозволів на перегляд значень полів матеріалів, що мають обмеження на доступ
 */
export const ROLE_CAN_VIEW_RESTRICTED_MATERIAL_FIELDS: Record<string, string[]> = {
  admin: ['price', 'supplierCurrency', 'supplierPrice'],
  support: ['price', 'supplierCurrency', 'supplierPrice'],
  director: ['price', 'supplierCurrency', 'supplierPrice'],
  accountant: ['price', 'supplierCurrency', 'supplierPrice'],
  representative: ['supplierCurrency', 'supplierPrice'],
  consultant: ['price'],
  designer: ['price'],
  manager: ['price'],
  production: ['price'],
  atelier: ['price'],
  distributor: [],
  mounter: [],
  hanger: [],
  courier: [],
  intern: ['price'],
};

export default {
  ROLE_CAN_ACCESS_TOOL_BY_ROUTE,
  ROLE_CAN_EDIT_PROJECT_IN_STATUS,
  ROLE_CAN_REGISTER_PAYMENT_FOR_PROJECT_IN_STATUS,
  ROLE_CAN_SEE_DRAFT_PRODUCTS,
  ROLE_CAN_SEE_CLIENT_DATA,
  ROLE_CAN_SEE_PRODUCT_MATERIALS_PRICES,
  ROLE_CAN_MODIFY_MATERIALS,
  ROLE_CAN_NOT_EXPORT_MATERIALS,
  ROLE_CAN_VIEW_RESTRICTED_MATERIAL_FIELDS,
};
