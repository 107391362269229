import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../api/coreAPI';
import { RootState } from 'store';
import { Interest } from 'types/interest';
// import { loadState } from 'utils/localStorage';
import axios from 'axios';

// const persistedState = loadState('interest_tool');
// const persistedInterest = persistedState?.interestList ?? [];

type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';

// eslint-disable-next-line @typescript-eslint/naming-convention
interface Iinterest {
  interestList: Interest[];
  loading: FetchingStatus;
}

const initialState: Iinterest = {
  interestList: [],
  loading: 'idle',
};

export const fetchInterest = createAsyncThunk(
  'Interest',
  async () => {
    const { data } = await apiClient.get('/interests');
    return data;
  },
  {
    condition: (_, { getState }) => {
      const { interest } = getState() as RootState;
      if (interest?.loading === 'loading') {
        return false;
      }
      return true;
    },
  },
);

const slice = createSlice({
  name: 'interest',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchInterest.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchInterest.fulfilled, (state, { payload }) => {
        state.interestList = payload;
        state.loading = 'fulfilled';
      })
      .addCase(fetchInterest.rejected, state => {
        state.loading = 'rejected';
      });
  },
});

export const updateInterest = createAsyncThunk<void, { id: number; name: string; percent: number }>(
  'interest/saveRate',
  async ({ id, name, percent }) => {
    try {
      await apiClient.patch(`/interests/${id}`, { name, percent });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating supplier:', error);
      throw error;
    }
  },
);

export const clearInterest = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    clearState: state => {
      state.interestList = [];
      state.loading = 'idle';
    },
  },
});

export const createInterest = createAsyncThunk<
  void,
  { name?: string; group: string; percent: number; default?: boolean; companyId?: string }
>('interest/saveRate', async ({ name, group, percent, companyId }) => {
  try {
    await apiClient.post(`/interests`, {
      name,
      group,
      percent: Number(percent),
      default: false,
      companyId,
    });
  } catch (error) {
    if (axios.isAxiosError(error)) {
      // eslint-disable-next-line no-console
      console.error('Server error:', error.response?.data || error.message);
    } else {
      // eslint-disable-next-line no-console
      console.error('Unexpected error:', error);
    }
  }
});

export const { clearState } = clearInterest.actions;

export const { reducer } = slice;
