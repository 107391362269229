import React, { useState, useEffect, memo } from 'react';
import {
  Modal,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Select,
  MenuItem,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { fetchDiscount, updateDiscount } from 'slices/discount';
import { CloseIcon, EditIcon, SuccessIcon } from 'components/Icon/icons';
import { useSnackbar } from 'notistack';
import { fetchSuppliers, getSupplierMap } from 'slices/supplier';
import AddDiscount from './AddDiscount';
import 'theme/styles/table.scss';

function Discount() {
  const dispatch = useDispatch<AppDispatch>();
  const discounts = useSelector((state: RootState) => state.discount.discountList);
  const supplierMap = useSelector(getSupplierMap);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedDiscount, setSelectedDiscount] = useState<{
    id: number;
    name: string;
    percent: number;
    supplierId?: string;
  } | null>(null);

  useEffect(() => {
    dispatch(fetchDiscount());
    dispatch(fetchSuppliers());
  }, [dispatch]);

  const handleOpenModal = (discount: { id: number; name: string; percent: number; supplierId?: string }) => {
    setSelectedDiscount(discount);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedDiscount(null);
    setModalOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const supplierOptions = useSelector((state: RootState) =>
    state.suppliers.suppliersList
      .filter(supplier => supplier.active)
      .map(supplier => ({
        id: supplier.id,
        name: supplier.name,
      })),
  );

  const handleChangeSupplier = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    if (selectedDiscount) {
      setSelectedDiscount({
        ...selectedDiscount,
        supplierId: event.target.value as string,
      });
    }
  };

  const handleChangeRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    if (selectedDiscount) {
      setSelectedDiscount({
        ...selectedDiscount,
        [name]: name === 'percent' ? parseFloat(value) : value,
      });
    }
  };

  const validateDiscount = (name: string, percent: number): string | null => {
    if (!name.trim()) {
      return 'Назва знижки не може бути порожньою.';
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(percent) || percent <= 0) {
      return 'Значення знижки має бути числом більше 0.';
    }
    return null;
  };

  const handleSave = async () => {
    if (!selectedDiscount) return;
    const validationError = validateDiscount(selectedDiscount.name, selectedDiscount.percent);
    if (validationError) {
      // eslint-disable-next-line no-alert
      alert(validationError);
      return;
    }
    try {
      await dispatch(updateDiscount(selectedDiscount));
      await dispatch(fetchDiscount());
      enqueueSnackbar(`Знижку оновлено.`, {
        variant: 'success',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      enqueueSnackbar(`Помилка під час зберігання знижки.`, {
        variant: 'error',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } finally {
      handleCloseModal();
    }
  };

  return (
    <>
      <Table className="table-cell">
        <TableHead>
          <TableRow>
            <TableCell className="table-cell-id">ID</TableCell>
            <TableCell className="table-cell-percent">Знижка, %</TableCell>
            <TableCell className="table-cell-description">Опис</TableCell>
            <TableCell className="table-cell-supplier">Постачальник</TableCell>
            <TableCell className="table-cell-icon" align="right">
              <AddDiscount />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {discounts.map((discount: { id: number; name: string; percent: number; supplierId?: string }) => (
            <TableRow key={discount.id} className="Accordion-row">
              <TableCell>{discount.id}</TableCell>
              <TableCell>{discount.percent}</TableCell>
              <TableCell>{discount.name}</TableCell>
              <TableCell>{supplierMap[discount.supplierId || ''] || 'Немає постачальника'}</TableCell>
              <TableCell align="right">
                <Tooltip title="Редагувати знижку" arrow>
                  <SvgIcon
                    fontSize="small"
                    style={{
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                    onClick={() =>
                      handleOpenModal({
                        id: discount.id,
                        name: discount.name,
                        percent: discount.percent,
                        supplierId: discount.supplierId,
                      })
                    }
                  >
                    <EditIcon />
                  </SvgIcon>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      {/* Модальне вікно */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>ID {selectedDiscount?.id}</h2>
          <Select
            name="supplierId"
            value={selectedDiscount?.supplierId || ''}
            onChange={handleChangeSupplier}
            fullWidth
          >
            {supplierOptions.map(option => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
          <TextField
            name="name"
            label="Новий опис"
            value={selectedDiscount?.name}
            onChange={handleChangeRate}
            fullWidth
          />
          <TextField
            name="percent"
            label="Нова знижка"
            type="number"
            value={selectedDiscount?.percent}
            onChange={handleChangeRate}
            fullWidth
          />
          <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon color="red" />
            </SvgIcon>
            <SvgIcon
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
            >
              <SuccessIcon color="green" />
            </SvgIcon>
          </div>
        </div>
      </Modal>
    </>
  );
}
export default memo(Discount);
