import { PieChart as PieChartIcon } from 'react-feather';
import { UploadCloud as UploadCloudIcon } from 'react-feather';
import { FilePlus as FilePlusIcon } from 'react-feather';
import { Package as PackageIcon } from 'react-feather';
import { Grid as GridIcon } from 'react-feather';
import { MessageCircle as MessageCircleIcon } from 'react-feather';
import { Users as UsersIcon } from 'react-feather';

// eslint-disable-next-line import/prefer-default-export
export const sideBarRoutes = {
  admin: [
    {
      subheader: ' ',
      items: [
        {
          title: 'nav.userAdministration',
          icon: UsersIcon,
          href: '/user-administration',
        },
        {
          title: 'nav.projects',
          icon: GridIcon,
          href: '/projects',
        },
        {
          title: 'nav.materials',
          icon: PackageIcon,
          href: '/materials',
        },
        {
          title: 'nav.imports',
          icon: UploadCloudIcon,
          href: '/materials/imports',
        },
        {
          title: 'nav.admin',
          icon: PieChartIcon,
          href: '/administrate',
        },
      ],
      docsHelp: {
        href: 'https://docs.google.com/document/d/1HcpFi8ZCMlaqSwguGH0RSTwWSZ4NM1C3UNwp02MCe6M/',
        target: '_blank',
      },
    },
  ],
  designer: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.projects',
          icon: GridIcon,
          href: '/projects',
        },
        {
          title: 'nav.blankOrder',
          icon: FilePlusIcon,
          href: '/new-project',
        },
        {
          title: 'nav.materials',
          icon: PackageIcon,
          href: '/materials',
        },
        {
          title: 'nav.consultation',
          icon: MessageCircleIcon,
          href: '/consultation',
        },
      ],
      docsHelp: {
        href: 'https://docs.google.com/document/d/1HcpFi8ZCMlaqSwguGH0RSTwWSZ4NM1C3UNwp02MCe6M/',
        target: '_blank',
      },
    },
  ],
  support: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.projects',
          icon: GridIcon,
          href: '/projects',
        },
        {
          title: 'nav.materials',
          icon: PackageIcon,
          href: '/materials',
        },
        {
          title: 'nav.imports',
          icon: UploadCloudIcon,
          href: '/materials/imports',
        },
      ],
    },
  ],
  representative: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.materials',
          icon: PackageIcon,
          href: '/materials',
        },
        {
          title: 'nav.imports',
          icon: UploadCloudIcon,
          href: '/materials/imports',
        },
      ],
    },
  ],

  accountant: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/accountant/dashboard',
        },
        {
          title: 'nav.materials',
          icon: PackageIcon,
          href: '/materials',
        },
        {
          title: 'nav.imports',
          icon: UploadCloudIcon,
          href: '/materials/imports',
        },
      ],
    },
  ],
  director: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/director/dashboard',
        },
        {
          title: 'nav.userAdministration',
          icon: UsersIcon,
          href: '/user-administration',
        },
      ],
    },
  ],
  consultant: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.consultation',
          icon: MessageCircleIcon,
          href: '/consultation',
        },
        {
          title: 'nav.materials',
          icon: PackageIcon,
          href: '/materials',
        },
        {
          title: 'nav.projects',
          icon: GridIcon,
          href: '/projects',
        },
        {
          title: 'nav.blankOrder',
          icon: FilePlusIcon,
          href: '/new-project',
        },
      ],
      docsHelp: {
        href: 'https://docs.google.com/document/d/1HcpFi8ZCMlaqSwguGH0RSTwWSZ4NM1C3UNwp02MCe6M/',
        target: '_blank',
      },
    },
  ],
  manager: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.projects',
          icon: GridIcon,
          href: '/projects',
        },
        {
          title: 'nav.blankOrder',
          icon: FilePlusIcon,
          href: '/new-project',
        },
      ],
      docsHelp: {
        href: 'https://docs.google.com/document/d/1HcpFi8ZCMlaqSwguGH0RSTwWSZ4NM1C3UNwp02MCe6M/',
        target: '_blank',
      },
    },
  ],
  production: [
    {
      subheader: 'nav.main',
      items: [
        // {
        //   title: 'nav.dashboard',
        //   icon: PieChartIcon,
        //   href: '/production/dashboard',
        // },
        {
          title: 'nav.projects',
          icon: GridIcon,
          href: '/projects',
        },
        {
          title: 'nav.materials',
          icon: PackageIcon,
          href: '/materials',
        },
      ],
    },
  ],
  atelier: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/atelier/dashboard',
        },
      ],
    },
  ],
  distributor: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/distributor/dashboard',
        },
      ],
    },
  ],
  mounter: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/mounter/dashboard',
        },
      ],
    },
  ],
  hanger: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/hanger/dashboard',
        },
      ],
    },
  ],
  courier: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/courier/dashboard',
        },
      ],
    },
  ],
  intern: [
    {
      subheader: 'nav.main',
      items: [
        {
          title: 'nav.dashboard',
          icon: PieChartIcon,
          href: '/intern/dashboard',
        },
      ],
    },
  ],
};
