import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../api/coreAPI';
import { RootState } from 'store';
import { Currency } from 'types/currency';
import axios from 'axios';

type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';

interface Icurrency {
  currencyList: Currency[];
  loading: FetchingStatus;
}

const initialState: Icurrency = {
  currencyList: [],
  loading: 'idle',
};

export const fetchCurrency = createAsyncThunk(
  'getCurrency',
  async () => {
    const { data } = await apiClient.get('/currencies');
    return data;
  },
  {
    condition: (_, { getState }) => {
      const { currency } = getState() as RootState;
      if (currency?.loading === 'loading') {
        return false;
      }
      return true;
    },
  },
);
const slice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCurrency.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchCurrency.fulfilled, (state, { payload }) => {
        state.currencyList = payload;
        state.loading = 'fulfilled';
      })
      .addCase(fetchCurrency.rejected, state => {
        state.loading = 'rejected';
      });
  },
});
export const getCurrencies = (state: { currency: { currencies: any } }) => state.currency.currencies;

export const updateCurrency = createAsyncThunk<void, { id: number; convertRate: number }>(
  'currency/saveRate',
  async ({ id, convertRate }) => {
    try {
      await apiClient.patch(`/currencies/${id}`, { convertRate });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error updating supplier:', error);
      throw error;
    }
  },
);

export const clearCurrency = createSlice({
  name: 'currency',
  initialState,
  reducers: {
    clearState: state => {
      state.currencyList = [];
      state.loading = 'idle';
    },
  },
});

export const createCurrency = createAsyncThunk<void, { code: string; convertRate: number; supplierId: string }>(
  'currency/saveRate',
  async ({ code, convertRate }) => {
    try {
      await apiClient.post(`/currencies`, {
        code,
        convertRate: Number(convertRate),
        bySupplier: false,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // eslint-disable-next-line no-console
        console.error('Server error:', error.response?.data || error.message);
      } else {
        // eslint-disable-next-line no-console
        console.error('Unexpected error:', error);
      }
    }
  },
);

export const updateCurrencyRate = createAction<{ id: number; convertRate: number }>('currency/updateRate');

export const { reducer } = slice;
