import React, { memo, useEffect, useState, useRef, FC, PropsWithChildren } from 'react';
import { CSSTransition } from 'react-transition-group';
import clsx from 'clsx';
import { Icon } from './Icon';
import { TRANSITION } from 'constants/effects';
import 'theme/styles/accordion.scss';
import { string } from 'yup';

type TProps = {
  className?: string;
  isActive?: boolean;
  title?: string;
} & PropsWithChildren<any>;

const AccordionComponent: FC<TProps> = ({ className, isActive = false, title = string, children = null }) => {
  const [isOpen, setIsOpen] = useState(isActive);
  const nodeRef = useRef<HTMLDivElement>(null);
  const contentHeight = nodeRef.current?.scrollHeight;

  const handleToggleAccordion = () => {
    setIsOpen((prev?: boolean) => !prev);
  };

  const setAtToStringAndPx = (value: number): string => {
    return value.toString() + 'px';
  };

  useEffect(() => {
    if (nodeRef.current && contentHeight) {
      nodeRef.current.style.setProperty('--content-height', setAtToStringAndPx(contentHeight));
    }
  }, [contentHeight]);

  return (
    <div
      className={clsx('Accordion', className, {
        Accordion__active: isOpen,
      })}
    >
      <div className="Accordion-Header" onClick={handleToggleAccordion}>
        <div className="Accordion-HeaderTitle">{title}</div>
        <Icon className="Accordion-HeaderIcon" type="ArrowDown" />
      </div>

      <CSSTransition
        classNames="Accordion-ContentWrapper"
        in={isOpen}
        nodeRef={nodeRef}
        timeout={TRANSITION}
        unmountOnExit
      >
        <div ref={nodeRef}>
          <div className="Accordion-Content">{children}</div>
        </div>
      </CSSTransition>
    </div>
  );
};
const Accordion = memo(AccordionComponent);

export default Accordion;
