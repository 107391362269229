import React, { FC } from 'react';
import Accordion from 'components/accordion';
import Currency from './Currency';
import Interest from './Interest';
import Discounts from './Discounts';
import Supplier from './Supplier';

import 'theme/styles/accordion.scss';

const Multy: FC = () => {
  return (
    <section>
      <Accordion className="Accordion" title="Курс валют" isActive={false}>
        <Currency />
      </Accordion>
      <Accordion title="Націнка" isActive={false}>
        <Interest />
      </Accordion>
      <Accordion title="Знижка" isActive={false}>
        <Discounts />
      </Accordion>
      <Accordion title="Постачальники" isActive={false}>
        <Supplier />
      </Accordion>
    </section>
  );
};

export default Multy;
