import React, { useState } from 'react';
import { Modal, TextField, SvgIcon, Checkbox, FormControlLabel, Tooltip } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { AppDispatch } from 'store';
import { createSupplier, fetchSuppliers } from 'slices/supplier';
import { CloseIcon, PlusIcon, SuccessIcon } from 'components/Icon/icons';
import 'theme/styles/modal.scss';

const AddSupplier: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setModalOpen] = useState(false);
  const { formatMessage } = useIntl();
  const [newSupplier, setNewSupplier] = useState<{
    name: string;
    platformCode?: string;
    officialName?: string;
    city?: string;
    address?: string;
    isAssociate?: boolean;
    specializations: string[];
    active: boolean;
  }>({
    name: '',
    platformCode: '',
    officialName: '',
    city: '',
    address: '',
    isAssociate: false,
    specializations: [],
    active: true,
  });

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setNewSupplier({
      name: '',
      platformCode: '',
      officialName: '',
      city: '',
      address: '',
      isAssociate: false,
      specializations: [],
      active: true,
    });
  };

  const categoryOptions = [
    {
      localeId: 'product.component.group.textile',
      value: 'textile',
    },
    {
      localeId: 'product.component.group.webbing',
      value: 'webbing',
    },
    {
      localeId: 'product.component.group.furniture',
      value: 'furniture',
    },
    {
      localeId: 'product.component.group.curtainRodAndAccessory',
      value: 'curtainRod',
    },
  ];

  const handleCategoryChange = (value: string) => {
    setNewSupplier(prev => {
      const isSelected = prev.specializations.includes(value);
      return {
        ...prev,
        specializations: isSelected
          ? prev.specializations.filter(item => item !== value) // Видаляємо, якщо вже вибрано
          : [...prev.specializations, value], // Додаємо, якщо не вибрано
      };
    });
  };

  const { enqueueSnackbar } = useSnackbar();

  const GreenCheckbox = styled(Checkbox)({
    '&.Mui-checked': {
      color: 'green', // Зелений колір для галочки
    },
  });

  const handleSave = async () => {
    try {
      const result = await dispatch(
        createSupplier({
          ...newSupplier,
          specializations: newSupplier.specializations || [],
          isAssociate: newSupplier.isAssociate || false,
          active: true,
        }),
      );

      if (createSupplier.fulfilled.match(result)) {
        enqueueSnackbar(`Постачальника успішно збережено.`, {
          variant: 'success',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        await dispatch(fetchSuppliers()); // Оновлюємо список тільки при успішному збереженні
      } else {
        enqueueSnackbar('Помилка під час збереження постачальника', {
          variant: 'error',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        // eslint-disable-next-line no-console
        console.error(result.payload || 'Unknown error occurred');
      }
    } catch (error) {
      enqueueSnackbar('Помилка під час збереження постачальника', {
        variant: 'error',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <div style={{ margin: -12 }}>
      <Tooltip title="Додати нового постачальника" arrow>
        <SvgIcon
          fontSize="small"
          style={{
            marginRight: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
          onClick={handleOpenModal}
        >
          <PlusIcon />
        </SvgIcon>
      </Tooltip>

      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-container">
          <h3>Додати нового постачальника</h3>
          <TextField
            label="Назва постачальника"
            value={newSupplier.name}
            onChange={e => setNewSupplier({ ...newSupplier, name: e.target.value })}
            fullWidth
          />
          <TextField
            label="Код платформи"
            value={newSupplier.platformCode}
            onChange={e => setNewSupplier({ ...newSupplier, platformCode: e.target.value })}
            fullWidth
            style={{ marginTop: 10 }}
          />
          <TextField
            label="Офіційна назва"
            value={newSupplier.officialName}
            onChange={e => setNewSupplier({ ...newSupplier, officialName: e.target.value })}
            fullWidth
            style={{ marginTop: 10 }}
          />
          <TextField
            label="Місто"
            value={newSupplier.city}
            onChange={e => setNewSupplier({ ...newSupplier, city: e.target.value })}
            fullWidth
            style={{ marginTop: 10 }}
          />
          <TextField
            label="Адреса"
            value={newSupplier.address}
            onChange={e => setNewSupplier({ ...newSupplier, address: e.target.value })}
            fullWidth
            style={{ marginTop: 10 }}
          />
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={newSupplier.isAssociate || false}
                onChange={e => setNewSupplier({ ...newSupplier, isAssociate: e.target.checked })}
              />
            }
            label="Партнерський постачальник"
            style={{ marginTop: 10 }}
          />
          {/* Категорії */}
          <div style={{ marginTop: 20 }}>
            <h4>Категорії</h4>
            {categoryOptions.map(category => (
              <FormControlLabel
                key={category.value}
                control={
                  <GreenCheckbox
                    checked={newSupplier.specializations.includes(category.value)}
                    onChange={() => handleCategoryChange(category.value)}
                  />
                }
                label={formatMessage({ id: category.localeId })}
              />
            ))}
          </div>
          <div className="modal-actions">
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon color="red" />
            </SvgIcon>
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
            >
              <SuccessIcon color="green" />
            </SvgIcon>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddSupplier;
