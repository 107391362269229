import React, { useState, useEffect, memo } from 'react';
import {
  Modal,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Collapse,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { RootState, AppDispatch } from 'store';
import { CloseIcon, EditIcon, SuccessIcon } from 'components/Icon/icons';
import { useSnackbar } from 'notistack';
import { fetchSuppliers, updateSupplier } from 'slices/supplier';
import AddSupplier from './AddSupplier';
import categoryOptions from 'constants/unit';
import 'theme/styles/table.scss';

function Supplier() {
  const dispatch = useDispatch<AppDispatch>();
  const supplier = useSelector((state: RootState) => state.suppliers.suppliersList);
  const { formatMessage } = useIntl();
  const [isModalOpen, setModalOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | null>(null);
  const [selectedSupplier, setSelectedSupplier] = useState<{
    id: string;
    name: string;
    platformCode?: string;
    officialName?: string;
    companyCode?: string;
    city?: string;
    address?: string;
    isAssociate?: boolean;
    specializations?: string[];
    active: boolean;
  } | null>(null);

  const handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedSupplier) {
      const { checked, value } = event.target;

      setSelectedSupplier({
        ...selectedSupplier,
        specializations: checked
          ? [...(selectedSupplier.specializations || []), value] // Додаємо профіль
          : selectedSupplier.specializations?.filter(profile => profile !== value), // Видаляємо профіль
      });
    }
  };

  useEffect(() => {
    dispatch(fetchSuppliers());
  }, [dispatch]);

  const handleOpenModal = (supplier: {
    id: string;
    name: string;
    platformCode?: string;
    officialName?: string;
    companyCode?: string;
    city?: string;
    address?: string;
    isAssociate?: boolean;
    specializations?: string[];
    active: boolean;
  }) => {
    setSelectedSupplier(supplier);
    setModalOpen(true);
  };

  const handleExpandClick = (id: string) => {
    setExpanded(expanded === id ? null : id);
  };

  const handleCloseModal = () => {
    setSelectedSupplier(null);
    setModalOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleSave = async () => {
    if (selectedSupplier) {
      try {
        await dispatch(updateSupplier(selectedSupplier));
        await dispatch(fetchSuppliers());
        enqueueSnackbar(`Постачальника успішно оновлено.`, {
          variant: 'success',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
      } catch (error) {
        enqueueSnackbar('Помилка під час збереження постачальника', {
          variant: 'error',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        // eslint-disable-next-line no-console
        console.error(error);
      }
    }
    handleCloseModal();
  };

  const GreenCheckbox = styled(Checkbox)({
    '&.Mui-checked': {
      color: 'green', // Зелений колір для галочки
    },
  });

  return (
    <>
      <Table className="table-cell">
        <TableHead>
          <TableRow>
            <TableCell>№ п/п</TableCell>
            <TableCell>Назва</TableCell>
            <TableCell>Профіль</TableCell>
            <TableCell className="table-cell-icon" align="right">
              <AddSupplier />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {supplier.map(
            (suppliers: {
              id: string;
              name: string;
              platformCode?: string;
              officialName?: string;
              companyCode?: string;
              city?: string;
              address?: string;
              isAssociate?: boolean;
              specializations?: string[];
              active: boolean;
            }) => (
              <React.Fragment key={suppliers.id}>
                <TableRow
                  key={suppliers.companyCode}
                  className={`Accordion-row ${suppliers.active ? '' : 'row-inactive'}`}
                >
                  <TableCell>{suppliers.companyCode}</TableCell>
                  <TableCell>
                    <span className="link-no-underline" onClick={() => handleExpandClick(suppliers.id)}>
                      {suppliers.name}
                    </span>
                  </TableCell>
                  <TableCell>
                    {suppliers.specializations
                      ?.map(profile => formatMessage({ id: `product.component.group.${profile}` })) // Локалізуємо кожен профіль
                      .join(', ') || ''}
                  </TableCell>
                  <TableCell>
                    <Tooltip title="Редагувати постачальника" arrow>
                      <SvgIcon
                        fontSize="small"
                        style={{
                          cursor: 'pointer',
                          transition: 'background-color 0.3s',
                        }}
                        onClick={() =>
                          handleOpenModal({
                            id: suppliers.id,
                            name: suppliers.name,
                            companyCode: suppliers.companyCode,
                            specializations: suppliers.specializations,
                            active: suppliers.active,
                          })
                        }
                      >
                        <EditIcon />
                      </SvgIcon>
                    </Tooltip>
                  </TableCell>
                </TableRow>

                {/* Акордеон для детальної інформації */}
                <TableRow>
                  <TableCell colSpan={4} style={{ padding: 0 }}>
                    <Collapse in={expanded === suppliers.id} timeout="auto" unmountOnExit>
                      <div style={{ padding: '10px' }}>
                        <p>
                          <strong>ID:</strong> {suppliers.id}
                        </p>
                        <p>
                          <strong>Код платформи:</strong> {suppliers.platformCode || 'N/A'}
                        </p>
                        <p>
                          <strong>Офіційна назва:</strong> {suppliers.officialName || 'N/A'}
                        </p>
                        <p>
                          <strong>Адреса:</strong> {suppliers.city || 'N/A'}, {suppliers.address || 'N/A'}
                        </p>
                        <p>
                          <strong>Партнер:</strong> {suppliers.isAssociate ? 'Так' : 'Ні'}
                        </p>
                      </div>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ),
          )}
        </TableBody>
      </Table>
      {/* Модальне вікно */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>
            {selectedSupplier?.companyCode}: {selectedSupplier?.name}
          </h2>
          <h5>{selectedSupplier?.id}</h5>
          {/* Чекбокс для активного статусу */}
          <FormControlLabel
            control={
              <GreenCheckbox
                checked={selectedSupplier?.active || false}
                onChange={e => setSelectedSupplier(prev => (prev ? { ...prev, active: e.target.checked } : null))}
              />
            }
            label="Постачальник"
          />
          {/* Поле для назви */}
          <TextField
            label="Назва постачальника"
            value={selectedSupplier?.name || ''}
            onChange={e => setSelectedSupplier(prev => (prev ? { ...prev, name: e.target.value } : null))}
            fullWidth
          />
          {/* Поле для офіційної назви */}
          <TextField
            label="Офіційна назва"
            value={selectedSupplier?.officialName || ''}
            onChange={e => setSelectedSupplier(prev => (prev ? { ...prev, officialName: e.target.value } : null))}
            fullWidth
            style={{ marginTop: 10 }}
          />
          {/* Поле для міста */}
          <TextField
            label="Місто"
            value={selectedSupplier?.city || ''}
            onChange={e => setSelectedSupplier(prev => (prev ? { ...prev, city: e.target.value } : null))}
            fullWidth
            style={{ marginTop: 10 }}
          />
          {/* Поле для адреси */}
          <TextField
            label="Адреса"
            value={selectedSupplier?.address || ''}
            onChange={e => setSelectedSupplier(prev => (prev ? { ...prev, address: e.target.value } : null))}
            fullWidth
            style={{ marginTop: 10 }}
          />
          {/* Чекбокси для вибору профілю */}
          <FormGroup>
            {categoryOptions.categoryOptions.map(category => (
              <FormControlLabel
                key={category.value}
                control={
                  <GreenCheckbox
                    value={category.value}
                    checked={selectedSupplier?.specializations?.includes(category.value) || false}
                    onChange={handleProfileChange}
                  />
                }
                label={formatMessage({ id: category.localeId })}
                style={{ marginLeft: 50 }}
              />
            ))}
          </FormGroup>
          <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon color="red" />
            </SvgIcon>
            <SvgIcon
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
            >
              <SuccessIcon color="green" />
            </SvgIcon>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default memo(Supplier);
