import { memo } from 'react';
import type { FC } from 'react';
import type { TIconProps } from './types';
import React from 'react';

const IconComponent: FC<TIconProps> = ({ height = 24, width = 24, ...props }) => (
  <svg height={height} width={width} viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect fill="none" height={height} width={width} />
    <polyline
      fill="none"
      points="144 88 184 48 224 88"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
    />
    <line
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
      x1="184"
      x2="184"
      y1="144"
      y2="48"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
      x1="48"
      x2="120"
      y1="128"
      y2="128"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
      x1="48"
      x2="104"
      y1="64"
      y2="64"
    />
    <line
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="16"
      x1="48"
      x2="184"
      y1="192"
      y2="192"
    />
  </svg>
);

export const SortUpIcon = memo(IconComponent);
