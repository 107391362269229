export const handleUpdate = async () => {
  // 1. Перевіряємо наявність нової версії
  if ('serviceWorker' in navigator) {
    const registration = await navigator.serviceWorker.getRegistration();
    if (registration?.waiting) {
      // Якщо є нова версія, активуємо її
      registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      registration.waiting.addEventListener('statechange', event => {
        if ((event.target as ServiceWorker).state === 'activated') {
          // 2. Очищаємо локальне сховище
          localStorage.clear();
          sessionStorage.clear();
          caches.keys().then(cacheNames => {
            cacheNames.forEach(cacheName => caches.delete(cacheName));
          });

          // 3. Перезавантажуємо сторінку
          window.location.reload();
        }
      });
    } else {
      // eslint-disable-next-line no-console
      console.log('Нова версія не знайдена');
    }
  } else {
    // eslint-disable-next-line no-console
    console.error('Service Worker не підтримується у вашому браузері');
  }
};

export const handleUpdatePC = () => {
  // Очищаємо локальне сховище
  localStorage.clear();
  sessionStorage.clear();

  // Перезавантажуємо сторінку
  window.location.reload();
};
