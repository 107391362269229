import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import apiClient from '../api/coreAPI';
import { RootState } from 'store';
import { Discount } from 'types/discount';
// import { loadState } from 'utils/localStorage';
import axios from 'axios';

// const persistedState = loadState('discount_tool');
// const persistedDiscount = persistedState?.discountList ?? [];

type FetchingStatus = 'idle' | 'loading' | 'fulfilled' | 'rejected';

interface Idiscount {
  discountList: Discount[];
  loading: FetchingStatus;
}

const initialState: Idiscount = {
  discountList: [],
  loading: 'idle',
};

export const fetchDiscount = createAsyncThunk(
  'discount',
  async () => {
    const { data } = await apiClient.get('/discounts');
    return data;
  },
  {
    condition: (_, { getState }) => {
      const { discount } = getState() as RootState;
      if (discount?.loading === 'loading') {
        return false; // Блокуємо лише якщо дані зараз завантажуються
      }
      return true; // Завжди дозволяємо запит
    },
  },
);

const slice = createSlice({
  name: 'discount',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchDiscount.pending, state => {
        state.loading = 'loading';
      })
      .addCase(fetchDiscount.fulfilled, (state, { payload }) => {
        state.discountList = payload;
        state.loading = 'fulfilled';
      })
      .addCase(fetchDiscount.rejected, state => {
        state.loading = 'rejected';
      });
  },
});

export const updateDiscount = createAsyncThunk<
  void,
  { id: number; name: string; percent: number; supplierId?: string }
>('discounts/updateRate', async ({ id, name, percent, supplierId }) => {
  try {
    await apiClient.patch(`/discounts/${id}`, { percent, name, supplierId });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error updating supplier:', error);
    throw error;
  }
});

export const clearDiscount = createSlice({
  name: 'discounts',
  initialState,
  reducers: {
    clearState: state => {
      state.discountList = [];
      state.loading = 'idle';
    },
  },
});

export const createDiscount = createAsyncThunk<void, { name?: string; percent: number; supplierId?: string }>(
  'discount/saveRate',
  async ({ name, percent, supplierId }) => {
    try {
      await apiClient.post(`/discounts`, {
        name,
        percent: Number(percent),
        supplierId,
        group: 'fromSupplierForMaterials',
        flat: 0,
        isOneTime: false,
        isUsed: false,
      });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        // eslint-disable-next-line no-console
        console.error('Server error:', error.response?.data || error.message);
      } else {
        // eslint-disable-next-line no-console
        console.error('Unexpected error:', error);
      }
    }
  },
);

export const { clearState } = clearDiscount.actions;

export const { reducer } = slice;
