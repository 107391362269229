import React, { useState } from 'react';
import { Modal, TextField, SvgIcon, Tooltip } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store';
import { CloseIcon, SuccessIcon, PlusIcon } from 'components/Icon/icons';
import { useSnackbar } from 'notistack';
import { createCurrency, fetchCurrency } from 'slices/currency';
import 'theme/styles/modal.scss';

function AddCurrency() {
  const dispatch = useDispatch<AppDispatch>();
  const [isModalOpen, setModalOpen] = useState(false);
  const [code, setCode] = useState('');
  const [convertRate, setConvertRate] = useState<number | ''>('');

  const handleOpenModalAddCurrency = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setCode('');
    setConvertRate('');
    setModalOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const validateCurrency = (code: string, convertRate: number): string | null => {
    if (!code.trim()) {
      return 'Назва курсу не може бути порожньою.';
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(convertRate) || convertRate <= 0) {
      return 'Значення курсу має бути числом більше 0.';
    }
    return null;
  };

  const handleSave = async () => {
    const validationError = validateCurrency(code, Number(convertRate));
    if (validationError) {
      // eslint-disable-next-line no-alert
      alert(validationError);
      return;
    }

    try {
      const result = await dispatch(
        createCurrency({
          code,
          convertRate: Number(convertRate),
          supplierId: '',
        }),
      );
      if (createCurrency.fulfilled.match(result)) {
        enqueueSnackbar(`Новий курс створено.`, {
          variant: 'success',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        await dispatch(fetchCurrency());
      } else {
        enqueueSnackbar(`Помилка під час зберігання курсу.`, {
          variant: 'error',
          autoHideDuration: 20e2,
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'center',
          },
        });
        // eslint-disable-next-line no-console
        console.error(result.payload || 'Unknown error occurred');
      }
    } catch (error) {
      enqueueSnackbar(`Помилка під час зберігання курсу.`, {
        variant: 'error',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <div style={{ margin: -12 }}>
      <Tooltip title="Додати новий курс" arrow>
        <SvgIcon
          fontSize="small"
          style={{
            marginRight: '12px',
            cursor: 'pointer',
            transition: 'background-color 0.3s',
          }}
          onClick={handleOpenModalAddCurrency}
        >
          <PlusIcon />
        </SvgIcon>
      </Tooltip>
      {/* Модальне вікно */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>Додати нову валюту</h2>
          <TextField
            label="Код валюти"
            value={code}
            onChange={e => setCode(e.target.value)}
            fullWidth
            style={{ marginBottom: 20 }}
          />
          <TextField
            label="Курс"
            type="number"
            value={convertRate}
            onChange={e => setConvertRate(Number(e.target.value))}
            fullWidth
          />
          <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon color="red" />
            </SvgIcon>
            <SvgIcon
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
            >
              <SuccessIcon color="green" />
            </SvgIcon>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default AddCurrency;
