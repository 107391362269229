import type { PayOffice } from 'types/PayOffice';
import apiClient from './coreAPI';
import { ComplexError, errorParser } from './errorsParser';

export const getPayOfficesOfCompany = async (companyId: string): Promise<PayOffice[]> => {
  try {
    const { data } = await apiClient.get<PayOffice[]>(`/companies/${encodeURIComponent(companyId)}/pay-offices`);
    return data;
  } catch (error) {
    const message = errorParser(error as ComplexError);
    throw Error(message);
  }
};

export const createPayOffice = async (companyId: string, newPayOffice: Partial<PayOffice>): Promise<void> => {
  try {
    const { data } = await apiClient.post(`/companies/${companyId}/pay-offices`, newPayOffice);
    return data;
  } catch (error: any) {
    // eslint-disable-next-line no-console
    console.error('Помилка створення каси:', error.response?.data || error.message);
    throw new Error('Failed to create pay office');
  }
};

export default { getPayOfficesOfCompany, createPayOffice };
