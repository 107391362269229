import React, { useState, useEffect, memo } from 'react';
import {
  Modal,
  TextField,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootState, AppDispatch } from 'store';
import { fetchCurrency, updateCurrency } from 'slices/currency';
import { CloseIcon, EditIcon, SuccessIcon } from 'components/Icon/icons';
import { useSnackbar } from 'notistack';
import AddCurrency from 'views/Administrate/AddCurrency';
import 'theme/styles/table.scss';

function Currency() {
  const dispatch = useDispatch<AppDispatch>();
  const currencies = useSelector((state: RootState) => state.currency.currencyList);
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState<{ id: number; code: string; convertRate: number } | null>(
    null,
  );

  useEffect(() => {
    dispatch(fetchCurrency());
  }, [dispatch]);

  const handleOpenModal = (currency: { id: number; code: string; convertRate: number }) => {
    setSelectedCurrency(currency);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedCurrency(null);
    setModalOpen(false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleChangeRate = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedCurrency) {
      setSelectedCurrency({ ...selectedCurrency, convertRate: parseFloat(event.target.value) });
    }
  };

  const validateCurrency = (code: string, convertRate: number): string | null => {
    if (!code.trim()) {
      return 'Назва курсу не може бути порожньою.';
    }
    // eslint-disable-next-line no-restricted-globals
    if (isNaN(convertRate) || convertRate <= 0) {
      return 'Значення курсу має бути числом більше 0.';
    }
    return null;
  };

  const handleSave = async () => {
    if (!selectedCurrency) return;
    const validationError = validateCurrency(selectedCurrency.code, selectedCurrency.convertRate);
    if (validationError) {
      // eslint-disable-next-line no-alert
      alert(validationError);
      return;
    }
    try {
      await dispatch(updateCurrency(selectedCurrency));
      await dispatch(fetchCurrency());
      enqueueSnackbar(`Курс оновлено.`, {
        variant: 'success',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
    } catch (error) {
      enqueueSnackbar(`Помилка під час зберігання курсу.`, {
        variant: 'error',
        autoHideDuration: 20e2,
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'center',
        },
      });
      // eslint-disable-next-line no-console
      console.error(error);
    } finally {
      handleCloseModal();
    }
  };

  return (
    <>
      <Table className="table-cell">
        <TableHead>
          <TableRow>
            <TableCell className="table-cell-id">ID</TableCell>
            <TableCell className="table-cell-percent">Курс</TableCell>
            <TableCell className="table-cell-description">Валюта</TableCell>
            <TableCell className="table-cell-icon" align="right">
              <AddCurrency />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currencies.map((currency: { id: number; code: string; convertRate: number }) => (
            <TableRow key={currency.id} className="Accordion-row">
              <TableCell>{currency.id}</TableCell>
              <TableCell align="left">{currency.convertRate}</TableCell>
              <TableCell>{currency.code}</TableCell>
              <TableCell align="right">
                <Tooltip title="Редагувати курс" arrow>
                  <SvgIcon
                    fontSize="small"
                    style={{
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                    onClick={() =>
                      handleOpenModal({ id: currency.id, code: currency.code, convertRate: currency.convertRate })
                    }
                  >
                    <EditIcon />
                  </SvgIcon>
                </Tooltip>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {/* Модальне вікно */}
      <Modal open={isModalOpen} onClose={handleCloseModal}>
        <div className="modal-container">
          <h2>{selectedCurrency?.code}</h2>
          <TextField
            label="Новий курс"
            type="number"
            value={selectedCurrency?.convertRate || ''}
            onChange={handleChangeRate}
            fullWidth
          />
          <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
            <SvgIcon
              fontSize="small"
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleCloseModal}
            >
              <CloseIcon color="red" />
            </SvgIcon>
            <SvgIcon
              style={{
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={handleSave}
            >
              <SuccessIcon color="green" />
            </SvgIcon>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default memo(Currency);
