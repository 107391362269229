import type { ProductExamples } from 'types/ExampleProduct';

const productExamples: ProductExamples = {
  curtains: [
    {
      id: '728472d0-ce3d-4e0a-bef3-fe9030a12c47',
      category: 'curtains',
      name: 'Шторы на тесьме',
      nameUk: 'Штори на тасьмі',
      basicExamplePrice: '1268',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: 'b50f031a-d38c-433e-90c7-9b33173086aa',
            sku: 'QUEEN 211',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '211',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41457,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'b50f031a-d38c-433e-90c7-9b33173086aa',
            },
          },
          webbing: {
            id: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            sku: '7127/BC',
            supplierSku: '7127/BC',
            name: '7127/BC',
            nameUk: '7127/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '59.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 22,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 1.6,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Штора на тесьме драпировочной',
          format: 'png',
          fileName: 'curtains-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: 'b698a6c8-76ab-4fde-ae79-521a55acec09',
            sku: 'HELEN 102 ( ARHOME )',
            supplierSku: 'HELEN',
            name: 'HELEN',
            nameUk: 'HELEN',
            description: null,
            brand: 'ARHOME',
            colour: '102',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1564.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42700,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'b698a6c8-76ab-4fde-ae79-521a55acec09',
            },
          },
          webbing: {
            id: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            sku: '7019/BC',
            supplierSku: '7019/BC',
            name: '7019/BC',
            nameUk: '7019/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '57.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 499,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Штора на тесьме драпировочной',
          format: 'png',
          fileName: 'curtains-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          webbing: {
            id: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            sku: '7019/BC',
            supplierSku: '7019/BC',
            name: '7019/BC',
            nameUk: '7019/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '57.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 499,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Штора на тесьме драпировочной',
          format: 'png',
          fileName: 'curtains-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'curtainsSimple',
    },
    {
      id: '553472d0-ce3d-4e0a-bef3-fe9030a12c53',
      category: 'curtains',
      name: 'Тюль',
      nameUk: 'Тюль',
      basicExamplePrice: '879',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            sku: 'NEW VUAL 407',
            supplierSku: 'NEW VUAL',
            name: 'NEW VUAL',
            nameUk: 'NEW VUAL',
            description: 'Вуаль молочний',
            brand: 'ARHOME',
            colour: '407',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '311.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41507,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            },
          },
          webbing: {
            id: 'b58805f4-c6b4-4dd4-bcce-8f2ae0ed8892',
            sku: '7083/BC',
            supplierSku: '7083/BC',
            name: '7083/BC',
            nameUk: '7083/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '67.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 26,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: 'b58805f4-c6b4-4dd4-bcce-8f2ae0ed8892',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Тюль на тесьме с драпировкой 1:2',
          format: 'png',
          fileName: 'tulle-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/tulle-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/tulle-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/tulle-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/tulle-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/tulle-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            sku: '110 10',
            supplierSku: '110',
            name: '0110',
            nameUk: '0110',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '10',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '861.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34731,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            },
          },
          webbing: {
            id: 'b58805f4-c6b4-4dd4-bcce-8f2ae0ed8892',
            sku: '7083/BC',
            supplierSku: '7083/BC',
            name: '7083/BC',
            nameUk: '7083/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '67.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 26,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: 'b58805f4-c6b4-4dd4-bcce-8f2ae0ed8892',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Тюль на тесьме с драпировкой 1:2',
          format: 'png',
          fileName: 'tulle-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/tulle-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/tulle-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/tulle-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/tulle-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/tulle-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            sku: '1121 29',
            supplierSku: '1121',
            name: '1121',
            nameUk: '1121',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '29',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '1551.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34713,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            },
          },
          webbing: {
            id: '8dea6259-b824-4430-9d70-0294f4aea847',
            sku: '7030/BC',
            supplierSku: '7030/BC',
            name: '7030/BC',
            nameUk: '7030/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '67.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 407,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '8dea6259-b824-4430-9d70-0294f4aea847',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2.3,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Тюль на тесьме с драпировкой 1:2',
          format: 'png',
          fileName: 'tulle-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/tulle-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/tulle-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/tulle-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/tulle-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/tulle-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'curtainsSimple',
    },
    {
      id: '553472d0-ce3d-4e0a-bef3-fe9030a12c52',
      category: 'curtains',
      name: 'Шторы Блэкаут',
      nameUk: 'Штори Блекаут',
      basicExamplePrice: '1801',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            sku: '365503 5304',
            supplierSku: '365503',
            name: 'Дімаут',
            nameUk: 'Дімаут',
            description: 'Дімаут',
            brand: 'Мир Текстиль',
            colour: '5304',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1129.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41440,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            },
          },
          webbing: {
            id: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            sku: '7127/BC',
            supplierSku: '7127/BC',
            name: '7127/BC',
            nameUk: '7127/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '59.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 22,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 1.6,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на тесьме драпировочной , подобраны на кисти',
          format: 'png',
          fileName: 'curtains-blackout-wBrushes.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-blackout-wBrushes.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-blackout-wBrushes.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-blackout-wBrushes.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '5d83c3d7-678b-49f6-98ea-9e9e798c3946',
            sku: 'MARTE 50',
            supplierSku: 'MARTE',
            name: 'MARTE',
            nameUk: 'MARTE',
            description: 'Димаут',
            brand: 'ARHOME',
            colour: '50',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1435.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41490,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 320,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5d83c3d7-678b-49f6-98ea-9e9e798c3946',
            },
          },
          webbing: {
            id: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            sku: '7019/BC',
            supplierSku: '7019/BC',
            name: '7019/BC',
            nameUk: '7019/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '57.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 499,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на тесьме драпировочной , подобраны на кисти',
          format: 'png',
          fileName: 'curtains-blackout-wBrushes.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-blackout-wBrushes.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-blackout-wBrushes.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-blackout-wBrushes.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: '4179806b-d09c-42bb-bc64-195dba3ecdd7',
            sku: 'CANADA 973',
            supplierSku: 'CANADA',
            name: 'CANADA',
            nameUk: 'CANADA',
            description: 'Дімаут рогожка',
            brand: 'ARHOME',
            colour: '973',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1983.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42210,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 320,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '4179806b-d09c-42bb-bc64-195dba3ecdd7',
            },
          },
          webbing: {
            id: '0e5f6d84-467c-40c1-8620-5591c9fe89c7',
            sku: 'Тасьма драпірувальна Енігма 1:2',
            supplierSku: 'Тасьма драпірувальна Енігма 1:2',
            name: 'Тасьма драпірувальна Енігма 1:2',
            nameUk: 'Тасьма драпірувальна Енігма 1:2',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '92.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 707,
              forProductCategory: ['штори', 'тюль'],
              width: 8,
              draperyRatio: '2.0000',
              notes: null,
              materialId: '0e5f6d84-467c-40c1-8620-5591c9fe89c7',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на тесьме драпировочной , подобраны на кисти',
          format: 'png',
          fileName: 'curtains-blackout-wBrushes.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-blackout-wBrushes.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-blackout-wBrushes.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-blackout-wBrushes.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'curtainsSimple',
    },
    {
      id: '728520d0-ce3d-4e0a-bef3-fe9030a12c47',
      category: 'curtains',
      name: 'Шторы на люверсах',
      nameUk: 'Штори на люверсах',
      basicExamplePrice: '3290',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '39e048ed-7025-4737-9f65-a9c00969f220',
            sku: 'QUEEN 523',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '523',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41719,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '39e048ed-7025-4737-9f65-a9c00969f220',
            },
          },
          webbing: {
            id: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            sku: 'Тасьма для люверсів біла',
            supplierSku: 'Тасьма для люверсів біла',
            name: 'Тасьма для люверсів біла',
            nameUk: 'Тасьма для люверсів біла',
            description: null,
            brand: null,
            colour: 'Білий',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '64.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 708,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: '1.0000',
              notes: null,
              materialId: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            },
          },
          grommet: {
            id: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            sku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            supplierSku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            name: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            nameUk: 'Люверс 35х55 Універсальний 10 шт/уп (Україна)',
            description: null,
            brand: null,
            colour: null,
            colourSet: [
              'Белый',
              'Золото',
              'Черный',
              'Черный-блеск',
              'Хром',
              'Золото-мат',
              'Хром-мат',
              'Антик,Сатин',
              'Медь',
            ],
            madeIn: 'Україна',
            unit: 'уп.',
            unitDivider: 1,
            minOrder: null,
            price: '370.00',
            rollAmount: null,
            packageAmount: 10,
            supplyTimeClass: null,
            type: 'furniture',
            created: '2023-04-29T17:24:44.870Z',
            modified: '2023-05-02T13:50:44.474Z',
            supplierId: 'c0f4c12e-7ab1-4f9d-9eb3-d4fabf57d2fc',
            furnitureData: {
              id: 257,
              materialType: 'люверс',
              forProductCategory: ['штори', 'тюль'],
              width: null,
              height: null,
              length: null,
              diameter: 35,
              overallDiameter: 55,
              diameterCompatibility: [16, 19, 25],
              fabricComposition: null,
              notes: null,
              materialId: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          grommet: { amount: -3 },
          draperyCoefficient: 2,
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingCurtainsComplex: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на люверсах коэф 1:2, тюль на тесьме 1:1,6 -1,8',
          format: 'png',
          fileName: 'curtains-simple-grommet.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple-grommet.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple-grommet.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple-grommet.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple-grommet.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple-grommet.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '5882d507-0159-4c42-8096-9dc69888f08c',
            sku: 'CANADA 965',
            supplierSku: 'CANADA',
            name: 'CANADA',
            nameUk: 'CANADA',
            description: 'Дімаут рогожка',
            brand: 'ARHOME',
            colour: '965',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1983.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 43273,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 320,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5882d507-0159-4c42-8096-9dc69888f08c',
            },
          },
          webbing: {
            id: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            sku: 'Тасьма для люверсів біла',
            supplierSku: 'Тасьма для люверсів біла',
            name: 'Тасьма для люверсів біла',
            nameUk: 'Тасьма для люверсів біла',
            description: null,
            brand: null,
            colour: 'Білий',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '64.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 708,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: '1.0000',
              notes: null,
              materialId: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            },
          },
          grommet: {
            id: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            sku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            supplierSku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            name: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            nameUk: 'Люверс 35х55 Універсальний 10 шт/уп (Україна)',
            description: null,
            brand: null,
            colour: null,
            colourSet: [
              'Белый',
              'Золото',
              'Черный',
              'Черный-блеск',
              'Хром',
              'Золото-мат',
              'Хром-мат',
              'Антик,Сатин',
              'Медь',
            ],
            madeIn: 'Україна',
            unit: 'уп.',
            unitDivider: 1,
            minOrder: null,
            price: '370.00',
            rollAmount: null,
            packageAmount: 10,
            supplyTimeClass: null,
            type: 'furniture',
            created: '2023-04-29T17:24:44.870Z',
            modified: '2023-05-02T13:50:44.474Z',
            supplierId: 'c0f4c12e-7ab1-4f9d-9eb3-d4fabf57d2fc',
            furnitureData: {
              id: 257,
              materialType: 'люверс',
              forProductCategory: ['штори', 'тюль'],
              width: null,
              height: null,
              length: null,
              diameter: 35,
              overallDiameter: 55,
              diameterCompatibility: [16, 19, 25],
              fabricComposition: null,
              notes: null,
              materialId: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          grommet: { amount: -3 },
          draperyCoefficient: 2,
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingCurtainsComplex: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на люверсах коэф 1:2, тюль на тесьме 1:1,6 -1,8',
          format: 'png',
          fileName: 'curtains-simple-grommet.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple-grommet.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple-grommet.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple-grommet.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple-grommet.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple-grommet.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          webbing: {
            id: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            sku: 'Тасьма для люверсів біла',
            supplierSku: 'Тасьма для люверсів біла',
            name: 'Тасьма для люверсів біла',
            nameUk: 'Тасьма для люверсів біла',
            description: null,
            brand: null,
            colour: 'Білий',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '64.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 708,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: '1.0000',
              notes: null,
              materialId: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            },
          },
          grommet: {
            id: '9ef3a760-2c12-4163-9c5a-db17d5c84f8e',
            sku: 'Люверсы 35x55 мм (Marcin Decor) 10 шт/уп (Бежевый, Белый, Желтый, Зеленый, Махонь, Прозрачный, Красный, Черный) Универсальный',
            supplierSku:
              'Люверсы 35x55 мм (Marcin Decor) 10 шт/уп (Бежевый, Белый, Желтый, Зеленый, Махонь, Прозрачный, Красный, Черный) Универсальный',
            name: 'Люверсы 35x55 мм (Marcin Decor) 10 шт/уп (Бежевый, Белый, Желтый, Зеленый, Махонь, Прозрачный, Красный, Черный) Универсальный',
            nameUk:
              'Люверси 35x55 мм (Marcin Decor) 10 шт/уп (Бежевий, Білий, Жовтий, Зелений, Махонь, Прозорий, Червоний, Чорний) Універсальний',
            description: null,
            brand: 'Marcin-Dekor',
            colour: null,
            colourSet: ['Бежевый', 'Белый', 'Желтый', 'Зеленый', 'Махонь', 'Прозрачный', 'Красный', 'Черный'],
            madeIn: 'Польша',
            unit: 'уп.',
            unitDivider: 1,
            minOrder: null,
            price: '843.00',
            rollAmount: null,
            packageAmount: 10,
            supplyTimeClass: null,
            type: 'furniture',
            created: '2023-04-29T17:24:44.870Z',
            modified: '2023-05-02T13:50:44.474Z',
            supplierId: 'c0f4c12e-7ab1-4f9d-9eb3-d4fabf57d2fc',
            furnitureData: {
              id: 240,
              materialType: 'люверс',
              forProductCategory: ['штори', 'тюль'],
              width: null,
              height: null,
              length: null,
              diameter: 35,
              overallDiameter: 55,
              diameterCompatibility: [16, 19, 25],
              fabricComposition: null,
              notes: null,
              materialId: '9ef3a760-2c12-4163-9c5a-db17d5c84f8e',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbing: { amount: -1 },
          grommet: { amount: -3 },
          draperyCoefficient: 2.3,
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingCurtainsComplex: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на люверсах коэф 1:2, тюль на тесьме 1:1,6 -1,8',
          format: 'png',
          fileName: 'curtains-simple-grommet.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple-grommet.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple-grommet.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple-grommet.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple-grommet.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple-grommet.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'curtainsSimpleGrommet',
    },
    {
      id: '284572d0-ce3d-4e0a-bef3-fe9030a84dh2',
      category: 'curtains',
      name: 'Шторы на тесьме с подкладкой',
      nameUk: 'Штори на тасьмі з підкладкою',
      basicExamplePrice: '2769',
      basicExampleUnit: 'м',
      supplyTimeClass: 7,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            sku: 'QUEEN 710',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '710',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41459,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing: {
            id: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            sku: '7127/BC',
            supplierSku: '7127/BC',
            name: '7127/BC',
            nameUk: '7127/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '59.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 22,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 1.6,
          isComplexSewing: false,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Штора на тесьме драпировочной',
          format: 'png',
          fileName: 'curtains-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '89107d34-ff2e-4f8f-a32c-b9300150841e',
            sku: 'KASMIR 22-11090',
            supplierSku: 'KASMIR',
            name: 'KASMIR',
            nameUk: 'KASMIR',
            description: 'Двохстороння тканина, Микровелюр',
            brand: 'ARHOME',
            colour: '22-11090',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1293.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 43300,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '89107d34-ff2e-4f8f-a32c-b9300150841e',
            },
          },
          liningTextile: {
            id: '3d07edc0-d07c-4566-bf67-79fd9828ba9e',
            sku: 'NIKOLA 15499',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15499',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41524,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3d07edc0-d07c-4566-bf67-79fd9828ba9e',
            },
          },
          webbing: {
            id: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            sku: '7019/BC',
            supplierSku: '7019/BC',
            name: '7019/BC',
            nameUk: '7019/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '57.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 499,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
          isComplexSewing: false,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Штора на тесьме драпировочной',
          format: 'png',
          fileName: 'curtains-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'c01a7d2b-b252-4588-9cb6-996c4f19d5d4',
            sku: 'Madrid 01',
            supplierSku: 'Madrid',
            name: 'Madrid',
            nameUk: 'Madrid',
            description: null,
            brand: null,
            colour: '01',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '2218.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16126,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 280,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'c01a7d2b-b252-4588-9cb6-996c4f19d5d4',
            },
          },
          liningTextile: {
            id: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            sku: '365503 5304',
            supplierSku: '365503',
            name: 'Дімаут',
            nameUk: 'Дімаут',
            description: 'Дімаут',
            brand: 'Мир Текстиль',
            colour: '5304',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1129.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41440,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            },
          },
          webbing: {
            id: '0e5f6d84-467c-40c1-8620-5591c9fe89c7',
            sku: 'Тасьма драпірувальна Енігма 1:2',
            supplierSku: 'Тасьма драпірувальна Енігма 1:2',
            name: 'Тасьма драпірувальна Енігма 1:2',
            nameUk: 'Тасьма драпірувальна Енігма 1:2',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '92.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 707,
              forProductCategory: ['штори', 'тюль'],
              width: 8,
              draperyRatio: '2.0000',
              notes: null,
              materialId: '0e5f6d84-467c-40c1-8620-5591c9fe89c7',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
          isComplexSewing: false,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Штора на тесьме драпировочной',
          format: 'png',
          fileName: 'curtains-simple.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'curtainsSimple',
    },
    {
      id: '497272d0-ce3d-4e0a-bef3-fe9030a12d73',
      category: 'curtains',
      name: 'Шторы Блэкаут с подкладкой',
      nameUk: 'Штори Блекаут з підкладкою',
      basicExamplePrice: '3302',
      basicExampleUnit: 'м',
      supplyTimeClass: 7,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            sku: '365503 5304',
            supplierSku: '365503',
            name: 'Дімаут',
            nameUk: 'Дімаут',
            description: 'Дімаут',
            brand: 'Мир Текстиль',
            colour: '5304',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1129.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41440,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            },
          },
          liningTextile: {
            id: '917c2a5d-ae9c-4cdb-808c-06bc03018658',
            sku: 'NIKOLA 15030',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15030',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41718,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '917c2a5d-ae9c-4cdb-808c-06bc03018658',
            },
          },
          webbing: {
            id: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            sku: '7127/BC',
            supplierSku: '7127/BC',
            name: '7127/BC',
            nameUk: '7127/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '59.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 22,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '498378b3-cc8f-4ec6-a1cf-7911da134308',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 1.6,
          isComplexSewing: false,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на тесьме драпировочной , подобраны на кисти',
          format: 'png',
          fileName: 'curtains-blackout-wBrushes.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-blackout-wBrushes.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-blackout-wBrushes.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-blackout-wBrushes.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '5d83c3d7-678b-49f6-98ea-9e9e798c3946',
            sku: 'MARTE 50',
            supplierSku: 'MARTE',
            name: 'MARTE',
            nameUk: 'MARTE',
            description: 'Димаут',
            brand: 'ARHOME',
            colour: '50',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1435.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41490,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 320,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5d83c3d7-678b-49f6-98ea-9e9e798c3946',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing: {
            id: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            sku: '7019/BC',
            supplierSku: '7019/BC',
            name: '7019/BC',
            nameUk: '7019/BC',
            description: null,
            brand: 'Rubans',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '57.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 499,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '101ebe95-3672-47f8-a1e2-2d516059c49d',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
          isComplexSewing: false,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на тесьме драпировочной , подобраны на кисти',
          format: 'png',
          fileName: 'curtains-blackout-wBrushes.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-blackout-wBrushes.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-blackout-wBrushes.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-blackout-wBrushes.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: '6dab6f8b-1a27-48fb-9d7e-b93cf76af5b5',
            sku: 'CANADA 961',
            supplierSku: 'CANADA',
            name: 'CANADA',
            nameUk: 'CANADA',
            description: 'Дімаут рогожка',
            brand: 'ARHOME',
            colour: '961',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1983.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 43271,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 320,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '6dab6f8b-1a27-48fb-9d7e-b93cf76af5b5',
            },
          },
          liningTextile: {
            id: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            sku: '365503 5304',
            supplierSku: '365503',
            name: 'Дімаут',
            nameUk: 'Дімаут',
            description: 'Дімаут',
            brand: 'Мир Текстиль',
            colour: '5304',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1129.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41440,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            },
          },
          webbing: {
            id: '0e5f6d84-467c-40c1-8620-5591c9fe89c7',
            sku: 'Тасьма драпірувальна Енігма 1:2',
            supplierSku: 'Тасьма драпірувальна Енігма 1:2',
            name: 'Тасьма драпірувальна Енігма 1:2',
            nameUk: 'Тасьма драпірувальна Енігма 1:2',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '92.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 707,
              forProductCategory: ['штори', 'тюль'],
              width: 8,
              draperyRatio: '2.0000',
              notes: null,
              materialId: '0e5f6d84-467c-40c1-8620-5591c9fe89c7',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          draperyCoefficient: 2,
          isComplexSewing: false,
        },
        serviceOptions: {
          sewingCurtains: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на тесьме драпировочной , подобраны на кисти',
          format: 'png',
          fileName: 'curtains-blackout-wBrushes.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-blackout-wBrushes.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-blackout-wBrushes.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-blackout-wBrushes.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-blackout-wBrushes.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'curtainsSimple',
    },
    {
      id: '392640d0-ce3d-4e0a-bef3-fe9030a84he3',
      category: 'curtains',
      name: 'Шторы на люверсах с подкладкой',
      nameUk: 'Штори на люверсах з підкладкою',
      basicExamplePrice: '5304',
      basicExampleUnit: 'м',
      supplyTimeClass: 7,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '39e048ed-7025-4737-9f65-a9c00969f220',
            sku: 'QUEEN 523',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '523',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41719,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '39e048ed-7025-4737-9f65-a9c00969f220',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing: {
            id: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            sku: 'Тасьма для люверсів біла',
            supplierSku: 'Тасьма для люверсів біла',
            name: 'Тасьма для люверсів біла',
            nameUk: 'Тасьма для люверсів біла',
            description: null,
            brand: null,
            colour: 'Білий',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '64.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 708,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: '1.0000',
              notes: null,
              materialId: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            },
          },
          grommet: {
            id: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            sku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            supplierSku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            name: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            nameUk: 'Люверс 35х55 Універсальний 10 шт/уп (Україна)',
            description: null,
            brand: null,
            colour: null,
            colourSet: [
              'Белый',
              'Золото',
              'Черный',
              'Черный-блеск',
              'Хром',
              'Золото-мат',
              'Хром-мат',
              'Антик,Сатин',
              'Медь',
            ],
            madeIn: 'Україна',
            unit: 'уп.',
            unitDivider: 1,
            minOrder: null,
            price: '370.00',
            rollAmount: null,
            packageAmount: 10,
            supplyTimeClass: null,
            type: 'furniture',
            created: '2023-04-29T17:24:44.870Z',
            modified: '2023-05-02T13:50:44.474Z',
            supplierId: 'c0f4c12e-7ab1-4f9d-9eb3-d4fabf57d2fc',
            furnitureData: {
              id: 257,
              materialType: 'люверс',
              forProductCategory: ['штори', 'тюль'],
              width: null,
              height: null,
              length: null,
              diameter: 35,
              overallDiameter: 55,
              diameterCompatibility: [16, 19, 25],
              fabricComposition: null,
              notes: null,
              materialId: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          grommet: { amount: -3 },
          draperyCoefficient: 2,
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingCurtainsComplex: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на люверсах коэф 1:2, тюль на тесьме 1:1,6 -1,8',
          format: 'png',
          fileName: 'curtains-simple-grommet.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple-grommet.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple-grommet.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple-grommet.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple-grommet.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple-grommet.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '39661f8b-3a75-450f-9cf9-403522dca9bc',
            sku: 'Gino 1115',
            supplierSku: 'Gino',
            name: 'Gino',
            nameUk: 'Gino',
            description: null,
            brand: 'ARHOME',
            colour: '1115',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1121.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42709,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '39661f8b-3a75-450f-9cf9-403522dca9bc',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing: {
            id: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            sku: 'Тасьма для люверсів біла',
            supplierSku: 'Тасьма для люверсів біла',
            name: 'Тасьма для люверсів біла',
            nameUk: 'Тасьма для люверсів біла',
            description: null,
            brand: null,
            colour: 'Білий',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '64.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 708,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: '1.0000',
              notes: null,
              materialId: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            },
          },
          grommet: {
            id: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            sku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            supplierSku: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            name: 'Люверс 35х55  Универсальный 10 шт/уп (Украина)',
            nameUk: 'Люверс 35х55 Універсальний 10 шт/уп (Україна)',
            description: null,
            brand: null,
            colour: null,
            colourSet: [
              'Белый',
              'Золото',
              'Черный',
              'Черный-блеск',
              'Хром',
              'Золото-мат',
              'Хром-мат',
              'Антик,Сатин',
              'Медь',
            ],
            madeIn: 'Україна',
            unit: 'уп.',
            unitDivider: 1,
            minOrder: null,
            price: '370.00',
            rollAmount: null,
            packageAmount: 10,
            supplyTimeClass: null,
            type: 'furniture',
            created: '2023-04-29T17:24:44.870Z',
            modified: '2023-05-02T13:50:44.474Z',
            supplierId: 'c0f4c12e-7ab1-4f9d-9eb3-d4fabf57d2fc',
            furnitureData: {
              id: 257,
              materialType: 'люверс',
              forProductCategory: ['штори', 'тюль'],
              width: null,
              height: null,
              length: null,
              diameter: 35,
              overallDiameter: 55,
              diameterCompatibility: [16, 19, 25],
              fabricComposition: null,
              notes: null,
              materialId: 'ae2b4832-5853-4862-94e4-4d64833c3955',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          grommet: { amount: -3 },
          draperyCoefficient: 2,
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingCurtainsComplex: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на люверсах коэф 1:2, тюль на тесьме 1:1,6 -1,8',
          format: 'png',
          fileName: 'curtains-simple-grommet.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple-grommet.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple-grommet.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple-grommet.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple-grommet.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple-grommet.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: '26960879-d66f-41b7-9b4a-a9984e6321a7',
            sku: 'BERLIN 205',
            supplierSku: 'BERLIN',
            name: 'BERLIN',
            nameUk: 'BERLIN',
            description: 'Імітація шерсті',
            brand: 'ARHOME',
            colour: '205',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1459.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41254,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 320,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '26960879-d66f-41b7-9b4a-a9984e6321a7',
            },
          },
          liningTextile: {
            id: 'b3641c9c-bc3b-4232-a8c0-485e60d220ab',
            sku: 'MARTE 67',
            supplierSku: 'MARTE',
            name: 'MARTE',
            nameUk: 'MARTE',
            description: 'Димаут',
            brand: 'ARHOME',
            colour: '67',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1435.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41244,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 320,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'b3641c9c-bc3b-4232-a8c0-485e60d220ab',
            },
          },
          webbing: {
            id: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            sku: 'Тасьма для люверсів біла',
            supplierSku: 'Тасьма для люверсів біла',
            name: 'Тасьма для люверсів біла',
            nameUk: 'Тасьма для люверсів біла',
            description: null,
            brand: null,
            colour: 'Білий',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '64.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            webbingData: {
              id: 708,
              forProductCategory: ['штори', 'тюль'],
              width: null,
              draperyRatio: '1.0000',
              notes: null,
              materialId: 'b8d229a6-abba-4bb5-8fcd-911baeceaf5b',
            },
          },
          grommet: {
            id: '9ef3a760-2c12-4163-9c5a-db17d5c84f8e',
            sku: 'Люверсы 35x55 мм (Marcin Decor) 10 шт/уп (Бежевый, Белый, Желтый, Зеленый, Махонь, Прозрачный, Красный, Черный) Универсальный',
            supplierSku:
              'Люверсы 35x55 мм (Marcin Decor) 10 шт/уп (Бежевый, Белый, Желтый, Зеленый, Махонь, Прозрачный, Красный, Черный) Универсальный',
            name: 'Люверсы 35x55 мм (Marcin Decor) 10 шт/уп (Бежевый, Белый, Желтый, Зеленый, Махонь, Прозрачный, Красный, Черный) Универсальный',
            nameUk:
              'Люверси 35x55 мм (Marcin Decor) 10 шт/уп (Бежевий, Білий, Жовтий, Зелений, Махонь, Прозорий, Червоний, Чорний) Універсальний',
            description: null,
            brand: 'Marcin-Dekor',
            colour: null,
            colourSet: ['Бежевый', 'Белый', 'Желтый', 'Зеленый', 'Махонь', 'Прозрачный', 'Красный', 'Черный'],
            madeIn: 'Польша',
            unit: 'уп.',
            unitDivider: 1,
            minOrder: null,
            price: '843.00',
            rollAmount: null,
            packageAmount: 10,
            supplyTimeClass: null,
            type: 'furniture',
            created: '2023-04-29T17:24:44.870Z',
            modified: '2023-05-02T13:50:44.474Z',
            supplierId: 'c0f4c12e-7ab1-4f9d-9eb3-d4fabf57d2fc',
            furnitureData: {
              id: 240,
              materialType: 'люверс',
              forProductCategory: ['штори', 'тюль'],
              width: null,
              height: null,
              length: null,
              diameter: 35,
              overallDiameter: 55,
              diameterCompatibility: [16, 19, 25],
              fabricComposition: null,
              notes: null,
              materialId: '9ef3a760-2c12-4163-9c5a-db17d5c84f8e',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing: { amount: -1 },
          grommet: { amount: -3 },
          draperyCoefficient: 2.3,
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingCurtainsComplex: -1,
          hangingCurtains: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Шторы на люверсах коэф 1:2, тюль на тесьме 1:1,6 -1,8',
          format: 'png',
          fileName: 'curtains-simple-grommet.dK2.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/curtains-simple-grommet.dK2.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/curtains-simple-grommet.dK2.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/curtains-simple-grommet.dK2.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/curtains-simple-grommet.dK2.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/curtains-simple-grommet.dK2.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'curtainsSimpleGrommet',
    },
  ],
  romanBlinds: [
    {
      id: '468572d0-ce3d-4e0a-bef3-fe9030a12c48',
      category: 'curtains',
      name: 'Римские шторы',
      nameUk: 'Римські штори',
      basicExamplePrice: '1724',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'chain-roman',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            sku: 'QUEEN 710',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '710',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41459,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            sku: 'KETEN 320',
            supplierSku: 'KETEN',
            name: 'KETEN',
            nameUk: 'KETEN',
            description: 'Рогожка',
            brand: 'ARHOME',
            colour: '320',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1177.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42282,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'romanBlindsSimple',
    },
    {
      id: '468272d0-ce3d-4e0a-bef3-fe9030a12c50',
      category: 'curtains',
      name: 'Римские шторы',
      nameUk: 'Римські штори',
      basicExamplePrice: '599',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            sku: 'QUEEN 710',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '710',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41459,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbingAdhesive: { amount: -1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            sku: 'KETEN 320',
            supplierSku: 'KETEN',
            name: 'KETEN',
            nameUk: 'KETEN',
            description: 'Рогожка',
            brand: 'ARHOME',
            colour: '320',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1177.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42282,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbingAdhesive: { amount: -1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbingAdhesive: { amount: -1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'romanBlindsSimpleWithoutSystem',
    },
    {
      id: '069372d0-ce3d-4e0a-bef3-fe9030a12c48',
      category: 'curtains',
      name: 'Римские шторы из тюля',
      nameUk: 'Римські штори з тюля',
      basicExamplePrice: '1538',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'chain-roman',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            sku: 'NEW VUAL 407',
            supplierSku: 'NEW VUAL',
            name: 'NEW VUAL',
            nameUk: 'NEW VUAL',
            description: 'Вуаль молочний',
            brand: 'ARHOME',
            colour: '407',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '311.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41507,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          mainTextile: {
            id: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            sku: '110 10',
            supplierSku: '110',
            name: '0110',
            nameUk: '0110',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '10',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '861.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34731,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          mainTextile: {
            id: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            sku: '1121 29',
            supplierSku: '1121',
            name: '1121',
            nameUk: '1121',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '29',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '1551.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34713,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'romanBlindsSimple',
    },
    {
      id: '376172d0-ce3d-4e0a-bef3-fe9030a12c50',
      category: 'curtains',
      name: 'Римские шторы из тюля',
      nameUk: 'Римські штори з тюля',
      basicExamplePrice: '413',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            sku: 'NEW VUAL 407',
            supplierSku: 'NEW VUAL',
            name: 'NEW VUAL',
            nameUk: 'NEW VUAL',
            description: 'Вуаль молочний',
            brand: 'ARHOME',
            colour: '407',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '311.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41507,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbingAdhesive: { amount: -1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          mainTextile: {
            id: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            sku: '110 10',
            supplierSku: '110',
            name: '0110',
            nameUk: '0110',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '10',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '861.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34731,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbingAdhesive: { amount: -1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          mainTextile: {
            id: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            sku: '1121 29',
            supplierSku: '1121',
            name: '1121',
            nameUk: '1121',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '29',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '1551.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34713,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          webbingAdhesive: { amount: -1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'romanBlindsSimpleWithoutSystem',
    },
    {
      id: '437152d0-ce3d-4e0a-bef3-fe9030a12c51',
      category: 'curtains',
      name: 'Римские шторы на подкладке',
      nameUk: 'Римські штори на підкладці',
      basicExamplePrice: '2349',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'chain-roman',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            sku: 'QUEEN 710',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '710',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41459,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing4Stick: {
            id: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            sku: 'Тесьма тонельная для прутов',
            supplierSku: 'Тесьма тонельная для прутов',
            name: 'Тесьма тонельная для прутов',
            nameUk: 'Тасьма тунельна для прутів',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '17.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 517,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing4Stick: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingRomanBlindsComplex: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            sku: 'KETEN 320',
            supplierSku: 'KETEN',
            name: 'KETEN',
            nameUk: 'KETEN',
            description: 'Рогожка',
            brand: 'ARHOME',
            colour: '320',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1177.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42282,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing4Stick: {
            id: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            sku: 'Тесьма тонельная для прутов',
            supplierSku: 'Тесьма тонельная для прутов',
            name: 'Тесьма тонельная для прутов',
            nameUk: 'Тасьма тунельна для прутів',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '17.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 517,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing4Stick: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingRomanBlindsComplex: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          liningTextile: {
            id: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            sku: '365503 5304',
            supplierSku: '365503',
            name: 'Дімаут',
            nameUk: 'Дімаут',
            description: 'Дімаут',
            brand: 'Мир Текстиль',
            colour: '5304',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1129.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41440,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            },
          },
          webbing4Stick: {
            id: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            sku: 'Тесьма тонельная для прутов',
            supplierSku: 'Тесьма тонельная для прутов',
            name: 'Тесьма тонельная для прутов',
            nameUk: 'Тасьма тунельна для прутів',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '17.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 517,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            },
          },
          romanBlindSystem: {
            id: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            sku: 'Люкс Карниз в сборе для римских штор',
            supplierSku: 'Люкс Карниз в сборе для римских штор',
            name: 'Люкс Карниз в сборе для римских штор',
            nameUk: 'Люкс Карниз в зборі для римських штор',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: 0,
            price: '1141.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            curtainRodData: {
              id: 389,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 40,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: 'Ролики намотки шнура -2 шт \nКрепление верхнее- 2 шт\nКарниз с лентой +прут, м.п.                                \nЛента пришивная (ответная часть) для ткани, м.п. \nМеханизм управления LUX, ком-т                                                                                                                                                             \nЦепь управления замкнутая (на выбор), шт                                                                                        \nПрут  Ø4мм, фибергласс (прозрачный), 5 шт                                                                                        \nУтяжелитель алюминиевый для полотна, 1 шт                                                                                        \nКольцо прозр.пришивное, шт.  \nРасчет кол-ва= кол-во роликов*кол-во прутов                                                                                        \nКольцо с захватом прозр. пришивное, шт.  \nРасчет кол-ва= кол-во роликов                                                                                        \nТесьма тонельная для прутов, м.п. \nРасчет тесьмы=шир. карниза*кол-во прутов                                                                                        \nОтвес цепи управления, шт                                                                                        \nУтяжелитель отвеса цепи, шт',
              },
              notes: null,
              materialId: '6bdf3d0f-d383-4e74-b6d0-fa04123a6e6c',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing4Stick: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingRomanBlindsComplex: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'romanBlindsLined',
    },
    {
      id: '437152d0-ce3d-4e0a-bef3-fe9030a12c52',
      category: 'curtains',
      name: 'Римские шторы на подкладке',
      nameUk: 'Римські штори на підкладці',
      basicExamplePrice: '1224',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'none',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            sku: 'QUEEN 710',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '710',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41459,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing4Stick: {
            id: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            sku: 'Тесьма тонельная для прутов',
            supplierSku: 'Тесьма тонельная для прутов',
            name: 'Тесьма тонельная для прутов',
            nameUk: 'Тасьма тунельна для прутів',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '17.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 517,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing4Stick: { amount: -1 },
          webbingAdhesive: { amount: -1 },
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingRomanBlindsComplex: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            sku: 'KETEN 320',
            supplierSku: 'KETEN',
            name: 'KETEN',
            nameUk: 'KETEN',
            description: 'Рогожка',
            brand: 'ARHOME',
            colour: '320',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1177.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42282,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            },
          },
          liningTextile: {
            id: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            sku: 'NIKOLA 15498',
            supplierSku: 'NIKOLA',
            name: 'NIKOLA',
            nameUk: 'NIKOLA',
            description: 'Підкладка',
            brand: 'ARHOME',
            colour: '15498',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '460.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41525,
              forProductCategory: ['підкладка'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '43cdf248-2dce-49eb-955c-c9b18ae77d04',
            },
          },
          webbing4Stick: {
            id: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            sku: 'Тесьма тонельная для прутов',
            supplierSku: 'Тесьма тонельная для прутов',
            name: 'Тесьма тонельная для прутов',
            nameUk: 'Тасьма тунельна для прутів',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '17.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 517,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing4Stick: { amount: -1 },
          webbingAdhesive: { amount: -1 },
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingRomanBlindsComplex: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          liningTextile: {
            id: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            sku: '365503 5304',
            supplierSku: '365503',
            name: 'Дімаут',
            nameUk: 'Дімаут',
            description: 'Дімаут',
            brand: 'Мир Текстиль',
            colour: '5304',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1129.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41440,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '055e9325-4bb8-467d-8e8b-0ec683c03ae6',
            },
          },
          webbing4Stick: {
            id: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            sku: 'Тесьма тонельная для прутов',
            supplierSku: 'Тесьма тонельная для прутов',
            name: 'Тесьма тонельная для прутов',
            nameUk: 'Тасьма тунельна для прутів',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '17.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 517,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '4cc4bd85-95a5-4458-a7ac-5968853009f4',
            },
          },
          webbingAdhesive: {
            id: '568dac38-e215-4bff-aa14-f98e778cc91a',
            sku: 'Лента (ответная часть) для ткани',
            supplierSku: 'Лента (ответная часть) для ткани',
            name: 'Лента (ответная часть) для ткани',
            nameUk: 'Стрічка (відповідна частина) для тканини',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '39.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'webbing',
            supplierId: '82c4dad4-29d5-43e7-a368-6b696eb98f0b',
            webbingData: {
              id: 516,
              forProductCategory: ['римські штори'],
              width: null,
              draperyRatio: null,
              notes: null,
              materialId: '568dac38-e215-4bff-aa14-f98e778cc91a',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          liningTextile: { amount: -1 },
          webbing4Stick: { amount: -1 },
          webbingAdhesive: { amount: -1 },
          isComplexSewing: true,
        },
        serviceOptions: {
          sewingRomanBlindsComplex: -1,
          hangingRomanBlinds: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'romanBlindsSimpleWithoutSystem',
    },
    {
      id: '376172d0-ce3d-4e0a-bef3-fe9030a12c53',
      category: 'curtains',
      name: 'Римские шторы "Стандарт"',
      nameUk: 'Римські штори "Стандарт"',
      basicExamplePrice: '1152',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'standard-cord-roman',
      corniceSystem: null,
      standart: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Дикий шелк',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Лён с отливом',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Блэкаутэклипс',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'standardRomanBlind',
    },
    {
      id: '376172d0-ce3d-4e0a-bef3-fe9030a12c54',
      category: 'curtains',
      name: 'Римские шторы "Стандарт"',
      nameUk: 'Римські штори "Стандарт"',
      basicExamplePrice: '2653',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'standard-chain-roman',
      corniceSystem: null,
      standart: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Дикий шелк',
            mainTextileColour: '1',
            systemType: 'standard-chain-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Лён с отливом',
            mainTextileColour: '1',
            systemType: 'standard-chain-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Блэкаутэклипс',
            mainTextileColour: '1',
            systemType: 'standard-chain-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'standardRomanBlind',
    },
    {
      id: '376172d0-ce3d-4e0a-bef3-fe9030a12c55',
      category: 'curtains',
      name: 'Римские "Стандарт" день-ночь',
      nameUk: 'Римські "Стандарт" день-ніч',
      basicExamplePrice: '2433',
      basicExampleUnit: 'шт',
      supplyTimeClass: 10,
      mechanism: 'standard-double-cord-roman',
      corniceSystem: null,
      standart: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Дикий шелк',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
          standardRomanBlindSecondary: {
            mainTextileName: 'Тюль лён',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
          standardRomanBlindDoubleSystem: {
            system: 'ТВИН',
            systemType: 'standard-double-cord-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
          standardRomanBlindSecondary: { amount: 1 },
          standardRomanBlindDoubleSystem: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Лён с отливом',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
          standardRomanBlindSecondary: {
            mainTextileName: 'Тюль лён',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
          standardRomanBlindDoubleSystem: {
            system: 'ТВИН',
            systemType: 'standard-double-cord-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
          standardRomanBlindSecondary: { amount: 1 },
          standardRomanBlindDoubleSystem: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          standardRomanBlind: {
            mainTextileName: 'Блэкаутэклипс',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
          standardRomanBlindSecondary: {
            mainTextileName: 'Тюль лён',
            mainTextileColour: '1',
            systemType: 'standard-cord-roman',
          },
          standardRomanBlindDoubleSystem: {
            system: 'ТВИН',
            systemType: 'standard-double-cord-roman',
          },
        },
        design: {
          standardRomanBlind: { amount: 1 },
          standardRomanBlindSecondary: { amount: 1 },
          standardRomanBlindDoubleSystem: { amount: 1 },
        },
        serviceOptions: {
          mountRomanBlindInhort: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'standardRomanBlindDouble',
    },
    {
      id: '437152d0-ce3d-4e0a-bef3-fe9030a12c53',
      category: 'curtains',
      name: 'Римские шторы день-ночь',
      nameUk: 'Римські штори день-ніч',
      basicExamplePrice: '4007',
      basicExampleUnit: 'шт',
      supplyTimeClass: 2,
      mechanism: 'double-nonsync-roman',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            sku: 'QUEEN 710',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '710',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41459,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            },
          },
          secondaryTextile: {
            id: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            sku: 'NEW VUAL 407',
            supplierSku: 'NEW VUAL',
            name: 'NEW VUAL',
            nameUk: 'NEW VUAL',
            description: 'Вуаль молочний',
            brand: 'ARHOME',
            colour: '407',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '311.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41507,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            },
          },
          romanBlindSystem: {
            id: '327d0988-3d79-4ec5-a051-9c959b86b9d2',
            sku: 'Римські механізми подвійні з двома ланцюжками 60 см',
            supplierSku: 'Римські механізми подвійні з двома ланцюжками 60 см',
            name: 'Римські механізми подвійні з двома ланцюжками 60 см',
            nameUk: 'Римські механізми подвійні з двома ланцюжками 60 см',
            description: null,
            brand: 'АФЕС',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '2055.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            created: '2022-12-01T06:09:30.599Z',
            modified: '2022-12-09T17:46:33.094Z',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            curtainRodData: {
              id: 6148,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              length: 60,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: "Профіль\nВісь приводу (прут 5*5)\nБлок управління (механізм 1:1)\nСуп. зі шнуром (350 см) ЛЮКС\nУтяжелювач полотна 2.0см, емальований\nУтяжелитель ланцюжка (утяж відвісу ланцюга)\nЛанцюжок (звисання 150 см)\nКріплення верхне \nКільця, прозоре\nКільце із захопленням\nЛипучка м'яка 25мм\nПрутики фібергласс\nЗаглушка для Т профілю\nВідвіс ланцюга Веста\nЛюверси\nЗажим круглий з гвинтом",
              },
              notes: null,
              materialId: '327d0988-3d79-4ec5-a051-9c959b86b9d2',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          secondaryTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystemComplex: -1,
          customServices: [],
          combinedSewingComplexity: {
            isPrimaryComplex: false,
            isSecondaryComplex: false,
          },
          combinedHangingComplexity: {
            isPrimaryComplex: false,
            isSecondaryComplex: false,
          },
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Подвійні римські штори (день-ніч)',
          format: 'png',
          fileName: 'roman-blinds-double.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-double.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-double.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-double.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-double.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-double.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            sku: 'KETEN 320',
            supplierSku: 'KETEN',
            name: 'KETEN',
            nameUk: 'KETEN',
            description: 'Рогожка',
            brand: 'ARHOME',
            colour: '320',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1177.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42282,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            },
          },
          secondaryTextile: {
            id: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            sku: '110 10',
            supplierSku: '110',
            name: '0110',
            nameUk: '0110',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '10',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '861.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34731,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            },
          },
          romanBlindSystem: {
            id: '327d0988-3d79-4ec5-a051-9c959b86b9d2',
            sku: 'Римські механізми подвійні з двома ланцюжками 60 см',
            supplierSku: 'Римські механізми подвійні з двома ланцюжками 60 см',
            name: 'Римські механізми подвійні з двома ланцюжками 60 см',
            nameUk: 'Римські механізми подвійні з двома ланцюжками 60 см',
            description: null,
            brand: 'АФЕС',
            colour: null,
            colourSet: null,
            madeIn: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '2055.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            created: '2022-12-01T06:09:30.599Z',
            modified: '2022-12-09T17:46:33.094Z',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            curtainRodData: {
              id: 6148,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              length: 60,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: {
                text: "Профіль\nВісь приводу (прут 5*5)\nБлок управління (механізм 1:1)\nСуп. зі шнуром (350 см) ЛЮКС\nУтяжелювач полотна 2.0см, емальований\nУтяжелитель ланцюжка (утяж відвісу ланцюга)\nЛанцюжок (звисання 150 см)\nКріплення верхне \nКільця, прозоре\nКільце із захопленням\nЛипучка м'яка 25мм\nПрутики фібергласс\nЗаглушка для Т профілю\nВідвіс ланцюга Веста\nЛюверси\nЗажим круглий з гвинтом",
              },
              notes: null,
              materialId: '327d0988-3d79-4ec5-a051-9c959b86b9d2',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          secondaryTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystemComplex: -1,
          customServices: [],
          combinedSewingComplexity: {
            isPrimaryComplex: false,
            isSecondaryComplex: false,
          },
          combinedHangingComplexity: {
            isPrimaryComplex: false,
            isSecondaryComplex: false,
          },
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Подвійні римські штори (день-ніч)',
          format: 'png',
          fileName: 'roman-blinds-double.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-double.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-double.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-double.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-double.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-double.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          secondaryTextile: {
            id: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            sku: '1121 29',
            supplierSku: '1121',
            name: '1121',
            nameUk: '1121',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '29',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '1551.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34713,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            },
          },
          romanBlindSystem: {
            id: 'af939559-b3f3-4598-b551-1ad108618612',
            sku: 'Двойная римская система Twin, ручное управление, 40 см, Суппорт Tw- 2. Цепь 150,. Кр. Clik-2',
            supplierSku: 'Двойная римская система Twin, ручное управление, 40 см, Суппорт Tw- 2. Цепь 150,. Кр. Clik-2',
            name: 'Двойная римская система Twin, ручное управление, 40 см, Суппорт Tw- 2. Цепь 150,. Кр. Clik-2',
            nameUk: 'Подвійна римська система Twin, ручне управління, 40 см, Супорт Tw-2. Ланцюг 150,. Кр. Clik-2',
            description: null,
            brand: null,
            colour: null,
            colourSet: null,
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '3732.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 7462,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: null,
              forProductCategory: ['римські штори'],
              minLength: 0,
              length: 40,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: null,
              notes: null,
              materialId: 'af939559-b3f3-4598-b551-1ad108618612',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          secondaryTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystemComplex: -1,
          customServices: [],
          combinedSewingComplexity: {
            isPrimaryComplex: false,
            isSecondaryComplex: false,
          },
          combinedHangingComplexity: {
            isPrimaryComplex: false,
            isSecondaryComplex: false,
          },
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Подвійні римські штори (день-ніч)',
          format: 'png',
          fileName: 'roman-blinds-double.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-double.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-double.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-double.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-double.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-double.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'romanBlindsDouble',
    },
    {
      id: '468872d0-ce3d-4e0a-bef3-fe9030a12c49',
      category: 'curtains',
      name: 'Римские шторы',
      nameUk: 'Римські штори',
      basicExamplePrice: '15260',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'motorized-roman',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            sku: 'QUEEN 710',
            supplierSku: 'QUEEN',
            name: 'QUEEN Diamond',
            nameUk: 'QUEEN Diamond',
            description: 'Мікровелюр',
            brand: 'Мир Текстиль',
            colour: '710',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '887.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41459,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '3e951d86-4be8-4cf8-a376-8c728d74364a',
            },
          },
          romanBlindSystem: {
            id: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            sku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            supplierSku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            name: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            nameUk: 'Римська система TORRO Моторизована, до 3,5 кг, 80 cm',
            description: 'Суппорт - 2шт, з радіоприймачем, вага штори до 3,5кг.',
            brand: 'TORRO',
            colour: null,
            colourSet: ['Білий'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '12328.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 921,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['римські штори'],
              minLength: 70,
              length: 80,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: { text: 'Кольца прозрачные, кольца "груша", прут ПВХ - 5 шт, утяжелитель ткани' },
              notes: null,
              materialId: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            },
          },
          remoteControlUnit: {
            id: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            sku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            supplierSku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            name: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            nameUk: 'Пульт AC127-01 1-канальний БІЛИЙ, римська система',
            description: null,
            brand: 'TORRO',
            colour: null,
            colourSet: ['Белый'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '1557.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 3284,
              materialType: 'пульт',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['карниз'],
              minLength: 0,
              length: null,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: null,
              notes: null,
              materialId: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          remoteControlUnit: { amount: 1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      premium: {
        composition: {
          mainTextile: {
            id: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            sku: 'KETEN 320',
            supplierSku: 'KETEN',
            name: 'KETEN',
            nameUk: 'KETEN',
            description: 'Рогожка',
            brand: 'ARHOME',
            colour: '320',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '1177.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 42282,
              forProductCategory: ['штори'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '7d8be92c-f3bc-4946-b38d-bc0604939186',
            },
          },
          romanBlindSystem: {
            id: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            sku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            supplierSku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            name: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            nameUk: 'Римська система TORRO Моторизована, до 3,5 кг, 80 cm',
            description: 'Суппорт - 2шт, з радіоприймачем, вага штори до 3,5кг.',
            brand: 'TORRO',
            colour: null,
            colourSet: ['Білий'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '12328.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 921,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['римські штори'],
              minLength: 70,
              length: 80,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: { text: 'Кольца прозрачные, кольца "груша", прут ПВХ - 5 шт, утяжелитель ткани' },
              notes: null,
              materialId: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            },
          },
          remoteControlUnit: {
            id: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            sku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            supplierSku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            name: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            nameUk: 'Пульт AC127-01 1-канальний БІЛИЙ, римська система',
            description: null,
            brand: 'TORRO',
            colour: null,
            colourSet: ['Белый'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '1557.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 3284,
              materialType: 'пульт',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['карниз'],
              minLength: 0,
              length: null,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: null,
              notes: null,
              materialId: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          remoteControlUnit: { amount: 1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      luxury: {
        composition: {
          mainTextile: {
            id: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            sku: 'Castella 1',
            supplierSku: 'Castella',
            name: 'Castella',
            nameUk: 'Castella',
            description: null,
            brand: null,
            colour: '1',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '3348.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: 'e0b3bd68-72e0-420f-89a2-6fd913d2f7d1',
            textileData: {
              id: 16110,
              forProductCategory: ['штори'],
              collection: 'Basic of Rosetti',
              design: null,
              height: null,
              heightTolerance: null,
              width: 305,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'e6ad91d7-2597-48a1-a147-b9844fd56ef6',
            },
          },
          romanBlindSystem: {
            id: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            sku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            supplierSku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            name: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            nameUk: 'Римська система TORRO Моторизована, до 3,5 кг, 80 cm',
            description: 'Суппорт - 2шт, з радіоприймачем, вага штори до 3,5кг.',
            brand: 'TORRO',
            colour: null,
            colourSet: ['Білий'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '12328.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 921,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['римські штори'],
              minLength: 70,
              length: 80,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: { text: 'Кольца прозрачные, кольца "груша", прут ПВХ - 5 шт, утяжелитель ткани' },
              notes: null,
              materialId: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            },
          },
          remoteControlUnit: {
            id: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            sku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            supplierSku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            name: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            nameUk: 'Пульт AC127-01 1-канальний БІЛИЙ, римська система',
            description: null,
            brand: 'TORRO',
            colour: null,
            colourSet: ['Белый'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '1557.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 3284,
              materialType: 'пульт',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['карниз'],
              minLength: 0,
              length: null,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: null,
              notes: null,
              materialId: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          remoteControlUnit: { amount: 1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: {
          title: 'Звичайна класична римська штора',
          format: 'png',
          fileName: 'roman-blinds-simple.png',
          illustrate: 'productVariant',
          url: '/fs/product-types/roman-blinds-simple.png',
          width: 1080,
          height: 1080,
          sizes: {
            full: '/fs/product-types/roman-blinds-simple.png',
            fullSize: { x: 1080, y: 1080 },
            wh600: '/fs/product-types/600wh/roman-blinds-simple.png',
            wh600Size: { x: 600, y: 600 },
            wh400: '/fs/product-types/400wh/roman-blinds-simple.png',
            wh400Size: { x: 400, y: 400 },
            wh200: '/fs/product-types/200wh/roman-blinds-simple.png',
            wh200Size: { x: 200, y: 200 },
          },
        },
      },
      calcMethod: 'romanBlindsSimpleMoto',
    },
    {
      id: '069372d0-ce3d-4e0a-bef3-fe9030a12c49',
      category: 'curtains',
      name: 'Римские шторы из тюля',
      nameUk: 'Римські штори з тюля',
      basicExamplePrice: '14406',
      basicExampleUnit: 'шт',
      supplyTimeClass: 3,
      mechanism: 'motorized-roman',
      corniceSystem: null,
      standart: {
        composition: {
          mainTextile: {
            id: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            sku: 'NEW VUAL 407',
            supplierSku: 'NEW VUAL',
            name: 'NEW VUAL',
            nameUk: 'NEW VUAL',
            description: 'Вуаль молочний',
            brand: 'ARHOME',
            colour: '407',
            colourSet: null,
            madeIn: null,
            unit: 'м.п.',
            unitDivider: 0.05,
            minOrder: null,
            price: '311.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 41507,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: 300,
              heightTolerance: null,
              width: null,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: 'edcc2024-92ea-464b-83cd-de8ab763c2f1',
            },
          },
          romanBlindSystem: {
            id: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            sku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            supplierSku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            name: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            nameUk: 'Римська система TORRO Моторизована, до 3,5 кг, 80 cm',
            description: 'Суппорт - 2шт, з радіоприймачем, вага штори до 3,5кг.',
            brand: 'TORRO',
            colour: null,
            colourSet: ['Білий'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '12328.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 921,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['римські штори'],
              minLength: 70,
              length: 80,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: { text: 'Кольца прозрачные, кольца "груша", прут ПВХ - 5 шт, утяжелитель ткани' },
              notes: null,
              materialId: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            },
          },
          remoteControlUnit: {
            id: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            sku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            supplierSku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            name: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            nameUk: 'Пульт AC127-01 1-канальний БІЛИЙ, римська система',
            description: null,
            brand: 'TORRO',
            colour: null,
            colourSet: ['Белый'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '1557.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 3284,
              materialType: 'пульт',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['карниз'],
              minLength: 0,
              length: null,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: null,
              notes: null,
              materialId: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          remoteControlUnit: { amount: 1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          mainTextile: {
            id: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            sku: '110 10',
            supplierSku: '110',
            name: '0110',
            nameUk: '0110',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '10',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '861.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34731,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '1d873c0e-2d51-4808-8c91-bbac3b5cf48b',
            },
          },
          romanBlindSystem: {
            id: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            sku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            supplierSku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            name: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            nameUk: 'Римська система TORRO Моторизована, до 3,5 кг, 80 cm',
            description: 'Суппорт - 2шт, з радіоприймачем, вага штори до 3,5кг.',
            brand: 'TORRO',
            colour: null,
            colourSet: ['Білий'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '12328.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 921,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['римські штори'],
              minLength: 70,
              length: 80,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: { text: 'Кольца прозрачные, кольца "груша", прут ПВХ - 5 шт, утяжелитель ткани' },
              notes: null,
              materialId: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            },
          },
          remoteControlUnit: {
            id: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            sku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            supplierSku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            name: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            nameUk: 'Пульт AC127-01 1-канальний БІЛИЙ, римська система',
            description: null,
            brand: 'TORRO',
            colour: null,
            colourSet: ['Белый'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '1557.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 3284,
              materialType: 'пульт',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['карниз'],
              minLength: 0,
              length: null,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: null,
              notes: null,
              materialId: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          remoteControlUnit: { amount: 1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          mainTextile: {
            id: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            sku: '1121 29',
            supplierSku: '1121',
            name: '1121',
            nameUk: '1121',
            description: 'Тюль однотонна',
            brand: 'Linder',
            colour: '29',
            colourSet: null,
            madeIn: 'Франція',
            unit: 'м.п.',
            unitDivider: 0.1,
            minOrder: null,
            price: '1551.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: 3,
            type: 'textile',
            supplierId: '9a78fb73-1415-466b-8f47-97ae09d86313',
            textileData: {
              id: 34713,
              forProductCategory: ['тюль'],
              collection: null,
              design: null,
              height: null,
              heightTolerance: null,
              width: 300,
              widthTolerance: null,
              fabricComposition: null,
              textileType: null,
              rapport: null,
              weighted: false,
              notes: null,
              materialId: '5fb31f3a-386c-4787-9ea3-9d6d60da816c',
            },
          },
          romanBlindSystem: {
            id: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            sku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            supplierSku: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            name: 'Римская система TORRO Моторизированная, до 3,5 кг, 80 cm',
            nameUk: 'Римська система TORRO Моторизована, до 3,5 кг, 80 cm',
            description: 'Суппорт - 2шт, з радіоприймачем, вага штори до 3,5кг.',
            brand: 'TORRO',
            colour: null,
            colourSet: ['Білий'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '12328.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 921,
              materialType: 'карниз',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['римські штори'],
              minLength: 70,
              length: 80,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: { text: 'Кольца прозрачные, кольца "груша", прут ПВХ - 5 шт, утяжелитель ткани' },
              notes: null,
              materialId: '1430ce41-567c-4d4d-aed2-f651fdf9f62e',
            },
          },
          remoteControlUnit: {
            id: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            sku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            supplierSku: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            name: 'Пульт AC127-01 1-канальный БЕЛЫЙ, римская система',
            nameUk: 'Пульт AC127-01 1-канальний БІЛИЙ, римська система',
            description: null,
            brand: 'TORRO',
            colour: null,
            colourSet: ['Белый'],
            unit: 'шт.',
            unitDivider: 1,
            minOrder: null,
            price: '1557.00',
            rollAmount: null,
            packageAmount: null,
            supplyTimeClass: null,
            type: 'curtainRod',
            supplierId: 'c582658f-a790-4c5e-9161-e8272614f6df',
            curtainRodData: {
              id: 3284,
              materialType: 'пульт',
              corniceSystem: ['римська'],
              motorizedSystem: 'так',
              forProductCategory: ['карниз'],
              minLength: 0,
              length: null,
              diameter: null,
              overallDiameter: null,
              diameterCompatibility: null,
              width: null,
              height: null,
              bracketProfilesCount: null,
              bracketProfilesConf: null,
              itemComposition: null,
              notes: null,
              materialId: '1d4f56f2-56b1-47c2-b9e4-fcddb84a3525',
            },
          },
        },
        design: {
          mainTextile: { amount: -1 },
          romanBlindSystem: { amount: -2 },
          remoteControlUnit: { amount: 1 },
        },
        serviceOptions: {
          sewingRomanBlinds: -1,
          hangingRomanBlinds: -1,
          mountRomanBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'romanBlindsSimpleMoto',
    },
  ],
  jalousie: [
    {
      id: '592772d0-ce3d-4e0a-bef3-fe9030a12c01',
      category: 'jalousie',
      name: 'Вертикальные жалюзи (ткань)',
      nameUk: 'Вертикальні жалюзі (тканина)',
      basicExamplePrice: '990',
      basicExampleUnit: 'м2',
      supplyTimeClass: 2,
      mechanism: 'vertical-jalousie-127',
      corniceSystem: null,
      standart: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'LINE T',
            mainTextileColour: '6001',
            systemType: 'vertical-jalousie-127',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'DUNA',
            mainTextileColour: '408',
            systemType: 'vertical-jalousie-127',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'SCREEN T',
            mainTextileColour: '10002',
            systemType: 'vertical-jalousie-127',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'jalousieVertical',
    },
    {
      id: '592772d0-ce3d-4e0a-bef3-fe9030a12c00',
      category: 'jalousie',
      name: 'Вертикальные жалюзи (ткань)',
      nameUk: 'Вертикальні жалюзі (тканина)',
      basicExamplePrice: '1091',
      basicExampleUnit: 'м2',
      supplyTimeClass: 2,
      mechanism: 'vertical-jalousie-89',
      corniceSystem: null,
      standart: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'LINE 89',
            mainTextileColour: '6001',
            systemType: 'vertical-jalousie-89',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'BEIRUT 89',
            mainTextileColour: '011',
            systemType: 'vertical-jalousie-89',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'STRING 89',
            mainTextileColour: '0100',
            systemType: 'vertical-jalousie-89',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'jalousieVertical',
    },
    {
      id: 'dc620618-e30a-42c7-b96b-648dbe45d75c',
      category: 'jalousie',
      name: 'Вертикальные жалюзи TULLS',
      nameUk: 'Вертикальні жалюзі TULLS',
      basicExamplePrice: '1942',
      basicExampleUnit: 'м2',
      supplyTimeClass: 2,
      mechanism: 'vertical-jalousie-tulls',
      corniceSystem: null,
      standart: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'LIGHT LINES',
            mainTextileColour: 'ET-01 snow white',
            systemType: 'vertical-jalousie-tulls',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'LINES',
            mainTextileColour: 'DT-01 snow white',
            systemType: 'vertical-jalousie-tulls',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          verticalJalousie: {
            mainTextileName: 'POINTS',
            mainTextileColour: 'ND-01 snow white',
            systemType: 'vertical-jalousie-tulls',
            fasten: 'ceiling',
          },
        },
        design: {
          verticalJalousie: { amount: 1 },
        },
        serviceOptions: {
          mountJalousieSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'jalousieVertical',
    },
  ],
  rollerBlinds: [
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c00',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты MINI SET',
      nameUk: 'Тканинні ролети MINI SET',
      basicExamplePrice: '478',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'MINI SET',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'MINI SET',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'MINI SET',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c01',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты BESTA MINI',
      nameUk: 'Тканинні ролети BESTA MINI',
      basicExamplePrice: '946',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'BESTA MINI',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'BESTA MINI',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'BESTA MINI',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c02',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты BESTA STANDART 25',
      nameUk: 'Тканинні ролети BESTA STANDART 25',
      basicExamplePrice: '1187',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'BESTA STANDART 25',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'BESTA STANDART 25',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'BESTA STANDART 25',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c03',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты BESTA STANDART 28',
      nameUk: 'Тканинні ролети BESTA STANDART 28',
      basicExamplePrice: '1475',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'BESTA STANDART 28',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'BESTA STANDART 28',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'BESTA STANDART 28',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c04',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты STANDART 35',
      nameUk: 'Тканинні ролети STANDART 35',
      basicExamplePrice: '1987',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'STANDART 35',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'STANDART 35',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'STANDART 35',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '8194463b-0925-498f-8521-ec645e29ac36',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты STANDART 38',
      nameUk: 'Тканинні ролети STANDART 38',
      basicExamplePrice: '2120',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'STANDART 38',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'STANDART 38',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'STANDART 38',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c05',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты STANDART 42',
      nameUk: 'Тканинні ролети STANDART 42',
      basicExamplePrice: '2706',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'STANDART 42',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'STANDART 42',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'STANDART 42',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c06',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты STANDART 50',
      nameUk: 'Тканинні ролети STANDART 50',
      basicExamplePrice: '3604',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'STANDART 50',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'STANDART 50',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'STANDART 50',
            systemType: 'open-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c07',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты SKY LINE',
      nameUk: 'Тканинні ролети SKY LINE',
      basicExamplePrice: '1916',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-spring-noautofix-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'SKY LINE',
            systemType: 'open-spring-noautofix-roller',
            fixation: 'Прозрачные крючки',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'SKY LINE',
            systemType: 'open-spring-noautofix-roller',
            fixation: 'Прозрачные крючки',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'SKY LINE',
            systemType: 'open-spring-noautofix-roller',
            fixation: 'Прозрачные крючки',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c08',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты SPRING CORD',
      nameUk: 'Тканинні ролети SPRING CORD',
      basicExamplePrice: '2080',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-carrierTopBar-spring-cordTensionFix-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'SPRING CORD',
            systemType: 'open-carrierTopBar-spring-cordTensionFix-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'SPRING CORD',
            systemType: 'open-carrierTopBar-spring-cordTensionFix-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'SPRING CORD',
            systemType: 'open-carrierTopBar-spring-cordTensionFix-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c09',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты SPRING',
      nameUk: 'Тканинні ролети SPRING',
      basicExamplePrice: '2058',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-spring-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'SPRING',
            systemType: 'open-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'SPRING',
            systemType: 'open-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'SPRING',
            systemType: 'open-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c10',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты SPRING CHAIN',
      nameUk: 'Тканинні ролети SPRING CHAIN',
      basicExamplePrice: '4155',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'open-spring-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'SPRING CHAIN',
            systemType: 'open-spring-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'SPRING CHAIN',
            systemType: 'open-spring-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'SPRING CHAIN',
            systemType: 'open-spring-chain-roller',
            fixation: 'Наклон (леска)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c11',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты BESTA UNI',
      nameUk: 'Тканинні ролети BESTA UNI',
      basicExamplePrice: '1664',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-flat-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'BESTA UNI с плоскими направляющими',
            systemType: 'closed-flat-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'BESTA UNI с плоскими направляющими',
            systemType: 'closed-flat-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'BESTA UNI с плоскими направляющими',
            systemType: 'closed-flat-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c12',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты BESTA UNI',
      nameUk: 'Тканинні ролети BESTA UNI',
      basicExamplePrice: '1927',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-C-type-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'BESTA UNI с П-образными направляющими',
            systemType: 'closed-C-type-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'BESTA UNI с П-образными направляющими',
            systemType: 'closed-C-type-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'BESTA UNI с П-образными направляющими',
            systemType: 'closed-C-type-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c13',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты Bamar',
      nameUk: 'Тканинні ролети Bamar',
      basicExamplePrice: '2411',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-C-type-chain-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'Bamar',
            systemType: 'closed-C-type-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'Bamar',
            systemType: 'closed-C-type-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'Bamar',
            systemType: 'closed-C-type-chain-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c14',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты Bamar 27',
      nameUk: 'Тканинні ролети Bamar 27',
      basicExamplePrice: '4143',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-bigCase-noGuides-chainSmooth-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'Bamar 27',
            systemType: 'closed-bigCase-noGuides-chainSmooth-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'Bamar 27',
            systemType: 'closed-bigCase-noGuides-chainSmooth-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'Bamar 27',
            systemType: 'closed-bigCase-noGuides-chainSmooth-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c15',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты STANDART 38 в коробе',
      nameUk: 'Тканинні ролети STANDART 38 в коробі',
      basicExamplePrice: '3685',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-bigCase-noGuides-chainSmooth-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'STANDART 38 в коробі',
            systemType: 'closed-bigCase-noGuides-chainSmooth-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'STANDART 38 в коробі',
            systemType: 'closed-bigCase-noGuides-chainSmooth-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'STANDART 38 в коробі',
            systemType: 'closed-bigCase-noGuides-chainSmooth-roller',
            fixation: 'Наклон (стальной трос)',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c16',
      category: 'rollerBlinds',
      name: 'Тканевые ролеты Bamar 11',
      nameUk: 'Тканинні ролети Bamar 11',
      basicExamplePrice: '6826',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-C-type-spring-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'Bamar 11',
            systemType: 'closed-C-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'Bamar 11',
            systemType: 'closed-C-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'Bamar 11',
            systemType: 'closed-C-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c17',
      category: 'rollerBlinds',
      name: 'Т/ролеты для мансардных окон Decolux',
      nameUk: 'Т/ролети для мансардних вікон Decolux',
      basicExamplePrice: '5028',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-L-type-spring-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'система для мансардных окон Decolux',
            systemType: 'closed-L-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'система для мансардных окон Decolux',
            systemType: 'closed-L-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'система для мансардных окон Decolux',
            systemType: 'closed-L-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '26690733-9e1f-404b-b068-8ed6395690c9',
      category: 'rollerBlinds',
      name: 'Т/ролеты для мансардных окон Decolux Flat',
      nameUk: 'Т/ролети для мансардних вікон Decolux Flat',
      basicExamplePrice: '5519',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'closed-L-type-spring-roller',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'система для мансардных окон Decolux Flat',
            systemType: 'closed-L-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'система для мансардных окон Decolux Flat',
            systemType: 'closed-L-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'система для мансардных окон Decolux Flat',
            systemType: 'closed-L-type-spring-roller',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
    {
      id: '306118d0-ce3d-4e0a-bef3-fe9030a12c18',
      category: 'rollerBlinds',
      name: 'Муляж тканевых ролет',
      nameUk: 'Муляж тканинних ролет',
      basicExamplePrice: '309',
      basicExampleUnit: 'шт.',
      supplyTimeClass: 2,
      mechanism: 'mock',
      corniceSystem: null,
      standart: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Grass",
            // "mainTextileColour": "White 101",
            mainTextileCategoryId: '0',
            system: 'Муляж',
            systemType: 'mock',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      premium: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Luminis 201-251",
            // "mainTextileColour": "201",
            mainTextileCategoryId: '2',
            system: 'Муляж',
            systemType: 'mock',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      luxury: {
        composition: {
          textileRollerBlind: {
            // "mainTextileName": "Gloss",
            // "mainTextileColour": "White",
            mainTextileCategoryId: '4',
            system: 'Муляж',
            systemType: 'mock',
          },
        },
        design: {
          textileRollerBlind: { amount: 1 },
        },
        serviceOptions: {
          mountRollerBlindSystem: -1,
          customServices: [],
        },
        notes: { sewing: '', hanging: '', mounting: '' },
        image: null,
      },
      calcMethod: 'rollerBlindTextileSimple',
    },
  ],
  curtainRod: [],
};

export default productExamples;
