import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import apiClient from 'api/coreAPI';
import { ComplexError, errorParser } from 'api/errorsParser';
import { RootState } from 'store';
import { User } from 'types/user';

// Додавання нового користувача
type NewUser = Omit<User, 'id'>;

// eslint-disable-next-line consistent-return
export const addUser = createAsyncThunk<void, NewUser, { rejectValue: string }>(
  'users/addUser',
  // eslint-disable-next-line consistent-return
  async (newUser, { rejectWithValue }) => {
    try {
      await apiClient.post('/users', newUser);
    } catch (error) {
      // Приводимо помилку до типу з полем `message`
      const errorMessage = error instanceof Error ? error.message : 'Невідома помилка під час додавання користувача';
      return rejectWithValue(errorMessage); // Передаємо повідомлення про помилку
    }
  },
);

const addUserSlice = createSlice({
  name: 'user',
  initialState: {
    users: [] as User[],
    loading: false,
    error: null as string | null, // Тип 'error' дозволяє null або рядок
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(addUser.pending, state => {
        state.loading = true;
        state.error = null; // Очищаємо помилки під час нового запиту
      })
      .addCase(addUser.fulfilled, state => {
        state.loading = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.loading = false;
        state.error = typeof action.payload === 'string' ? action.payload : 'Не вдалося додати користувача';
      });
  },
});

// Async Thunk для отримання деталей користувача
export const fetchUserDetails = createAsyncThunk<User, string>(
  'users/fetchUserDetails',
  async (userId, { rejectWithValue }) => {
    try {
      const { data } = await apiClient.get<User>(`/users/${encodeURIComponent(userId)}`);
      return data;
    } catch (error) {
      return rejectWithValue('Не вдалося отримати дані користувача');
    }
  },
);

// Отримати посилання на бота
export const fetchTelegramLink = async (userId: string): Promise<string> => {
  try {
    const { data } = await apiClient.get<{ telegram: string }>(`/bot/link/user/${userId}`);
    return data.telegram || '';
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Не вдалося отримати посилання на бота:', error);
    throw new Error('Не вдалося отримати посилання на бота');
  }
};

// Оновлення користувача
export const updateUser = createAsyncThunk<void, User>('users/updateUser', async (user, { rejectWithValue }) => {
  try {
    await apiClient.patch(`/users/${user.id}`, user);
  } catch (error) {
    const parsedError = errorParser(error as ComplexError);
    return rejectWithValue(parsedError); // Якщо є помилка, повертаємо її
  }
  return undefined; // Якщо все успішно, повертаємо явне undefined
});

interface UserState {
  currentUser: any;
  selectedUser: User | null;
  loading: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: UserState = {
  selectedUser: null,
  loading: 'idle',
  error: null,
  currentUser: undefined,
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchUserDetails.pending, state => {
        state.loading = 'loading';
        state.error = null;
        state.selectedUser = null;
      })
      .addCase(fetchUserDetails.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.selectedUser = action.payload;
      })
      .addCase(fetchUserDetails.rejected, (state, action) => {
        state.loading = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const selectUserState = (state: RootState) => state.users;
export const addUserReducer = addUserSlice.reducer;
export const { reducer } = slice;
// export const { reducer: usersReducer } = usersSlice;
