import React from 'react';
import {
  ArrowDownIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  BoldIcon,
  CalendarIcon,
  CenterIcon,
  CheckboxIcon,
  CloseIcon,
  CopyIcon,
  DarkModeIcon,
  EditIcon,
  HighlightIcon,
  HomeIcon,
  H2Icon,
  LightModeIcon,
  MinusIcon,
  OLIcon,
  PlusIcon,
  SearchIcon,
  SettingsIcon,
  SortingIcon,
  SortDownIcon,
  SortUpIcon,
  SpinnerIcon,
  SuccessIcon,
  TelegramIcon,
  ULIcon,
  UnderlineIcon,
  VisibilityIcon,
  VisibilityOffIcon,
} from './icons';

export type IconType =
  | 'ArrowDown'
  | 'ArrowLeft'
  | 'ArrowRight'
  | 'Bold'
  | 'Calendar'
  | 'Center'
  | 'Checkbox'
  | 'Close'
  | 'Copy'
  | 'DarkMode'
  | 'Edit'
  | 'Highlight'
  | 'Home'
  | 'H2'
  | 'LightMode'
  | 'Minus'
  | 'OL'
  | 'Plus'
  | 'Search'
  | 'Settings'
  | 'Sorting'
  | 'SortDown'
  | 'SortUp'
  | 'Spinner'
  | 'Success'
  | 'Telegram'
  | 'UL'
  | 'Underline'
  | 'Visibility'
  | 'VisibilityOff';

export const iconTypes = new Map([
  ['ArrowDown', <ArrowDownIcon key="ArrowDownIcon" />],
  ['ArrowLeft', <ArrowLeftIcon key="ArrowLeftIcon" />],
  ['ArrowRight', <ArrowRightIcon key="ArrowRightIcon" />],
  ['Bold', <BoldIcon key="BoldIcon" />],
  ['Calendar', <CalendarIcon key="CalendarIcon" />],
  ['Center', <CenterIcon key="CenterIcon" />],
  ['Checkbox', <CheckboxIcon key="CheckboxIcon" />],
  ['Close', <CloseIcon key="CloseIcon" />],
  ['Copy', <CopyIcon key="CopyIcon" />],
  ['DarkMode', <DarkModeIcon key="DarkModeIcon" />],
  ['Edit', <EditIcon key="EditIcon" />],
  ['Highlight', <HighlightIcon key="HighlightIcon" />],
  ['Home', <HomeIcon key="HomeIcon" />],
  ['H2', <H2Icon key="H2Icon" />],
  ['LightMode', <LightModeIcon key="LightModeIcon" />],
  ['Minus', <MinusIcon key="MinusIcon" />],
  ['OL', <OLIcon key="OLIcon" />],
  ['Plus', <PlusIcon key="PlusIcon" />],
  ['Search', <SearchIcon key="SearchIcon" />],
  ['Settings', <SettingsIcon key="Settings" />],
  ['Sorting', <SortingIcon key="Sorting" />],
  ['SortDown', <SortDownIcon key="SortDown" />],
  ['SortUp', <SortUpIcon key="SortUp" />],
  ['Spinner', <SpinnerIcon key="SpinnerIcon" />],
  ['Success', <SuccessIcon key="SuccessIcon" />],
  ['Telegram', <TelegramIcon key="TelegramIcon" />],
  ['UL', <ULIcon key="ULIcon" />],
  ['Underline', <UnderlineIcon key="UnderlineIcon" />],
  ['Visibility', <VisibilityIcon key="VisibilityIcon" />],
  ['VisibilityOff', <VisibilityOffIcon key="VisibilityOffIcon" />],
]);
