import { memo } from 'react';
import type { FC } from 'react';
import type { TIconProps } from './types';
import React from 'react';

const IconComponent: FC<TIconProps> = ({ height = 24, width = 24, ...props }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M20 6H10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M20 12H10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M20 18H10" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path
        d="M4.50962 11C4.44551 11 4.38782 10.98 4.33654 10.94C4.29167 10.8943 4.26923 10.8429 4.26923 10.7857V6.70571L2.95192 7.60571C2.90705 7.64 2.85897 7.65714 2.80769 7.65714C2.73718 7.65714 2.67308 7.62286 2.61538 7.55429L2.04808 6.89429C2.01603 6.84857 2 6.80571 2 6.76571C2 6.68571 2.03846 6.62571 2.11538 6.58571L4.32692 5.06C4.39744 5.02 4.47436 5 4.55769 5H5.75962C5.83013 5 5.88782 5.02 5.93269 5.06C5.97756 5.1 6 5.15143 6 5.21429V10.7857C6 10.8429 5.97756 10.8943 5.93269 10.94C5.88782 10.98 5.83013 11 5.75962 11H4.50962Z"
        fill="currentColor"
      />
      <path
        d="M2.23009 19C2.16519 19 2.10914 18.9804 2.06195 18.9411C2.02065 18.9018 2 18.8513 2 18.7896V18.1921C2 18.0238 2.07375 17.8864 2.22124 17.7798L3.14159 16.9215C3.67847 16.5288 4.08555 16.223 4.36283 16.0042C4.64012 15.7854 4.84956 15.5863 4.99115 15.4067C5.13274 15.2272 5.20354 15.0561 5.20354 14.8934C5.20354 14.4502 4.97345 14.2286 4.51327 14.2286C4.27139 14.2286 4.08555 14.2987 3.95575 14.439C3.82596 14.5736 3.74041 14.7447 3.69912 14.9523C3.66372 15.0813 3.56342 15.1459 3.39823 15.1459H2.29204C2.23894 15.1459 2.19469 15.129 2.15929 15.0954C2.12389 15.0617 2.10619 15.0196 2.10619 14.9691C2.11799 14.6101 2.22124 14.2819 2.41593 13.9846C2.61652 13.6816 2.89676 13.4432 3.25664 13.2693C3.62242 13.0898 4.0413 13 4.51327 13C5.00885 13 5.43363 13.0757 5.78761 13.2272C6.14159 13.3787 6.41003 13.5919 6.59292 13.8668C6.77581 14.1417 6.86726 14.4614 6.86726 14.8261C6.86726 15.2188 6.73746 15.5778 6.47788 15.9032C6.22419 16.223 5.83481 16.5708 5.30973 16.9467L4.49558 17.7293H6.76991C6.83481 17.7293 6.88791 17.7489 6.9292 17.7882C6.9764 17.8275 7 17.878 7 17.9397V18.7896C7 18.8513 6.9764 18.9018 6.9292 18.9411C6.88791 18.9804 6.83481 19 6.76991 19H2.23009Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const OLIcon = memo(IconComponent);
