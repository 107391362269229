// prettier-ignore
const messages = {
  // ПОЧАТОК СТРОК ДЛЯ ПЕРЕКЛАДУ
  // ПЕРЕКЛАДУ ПІДЛЯГАЄ ЛИШЕ ПРАВА ЧАСТИНА (ТА ЩО В ЛАПКАХ ПІСЛЯ ДВОКРАПКИ)

  'screens.projects.title': 'Головна',
  'app.description': 'Інструмент дизайнера гардин.',
  'app.name': 'Платформа',
  'app.newVersion.body':
    'Коли вам буде зручно, натисніть кнопку "Оновити" щоб сторінка перезавантажилась у новій версії. Допустимо оновитись пізніше: зараз натисніть кнопку "Закрити", а коли будете готові вийдіть з додатку й закрийте його або вкладку браузера, при наступному відкритті ви користуватиметесь новою версією.',
  'app.newVersion.title': 'Наявна нова версія додатку!',

  'auth.account': 'Акаунт',
  'auth.anonymous': 'Анонім',
  'auth.login': 'Вхід в систему',
  'auth.loginFormDesc': 'Авторизуйтесь на платформі',
  'auth.signIn': 'Увійти',
  'auth.signOut': 'Вийти',
  'auth.signOutFromAllDevices': 'Вийти на усіх пристроях',
  'auth.unableToLogoutNotice': 'Неможливо вийти',
  'auth.forgotPasswordLink': 'Забули пароль?',
  'auth.PasswordRecovery.title': 'Відновлення паролю',
  'auth.PasswordRecovery.expectEmail.desc': 'Введіть email адресу вашого облікового запису',
  'auth.PasswordRecovery.claimFailed.desc': 'Не вдалось застосувати введену email адресу або сплив термін активності процесу. Спробуйте повернутись на екран авторизації й почати процес відновлення паролю заново.',
  'auth.PasswordRecovery.confirmationCode': 'Код підтвердження',
  'auth.PasswordRecovery.expectConfirmation.desc': 'У ваш персональний чат з Платформою мало надійти повідомлення з кодом підтвердження. Введіть цей код у полі нижче.',
  'auth.PasswordRecovery.confirmationFailed.desc': 'Не вдалось підтвердити дійсність коду або сплив термін активності процесу. Спробуйте повернутись на екран авторизації й почати процес відновлення паролю заново.',
  'auth.PasswordRecovery.success.desc': 'Успішно! Новий пароль вже мав надійти вам у повідомленні в персональний чат з Платформою. Можете повернутись на сторінку входу в систему.',
  'auth.sessions': 'Активні сесії авторизації',
  'auth.sessions.short': 'Сесії',
  'auth.sessions.my.control.desc': 'Керування всіма активними сесіями користування застосунком на всіх пристроях та браузерах.',
  'auth.sessions.current': 'Поточна сесія',
  'auth.sessions.start': 'Початок',
  'auth.sessions.latestActivity': 'Остання активність',

  'city': 'Місто',
  // Перехресний переклад населених пунктів (міста та інші)
  // При додаванні нової локалізації, крім перекладу правої частини кожного міста, до кожного з міст потрібно додати новий рядок де ліва та права частини містимуть назву міста згідно нової локалі
  // Також новий ряд з лівою частиною назви міста новою локаллю потрібно додати у всі інші файли локалізації.
  'Київ': 'Київ',
  'Киев': 'Київ',
  'Kyiv': 'Київ',
  'Kijów': 'Київ',
  'Одеса': 'Одеса',
  'Одесса': 'Одеса',
  'Odesa': 'Одеса',
  'Odessa': 'Одеса',
  'Харків': 'Харків',
  'Харьков': 'Харків',
  'Kharkiv': 'Харків',
  'Charków': 'Харків',
  'Львів': 'Львів',
  'Львов': 'Львів',
  'Lviv': 'Львів',
  'Lwów': 'Львів',
  'Дніпро': 'Дніпро',
  'Днепр': 'Дніпро',
  'Dnipro': 'Дніпро',
  'Dniepr': 'Дніпро',
  'Івано-Франківськ': 'Івано-Франківськ',
  'Ивано-Франковск': 'Івано-Франківськ',
  'Ivano-Frankivsk': 'Івано-Франківськ',
  'Iwano-Frankiwsk': 'Івано-Франківськ',
  'Хмельницький': 'Хмельницький',
  'Хмельницкий': 'Хмельницький',
  'Khmelnytskyi': 'Хмельницький',
  'Chmielnicki': 'Хмельницький',
  'Ужгород': 'Ужгород',
  'Uzhhorod': 'Ужгород',
  'Użhorod': 'Ужгород',
  'Мукачево': 'Мукачево',
  'Mukachevo': 'Мукачево',
  'Mukaczewo': 'Мукачево',
  'Софіївська Борщагівка': 'Софіївська Борщагівка',
  'Софиевская Борщаговка': 'Софіївська Борщагівка',
  'Sofiivska Borschahivka': 'Софіївська Борщагівка',
  'Sowijiwśka Borszczahiwka': 'Софіївська Борщагівка',
  'Петропавлівська Борщагівка': 'Петропавлівська Борщагівка',
  'Петропавловская Борщаговка': 'Петропавлівська Борщагівка',
  'Petropavlivska Borshchahivka': 'Петропавлівська Борщагівка',
  'Petropawliwśka Borszczahiwka': 'Петропавлівська Борщагівка',
  'Крюківщина': 'Крюківщина',
  'Крюковщина': 'Крюківщина',
  'Kriukivshchyna': 'Крюківщина',
  'Kriukiwszczyna': 'Крюківщина',
  'Святопетрівське': 'Святопетрівське',
  'Святопетровское': 'Святопетрівське',
  'Sviatopetrivske': 'Святопетрівське',
  'Swiatopetriwskie': 'Святопетрівське',
  'Бориспіль': 'Бориспіль',
  'Борисполь': 'Бориспіль',
  'Boryspil': 'Бориспіль',
  'Boryspol': 'Бориспіль',
  'Бровари': 'Бровари',
  'Бровары': 'Бровари',
  'Brovary': 'Бровари',
  'Browary': 'Бровари',
  'Ірпінь': 'Ірпінь',
  'Ирпень': 'Ірпінь',
  'Irpin': 'Ірпінь',
  'Irpień': 'Ірпінь',
  'Буча': 'Буча',
  'Bucha': 'Буча',
  'Bucza': 'Буча',
  'Обухів': 'Обухів',
  'Обухов': 'Обухів',
  'Obukhiv': 'Обухів',
  'Obuchów': 'Обухів',
  'Вишгород': 'Вишгород',
  'Вышгород': 'Вишгород',
  'Vyshhorod': 'Вишгород',
  'Wyszogród': 'Вишгород',
  'Вишневе': 'Вишневе',
  'Вишневое': 'Вишневе',
  'Vyshneve': 'Вишневе',
  'Wysznewe': 'Вишневе',
  'Боярка': 'Боярка',
  'Boyarka': 'Боярка',
  'Bojarka': 'Боярка',
  'Гатне': 'Гатне',
  'Гатное': 'Гатне',
  'Hatne': 'Гатне',
  'Тарасівка': 'Тарасівка',
  'Тарасовка': 'Тарасівка',
  'Tarasivka': 'Тарасівка',
  'Tarasiwka': 'Тарасівка',
  'Білогородка': 'Білогородка',
  'Белогородка': 'Білогородка',
  'Bilohorodka': 'Білогородка',
  'Biłohorodka': 'Білогородка',
  'Українка': 'Українка',
  'Украинка': 'Українка',
  'Ukrainka': 'Українка',
  'Васильків': 'Васильків',
  'Васильков': 'Васильків',
  'Vasylkiv': 'Васильків',
  'Wasylkiw': 'Васильків',
  'Черкаси': 'Черкаси',
  'Черкассы': 'Черкаси',
  'Cherkasy': 'Черкаси',
  'Czerkasy': 'Черкаси',
  'Полтава': 'Полтава',
  'Poltava': 'Полтава',
  'Połtawa': 'Полтава',
  'Рівне': 'Рівне',
  'Ровно': 'Рівне',
  'Rivne': 'Рівне',
  'Równe': 'Рівне',
  'Вінниця': 'Вінниця',
  'Винница': 'Вінниця',
  'Vinnytsia': 'Вінниця',
  'Winnica': 'Вінниця',
  'Запоріжжя': 'Запоріжжя',
  'Запорожье': 'Запоріжжя',
  'Zaporizhzhia': 'Запоріжжя',
  'Zaporoże': 'Запоріжжя',
  'Славутич': 'Славутич',
  'Slavutych': 'Славутич',
  'Sławutycz': 'Славутич',
  'Кривий Ріг': 'Кривий Ріг',
  'Кривой Рог': 'Кривий Ріг',
  'Kryvyi Rih': 'Кривий Ріг',
  'Krzywy Róg': 'Кривий Ріг',
  'Миколаїв': 'Миколаїв',
  'Николаев': 'Миколаїв',
  'Mykolaiv': 'Миколаїв',
  'Mikołajów': 'Миколаїв',
  'Житомир': 'Житомир',
  'Zhytomyr': 'Житомир',
  'Żytomierz': 'Житомир',
  'Чернігів': 'Чернігів',
  'Чернигов': 'Чернігів',
  'Chernihiv': 'Чернігів',
  'Czernihów': 'Чернігів',
  'Херсон': 'Херсон',
  'Kherson': 'Херсон',
  'Chersoń': 'Херсон',
  'Луцьк': 'Луцьк',
  'Луцк': 'Луцьк',
  'Lutsk': 'Луцьк',
  'Łuck': 'Луцьк',
  'Суми': 'Суми',
  'Сумы': 'Суми',
  'Sumy': 'Суми',
  'Краматорськ': 'Краматорськ',
  'Краматорск': 'Краматорськ',
  'Kramatorsk': 'Краматорськ',

  'consultant.steps.combined.size': 'Розміри (ширина по карнизу х висота виробу)',
  'consultant.steps.step': 'Крок',
  'consultant.steps.table.category': 'Категорія',
  'consultant.steps.tableHead.draperyCoefficient.short': 'КД',
  'consultant.steps.tableHead.draperyCoefficient': 'Коефіцієнт драпірування',
  'consultant.steps.tableHead.luxury': 'Люкс',
  'consultant.steps.tableHead.premium': 'Преміум',
  'consultant.steps.tableHead.standard': 'Стандарт',

  'designer.steps.step1': 'Тип виробу',
  'designer.steps.step2': 'Базові параметри',
  'designer.steps.step3': 'Бланк виробу',

  'errors.error': 'Помилка',
  'errors.unknown': 'Невідома помилка. Зверніться до підтримки.',
  'errors.error404': '404: Не знайдено',
  'errors.pageNotFound.description':
    'Можливо ви самостійно ввели помилкову адресу або сторінка, яку ви хотіли відвідати, вже не актуальна.',
  'errors.pageNotFound.title': '404: Такої сторінки не існує',

  'form.city': 'Населений пункт',
  'form.residentialComplex': 'Житловий комплекс',
  'form.address': 'Адреса',
  'form.emailAddress': 'Електронна адреса',
  'form.emailAlerts': 'Сповіщення електронною поштою',
  'form.emailLabel': 'Email Адреса',
  'form.emailRequiredNotice': 'Email необхідний',
  'form.emailValidationNotice': 'Має бути дійсним email',
  'form.language': 'Мова програми',
  'form.firstName': "Ім'я",
  'form.firstNameRequiredNotice': "Потрібно вказати ім'я",
  'form.lastName': 'Прізвище',
  'form.lastNameRequiredNotice': 'Потрібно вказати прізвище',
  'form.secondName': 'По-батькові',
  'form.secondNameRequiredNotice': 'Потрібно вказати по-батькові',
  'form.materials.searchResults.message': 'Данний артикул не знайдено',
  'form.materials.searchResults.placeholder': 'Введіть артикул',
  'form.minCharacters': 'Має містити принаймні 8 символів',
  'form.passwordConfirmation': 'Повторіть пароль',
  'form.passwordLabel': 'Пароль',
  'form.passwordRequiredNotice': 'Пароль необхідний',
  'form.passwordsMustMatch': 'Паролі повинні збігатися',
  'form.passwordUpdated': 'Пароль оновлено',
  'form.phones': 'Номери телефонів',
  'form.phoneNumber': 'Номер телефону',
  'form.additionalPhoneNumber': 'Додатковий телефон',
  'form.profile': 'Профіль',
  'form.profileUpdated': 'Профіль оновлено',
  'form.pushNotifications': 'Push-сповіщення',
  'form.system': 'Система',
  'form.projectRooms.inputHelper': 'Оберіть одне або кілька приміщень із запропонованих варіантів або введіть власні.',
  'form.projectRooms.emptyNotice': 'Попередньо налаштуйте перелік можливих приміщень!',
  'form.telegramNotifications': 'Повідомлення Telegram',
  'form.weWillUseThisEmailToContactYou': "Ми використаємо цю електронну пошту для зв'язку з вами",
  'form.youWillReceiveEmails': 'Ви отримаєте електронні листи на свою електронну бізнес адресу',
  'form.password': 'Пароль не повинен бути коротшим за 8 символів',
  'form.validate.minValue': 'Мінімальне значення {value}',
  'form.validate.maxValue': 'Максимальне значення {value}',

  'generic.in': 'в ', // важливо зберігати пробіл після літери
  'generic.all': 'Всі',
  'generic.add': 'Додати',
  'generic.assortment': 'Асортимент',
  'generic.new': 'Новий',
  'generic.next': 'Далі',
  'generic.confirm': 'Підтвердити',
  'generic.compoundPersonName': 'ПІБ',
  'generic.variables': 'Змінні',
  'generic.loading': 'Завантаження',
  'generic.download': 'Завантажити',
  'generic.repeat': 'Повторити',
  'generic.repeatTrying': 'Повторити спробу',
  'generic.processStart': 'Початок процесу...',
  'generic.processing': 'Виконання процесу...',
  'generic.moreOptions': 'Більше варіантів',
  'generic.moreActions': 'Більше варіантів дій',
  'generic.noOptions': 'Немає варіантів',
  'generic.addOption': 'Додати "{value}"',
  'generic.category': 'Категорія',
  'generic.change': 'Змінити',
  'generic.changes': 'Зміни',
  'generic.discardChanges': 'Відмінити зміни',
  'generic.clear': 'Очистити',
  'generic.clearSession': 'Очистити сесію',
  'generic.clearBlank': 'Очистити бланк',
  'generic.close': 'Закрити',
  'generic.copied': 'Скопійовано',
  'generic.colour': 'Колір',
  'generic.description': 'Опис',
  'generic.createdAsc': 'Дата створення (спочатку нові)',
  'generic.createdDesc': 'Дата створення (спочатку старі)',
  'generic.current': 'Поточний',
  'generic.days': `{days, plural, one {# день} =2 {# дні} =3 {# дні} =4 {# дні} =22 {# дні} =23 {# дні} =24 {# дні} other {# днів} }`,
  'generic.delete': 'Видалити',
  'generic.edit': 'Змінити',
  'generic.view': 'Огляд',
  'generic.export': 'Експорт',
  'generic.filter': 'Фільтр',
  'generic.type': 'Тип',
  'generic.from': 'від',
  'generic.import': 'Імпорт',
  'generic.material': 'Матеріал',
  'generic.notifications': 'Повідомлення',
  'generic.prev': 'Додати інший варіант',
  'generic.price': 'Ціна',
  'generic.total': 'Всього',
  'generic.required': "Обов'язкове",
  'generic.send': 'Надіслати',
  'generic.save': 'Зберегти',
  'generic.save.changes': 'Зберегти зміни',
  'generic.security': 'Безпека',
  'generic.settings': 'Налаштування',
  'generic.sortBy': 'Сортувати за',
  'generic.succeeded': 'Успішно',
  'generic.failed': 'Невдало',
  'generic.sum': 'Сума',
  'generic.totalPrice': 'Сума',
  'generic.discount': 'Знижка',
  'generic.switch': 'Перемкнути',
  'generic.dragHandleLabel': 'Потягніть за іконку щоб перемістити на інше місце',
  'generic.room.title': 'Приміщення',
  'generic.img': 'Зображення',
  'generic.title': 'Назва',
  'generic.undefined': 'Невизначено',
  'generic.unselected': 'Не обрано',
  'generic.update': 'Оновити',
  'generic.sku': 'Артикул',
  'generic.width': 'Ширина',
  'generic.height': 'Висота',
  'generic.length': 'Довжина',
  'generic.diameter': 'Діаметр',
  'generic.inner': 'внутрішній',
  'generic.inner.short': 'вн.',
  'generic.outer': 'зовнішній',
  'generic.outer.short': 'зовн.',
  'generic.skuAsc': 'Артикул (А-Я/A-Z)',
  'generic.skuDesc': 'Артикул (Я-А/Z-A)',
  'generic.lastNameAsc': 'Прізвище (А-Я/A-Z)',
  'generic.latestActivity': 'Остання активність',
  'generic.supplier': 'Постачальник',
  'generic.brand': 'Бренд',
  'generic.mechanism': 'Механізм',
  'generic.fasteningMechanism': 'Механізм кріплення',
  'generic.mountTarget': 'Тип кріплення',
  'generic.dimensions': 'Розміри',
  'generic.mainFabric': 'Основна тканина, арт.',
  'generic.cornice': 'Карниз',
  'generic.choose': 'Вибрати',
  'generic.chooseAs': 'Обрати як',
  'generic.draperyCoefficient': 'Коефіцієнт драпірування',
  'generic.draperyCoefficient.short': 'КД',
  'generic.cancel': 'Скасувати',
  'generic.hinges': 'Навіска',
  'generic.montage': 'Монтаж',
  'generic.dismantling': 'Демонтаж',
  'generic.customAny': 'Довільні',
  'generic.wall': 'На стіну',
  'generic.casement': 'На створку',
  'generic.ceiling': 'На стелю',
  'generic.window.hole': 'В проєм',
  'generic.other': 'Інше',
  'generic.empty': 'Ваш список порожній',
  'generic.amount': 'Кількість',
  'generic.unit': 'Одиниці',
  'generic.component.amount': 'Кількість на виріб',
  'generic.component.unit': 'Одиниця виміру',
  'generic.component.amountToBuy': 'Кількість купівлі',
  'generic.component.unitToBuy': 'Од. виміру',
  'generic.rollAmount': 'Метраж рулону',
  'generic.packageAmount': 'Шт. в пакунку',
  'generic.model': 'Модель',
  'generic.cascade': 'Каскадна збірка',
  'generic.decoration': 'Варіант декору',
  'generic.mainTextileName': 'Назва основної тканини',
  'generic.mainTextileColour': 'Колір основної тканини',
  'generic.edgingTextileName': 'Назва тканини канту',
  'generic.edgingTextileColour': 'Колір тканини канту',
  'generic.grommetColour': 'Колір люверсів',
  'generic.chainFixation': 'Фіксація шнура/ланцюга карнизу',
  'generic.systemType': 'Тип системи карнизу',
  'generic.systemColour': 'Колір карнизу',
  'generic.system': 'Варіант системи карнизу',
  'generic.nonStandardForm': 'Нестандартна форма',
  'generic.controlSide': 'Сторона керування',
  'generic.controlChainLength': 'Висота ланцюга керування',
  'generic.fixation': 'Фіксація штори',
  'generic.fixation.curtain': 'Фіксація штори',
  'generic.none': 'Немає',
  'generic.sewing': 'Пошиття',
  'generic.sewingAndProcessing': 'Спосіб пошиття та обробки',
  'generic.hangingFor': 'Для навіски',
  'generic.hanging': 'Навіска',
  'generic.mounting': 'Для монтажу',
  'generic.mount': 'Монтаж',
  'generic.disassembling': 'Демонтаж',
  'generic.service': 'Послуга',
  'generic.isComplex': 'Складна',
  'generic.toOrder': 'Оформити',
  'generic.ordering': 'Оформлення',
  'generic.note': 'Нотатка',
  'generic.serviceName': 'Назва послуги',
  'generic.compositionGroup': 'Група складових',
  'generic.AllProducts': 'Всі вироби',
  'generic.payments': 'Платежі',
  'generic.image': 'Зображення',
  'generic.sketch': 'Ескіз',
  'generic.position': 'Позиція',
  'generic.control': 'Керування',
  'generic.projectServices.onlyDelivery.products': 'Обрані для доставки вироби',
  'generic.projectServices.suburbanTransfer.distance': 'Кілометраж',
  'generic.projectServices.suburbanTransfer.transferType': 'Призначення трансферу',
  'generic.projectServices.suburbanTransfer.deliveryId': 'Послуга доставки якої стосується виїзд',
  'generic.projectDiscounts': 'Група знижки',
  'generic.projectDiscounts.property.group': 'Група знижки',
  'generic.projectDiscounts.property.percent': 'Відсоток знижки',
  'generic.projectDiscounts.property.flat': 'Сума знижки',
  'generic.projectDiscounts.property.oneTimeCode': 'Промокод',
  'generic.projectDiscounts.property.referralCode': 'Партнерський код',
  'generic.name': 'Назва',
  'generic.nameUk': 'Назва (укр)',
  'generic.packageAmount.long': 'Кількість штук в пакунку',
  'generic.workingDays': 'робочих днів',
  'generic.workingDays.short': 'р.д.',

  'nav.back': 'Назад',
  'nav.backToHome': 'Повернутись на головну',
  'nav.main': 'Головна',
  'nav.dashboard': 'Dashboard',
  'nav.projects': 'Проєкти',
  'nav.materials': 'Матеріали',
  'nav.imports': 'Підсумок імпорту матеріалів',
  'nav.admin': 'Адмінка',
  'nav.blankOrder': 'Бланк замовлення',
  'nav.consultation': 'Консультація',
  'nav.userAdministration': 'Користувачі',
  'nav.needHelp': 'Потрібна допомога?',
  'nav.documentation': 'Документація',

  'compositionGroup.materials': 'Матеріали',
  'compositionGroup.system': 'Карниз',
  'compositionGroup.accessories': 'Аксесуари',

  'unit.м.п.': 'м.п.',
  'unit.шт.': 'шт.',
  'unit.рулон': 'рулон',
  'unit.уп.': 'уп.',
  'unit.комплект': 'комплект',
  'unit.кг': 'кг',
  'component.unit.м.п.': 'м.п.',
  'component.unit.шт.': 'шт.',
  'component.unit.рулон': 'рулон',
  'component.unit.уп.': 'уп.',
  'component.unit.комплект': 'комплект',
  'component.unit.кг': 'кг',
  'component.unitToBuy.м.п.': 'м.п.',
  'component.unitToBuy.шт.': 'шт.',
  'component.unitToBuy.рулон': 'рулон',
  'component.unitToBuy.уп.': 'уп.',
  'component.unitToBuy.комплект': 'комплект',
  'component.unitToBuy.кг': 'кг',

  'note.sewing': 'Спосіб пошиття та обробки',
  'note.hanging': 'Для навіски',
  'note.mounting': 'Для монтажу',

  'product.service.combinedComplexity': 'Комбінація складності',
  'product.service.combinedComplexity.primary': 'Основна',
  'product.service.combinedComplexity.secondary': 'Друга',
  'romanBlindsDouble.service.combinedComplexity.primary': 'Штора',
  'romanBlindsDouble.service.combinedComplexity.secondary': 'Тюль',

  'service.sewingCurtains': 'Пошиття комплектів штор',
  'service.sewingCurtainsComplex': 'Пошиття комплектів штор складних',
  'service.sewingRomanBlinds': 'Пошиття римських штор простих',
  'service.sewingRomanBlindsComplex': 'Пошиття римських штор складних',
  'service.sewingBlanket': 'Пошиття покривал',
  'service.sewingCustom': 'Нестандартне пошиття',
  'service.hangingCurtains': 'Навіска тюлі/штори',
  'service.hangingRomanBlinds': 'Навіска римської штори',
  'service.hangingComplex': 'Складна навіска',
  'service.hangingBlanket': 'Покривало просте (прасування і вкладання)',
  'service.hangingBlanketComplex': 'Покривало з оборками та ін. декором (прасування і вкладання)',
  'service.hangingChairCover': 'Чохол на стілець простий (прасування і вкладання)',
  'service.hangingChairCoverComplex': 'Чохол на стілець з оборкою, бантом та ін. декором (прасування і вкладання)',
  'service.hangingCustom': 'Нестандартна навіска',
  'service.mountCurtainRod': 'Монтаж шторного карнизу ПРОСТИЙ',
  'service.mountCurtainRodComplex': 'Монтаж шторного карнизу СКЛАДНИЙ',
  'service.mountRomanBlindSystem': 'Монтаж римського карнизу ПРОСТИЙ',
  'service.mountRomanBlindSystemComplex': 'Монтаж римського карнизу СКЛАДНИЙ',
  'service.mountRomanBlindInhort': 'Монтаж готової римської "Стандарт" ПРОСТИЙ',
  'service.mountRomanBlindInhortComplex': 'Монтаж готової римської "Стандарт" СКЛАДНИЙ',
  'service.mountRollerBlindSystem': 'Монтаж тканинних ролет ПРОСТИЙ',
  'service.mountRollerBlindSystemComplex': 'Монтаж тканинних ролет СКЛАДНИЙ',
  'service.mountJalousieSystem': 'Монтаж жалюзі ПРОСТИЙ',
  'service.mountJalousieSystemComplex': 'Монтаж жалюзі СКЛАДНИЙ',
  'service.mountCafeSystem': 'Монтаж міні-карнизу "кафе"',
  'service.mountHookDrapery': 'Монтаж гачків драпірувальних (без карнизів)',
  'service.mountCustom': 'Нестандартний монтаж',
  'service.disassembling': 'Тип послуги Демонтаж',
  'service.disassemblingCurtainRod': 'Демонтаж старих карнизів для встановлення нових',
  'service.disassemblingCustom': 'Нестандартний демонтаж',

  'mechanism.chainRoman': 'Ланцюгово-роторний',
  'mechanism.closedBigCaseNoGuidesChainSmoothRoller':
    'Закрита система з великим коробом без напрямних, ланцюговий плавний механізм',
  'mechanism.closedCTypeChainRoller': 'Закрита система з П-подібними напрямними, ланцюговий механізм',
  'mechanism.closedCTypeSpringRoller': 'Закрита система з П-подібними напрямними, пружинний механізм',
  'mechanism.closedFlatChainRoller': 'Закрита система з плоскими напрямними, ланцюговий механізм',
  'mechanism.closedLTypeSpringRoller': 'Закрита система з Г-подібними напрямними, пружинний механізм',
  'mechanism.cordRoman': 'Мотузковий',
  'mechanism.cordSemiautoRoman': 'Ланцюгово-роторний з автоспуском',
  'mechanism.doubleNonsyncRoman': 'Ланцюгово-роторний подвійний незалежний',
  'mechanism.doubleSyncRoman': 'Ланцюгово-роторний подвійний синхронний',
  'mechanism.mock': 'Муляж',
  'mechanism.motorizedRoman': 'Моторизований',
  'mechanism.none': 'Без карнизу',
  'mechanism.openCarrierTopBarSpringCordTensionFixRoller':
    'Відкрита система на несущій верхній планці, пружинний механізм з фіксацією на шнуровому натягу',
  'mechanism.openChainRoller': 'Відкрита система, ланцюговий механізм',
  'mechanism.openSpringChainRoller': 'Відкрита система, ланцюгово-пружинний механізм',
  'mechanism.openSpringNoautofixRoller': 'Відкрита система, пружинний механізм без самофіксації',
  'mechanism.openSpringRoller': 'Відкрита система, пружинний механізм',
  'mechanism.standardChainRoman': 'Стандарт ланцюгово-роторний',
  'mechanism.standardCordRoman': 'Стандарт мотузковий',
  'mechanism.standardDoubleCordRoman': 'Стандарт подвійний мотузковий',
  'mechanism.verticalJalousie127': 'Верт. жалюзі 127 мм',
  'mechanism.verticalJalousie89': 'Верт. жалюзі 89 мм',
  'mechanism.verticalJalousieTulls': 'Верт. жалюзі Tulls',

  'product.title': 'Назва виробу',
  'product.dimensions': 'Розміри та інші числові параметри',
  'product.dimensions.secondarySameAsPrimary': 'Розміри співпадають',

  'product.component.group.productFullfillment': 'Продукція',

  'product.component.group.textile': 'Тканини',
  'product.component.mainTextile': 'Основна тканина',
  'product.component.secondaryTextile': 'Друга тканина',
  'product.component.thirdTextile': 'Третя тканина',
  'product.component.fourthTextile': 'Четверта тканина',
  'product.component.edgingTextile': 'Тканина канту',
  'product.component.liningTextile': 'Підкладка',
  'product.component.doublerine': 'Дублерін',

  'product.component.group.webbing': 'Тасьма',
  'product.component.webbing': 'Тасьма',
  'product.component.webbing4Stick': 'Тасьма під прут',
  'product.component.webbingAdhesive': 'Тасьма-липучка',
  'product.component.webbingDecorative': 'Тасьма декоративна',

  'product.component.group.furniture': 'Фурнітура',
  'product.component.grommet': 'Люверс',
  'product.component.sRings': 'S-кільця',
  'product.component.magnets': 'Магніти',
  'product.component.zipper': 'Блискавка',
  'product.component.syntheticFluff': 'Синтепух',
  'product.component.sintepon': 'Синтепон',

  'product.component.group.curtainRodAndAccessory': 'Карнизи та аксесуари',

  'product.component.group.curtainRod': 'Карнизи',
  'product.component.curtainRod': 'Труба',
  'product.component.curtainRod2': 'Труба-2',
  'product.component.curtainProfile': 'Профіль',
  'product.component.curtainProfile2': 'Профіль-2',
  'product.component.handpiece': 'Накінечники',
  'product.component.handpiece2': 'Накінечники-2',
  'product.component.cap': 'Заглушки',
  'product.component.cap2': 'Заглушки-2',
  'product.component.stopper': 'Стопор',
  'product.component.stopper2': 'Стопор-2',
  'product.component.pipeConnector': "З'єднувач труби/профілю",
  'product.component.pipeConnector2': "З'єднувач труби/профілю-2",
  'product.component.bracketExtension': 'Подовжувач кронштейну',
  'product.component.bracketFasten': 'Кронштейн',
  'product.component.wallBracket': 'Стіновий кронштейн',
  'product.component.clipsFasten': 'Кліпси',
  'product.component.thirdPipeHolder': 'Тримач третьої труби',
  'product.component.bendingCurtainRod': 'Гнуття карнизу',
  'product.component.romanBlindSystem': 'Римська система',
  'product.component.sticks': 'Прут',
  'product.component.weightingAgent': 'Обтяжувач',
  'product.component.chain': 'Ланцюг',
  'product.component.chainWeighter': 'Грузок',
  'product.component.fixation': 'Фіксація',
  'product.component.hookSigma': 'Сігма',
  'product.component.runners': 'Бігунки',
  'product.component.runners2': 'Бігунки-2',
  'product.component.rings4CurtainRod': 'Кільця',
  'product.component.rings4CurtainRod2': 'Кільця-2',
  'product.component.lambrequinLath': 'Планка ламбрикен',
  'product.component.motor': 'Мотор',
  'product.component.miniCurtainRod': 'Міні карниз',

  'product.component.group.accessory': 'Аксесуари',
  'product.component.hookDrapery': 'Гачки драпірувальні',
  'product.component.rosette': 'Розетка',
  'product.component.pin': 'Прищіпка',
  'product.component.tieBack': 'Підхвати',
  'product.component.curtainGuides': 'Шторовідники',
  'product.component.powerSupplyUnit': 'Блок живлення',
  'product.component.charger': 'Зарядний пристрій',
  'product.component.remoteControlUnit': 'Пульт ДК',
  'product.component.smartHub': 'Smart HUB',

  'product.component.group.productFulfillment': 'Стандартизовані вироби',
  'product.component.standardRomanBlind': 'Римська штора "Стандарт"',
  'product.component.standardRomanBlindSecondary': 'Друга римська штора "Стандарт"',
  'product.component.standardRomanBlindDoubleSystem': 'Подвійний механізм римської штори "Стандарт"',
  'product.component.textileRollerBlind': 'Тканинні ролети',
  'product.component.verticalJalousie': 'Вертикальні жалюзі',

  'product.component.shortName.mainTextile': 'Основна',
  'product.component.shortName.secondaryTextile': 'Друга',
  'product.component.shortName.thirdTextile': 'Третя',
  'product.component.shortName.fourthTextile': 'Четверта',
  'product.component.shortName.edgingTextile': 'Кант',
  'product.component.shortName.liningTextile': 'Підкладка',
  'product.component.shortName.doublerine': 'Дублерін',

  'product.design.width': 'Ширина виробу, м',
  'curtainsSimple.design.width': 'Ширина по карнизу, м',
  'curtainsSimpleGrommet.design.width': 'Ширина по карнизу, м',
  'romanBlindsDouble.design.width': 'Ширина полотна штори, м',
  'product.design.height': 'Висота виробу, м',
  'romanBlindsDouble.design.height': 'Висота полотна штори, м',
  'product.design.widthSecondary': 'Ширина 2-го полотна, м',
  'romanBlindsDouble.design.widthSecondary': 'Ширина полотна тюлі, м',
  'product.design.heightSecondary': 'Висота 2-го полотна, м',
  'romanBlindsDouble.design.heightSecondary': 'Висота полотна тюлі, м',

  'product.types.curtainRod': 'Карнизи',
  'product.types.curtains': 'Штори',
  'product.types.jalousie': 'Жалюзі',
  'product.types.rollerBlinds': 'Ролети',
  'product.types.romanBlinds': 'Римські',

  'product.error.unableToCalculate': 'Виріб містить помилки, що унеможливлюють обчислення. Доповніть налаштування, бо в такому вигляді виріб неможливо зберегти.',

  'role': 'Роль',
  'role.admin': 'Адміністратор',
  'role.support': 'Підтримка',
  'role.director': 'Директор',
  'role.accountant': 'Бухгалтер',
  'role.representative': 'Представник',
  'role.consultant': 'Консультант',
  'role.designer': 'Дизайнер',
  'role.manager': 'Менеджер',
  'role.production': 'Виробництво',
  'role.atelier': 'Ательє',
  'role.distributor': 'Комплектувальник',
  'role.mounter': 'Монтувальник',
  'role.hanger': 'Навісник',
  'role.courier': 'Кур’єр',
  'role.intern': 'Інтерн',

  'skill': 'Здібність',
  'skill.manageSaloon': 'Менеджер салону',
  'skill.callCenter': 'КЦ (кол-центр)',
  'skill.manageDialog': 'Діалог-менеджер',
  'skill.manageHoveredProjects': 'Менеджер по думаючим',
  'skill.startingProjects': 'Запуск замовлень',
  'skill.KVP': 'КВП (керівник відділу продажів)',
  "skill.noSkill": 'Без здібностей',

  'position': 'Посада',
  'position.creator': 'Творець',
  'position.admin': 'Адміністратор',
  'position.support': 'Підтримка',
  'position.director': 'Директор',
  'position.accountant': 'Бухгалтер',
  'position.representative': 'Представник',
  'position.consultant': 'Ведучий менеджер',
  'position.designer': 'Дизайнер',
  'position.manager': 'Менеджер',
  'position.production': 'Виробництво',
  'position.atelier': 'Ательє',
  'position.distributor': 'Комплектувальник',
  'position.mounter': 'Монтувальник',
  'position.hanger': 'Навісник',
  'position.courier': 'Кур’єр',
  'position.intern': 'Інтерн',
  'position.manageSaloon': 'Менеджер салону',
  'position.callCenter': 'КЦ',
  'position.manageDialog': 'Діалог-менеджер',
  'position.manageHoveredProjects': 'Менеджер по думаючим',
  'position.startingProjects': 'Фахівець із запуску',

  'settings.autoUpdateNotice':
    'Ми автоматично встановили вам початкові налаштування. Ви завжди можете їх змінити у відповідних панелях керування.',
  'settings.general': 'Загальне',

  'settings.language': 'Мова',
  // Опції мовної локалізації (кожен варіант правої частини має залишатись оригінальною мовою)
  'settings.language.uk': 'Українська',
  'settings.language.ru': 'Русский',
  'settings.language.pl': 'Polski',

  'settings.settingsUpdated': 'Налаштування оновлень',
  'settings.theme': 'Стиль інтерфейсу',

  'steps.step1': 'Обрати вироби',
  'steps.step2': 'Редагувати',
  'steps.step3': 'Виклик на замір',

  'table.rowsPerPage': 'Рядків на сторінках',
  'table.of': 'з',

  'views.consultant.title': 'Консультант',
  'views.consultant.clearSession': 'Дійсно очистити поточну сесію консультації?',

  'views.ProjectDesigner.pageTitle': 'Бланк замовлення',
  'views.designer.title': 'Дизайнер',
  'views.designer.newProduct': 'Створити новий виріб',
  'views.designer.freeform': 'Довільний виріб',
  'views.designer.curtainsSimpleDrape': 'Портьєра на тасьмі',
  'views.designer.curtainsSimpleTulle': 'Тюль на тасьмі',
  'views.designer.curtainsSimpleGrommetDrape': 'Портьєра на люверсах',
  'views.designer.curtainsSimpleGrommetTulle': 'Тюль на люверсах',
  'views.designer.romanBlindsSimple': 'Римська штора проста',
  'views.designer.romanBlindsLined': 'Римська штора з підкладкою',
  'views.designer.romanBlindsDouble': 'Подвійні римські штори',
  'views.designer.romanBlindsSimpleWithoutSystem': 'Римська штора без карнизу',
  'views.designer.romanBlindsSimpleMoto': 'Моторизована римська штора',
  'views.designer.romanBlindsDoubleMoto': 'Моторизована римська день-ніч',
  'views.designer.standardRomanBlind': 'Римські штори "Стандарт"',
  'views.designer.standardRomanBlindDouble': 'Римські "Стандарт" день-ніч',
  'views.designer.clearProjectTool.question': 'Видалити поточне замовлення і очистити бланк?',
  'views.designer.deleteProductModal.title': 'Видалити «{product}»?',
  'views.designer.deleteProductModal.body': 'Увага! Дані будуть видалені безповоротно',
  'views.designer.materials.title': 'Матеріали',
  'views.designer.services.title': 'Послуги',
  'views.designer.summary.title': 'Підсумок',
  'views.designer.orderSummary.title': 'Підсумок замовлення',
  'views.designer.comments.title': 'Коментарі',
  'views.designer.productComponent': 'Тип складової',
  'views.designer.orderContent': 'Зміст замовлення',
  'views.designer.editOrderContent': 'Редагувати зміст замовлення',
  'views.designer.addDiscount': 'Додати знижку',
  'views.designer.saveOrder': 'Оформити замовлення',
  'views.designer.sendOrder': 'Відправити замовлення',

  'views.projects.searchLabel': 'Пошук за номером запуску, телефоном, іменем, прізвищем, адресою, містом, ID',
  'views.projects.count': `{count, plural, one {Всього # замовлення} =2 {Всього # замовлення} =3 {Всього # замовлення} =4 {Всього # замовлення} =5 {Всього # замовлень} =21 {Всього # замовлення} =23 {Всього # замовлення} =24 {Всього # замовлення} =25 {Всього # замовлень} other {Всього # замовлень} }`,
  'views.projects.continueToProjectEditing': 'Продовжити редагування проєкту',

  'views.ProjectViewer.loading': 'Огляд замовлення ID:{id}',
  'views.ProjectViewer.pageTitle': 'Огляд замовлення №{orderNr} згідно угоди №{dealId} (ID:{id})',
  'views.ProjectViewer.title': 'Замовлення (без номеру запуску, номер угоди не визначений)',
  'views.ProjectViewer.titleWithDealId': 'Замовлення (без номеру запуску) згідно угоди №{dealId}',
  'views.ProjectViewer.titleStarted': 'Замовлення №{orderNr} (номер угоди не визначений)',
  'views.ProjectViewer.titleStartedWithDealId': 'Замовлення №{orderNr} згідно угоди №{dealId}',
  'views.ProjectViewer.internalID': 'Внутрішній ID: {id}',
  'views.ProjectViewer.participants': 'Учасники проєкту',
  'views.ProjectViewer.addPaymentsButton': 'Додати платежі',
  'views.ProjectViewer.goToNotEmptyEditorMessage': 'Ви плануєте перейти на один з екранів редагування замовлення. Проте в даний момент інше замовлення вже перебуває в процесі редагування.',
  'views.ProjectViewer.goToNotEmptyEditorWarning': 'Якщо ви підтвердите дію — всі не збережені зміни в те замовлення будуть втрачені!',
  'views.ProjectViewer.totals': 'Загальний підсумок',
  'views.ProjectViewer.services': 'Деталізація послуг по замовленню',
  'views.ProjectViewer.serviceComplexity': 'Складність',
  'views.ProjectViewer.serviceComplexity.normal': 'Проста',
  'views.ProjectViewer.serviceComplexity.complex': 'Складна',
  'views.ProjectViewer.producedItems': 'Вироби',
  'views.ProjectViewer.sewing': 'Пошиття',
  'views.ProjectViewer.sewing.sewingCurtains': 'Комплектів штор',
  'views.ProjectViewer.sewing.sewingRomanBlinds': 'Римських штор',
  'views.ProjectViewer.sewing.sewingBlanket': 'Покривал',
  'views.ProjectViewer.sewing.sewingCustom': 'Нестандартне',
  'views.ProjectViewer.hanging': 'Навішування',
  'views.ProjectViewer.hanging.curtainsNormal': 'Штори/тюлі',
  'views.ProjectViewer.hanging.romanBlindsNormal': 'Римської штори',
  'views.ProjectViewer.hanging.hangingBlanket': 'Покривала (прасування і вкладання)',
  'views.ProjectViewer.hanging.hangingChairCover': 'Чохла на стілець (прасування і вкладання)',
  'views.ProjectViewer.hanging.hangingCustom': 'Нестандартне',
  'views.ProjectViewer.mount': 'Монтаж',
  'views.ProjectViewer.mount.mountCurtainRod': 'Шторного карнизу',
  'views.ProjectViewer.mount.mountRomanBlindSystem': 'Римської системи',
  'views.ProjectViewer.mount.mountRomanBlindInhort': 'Готової римської "Стандарт"',
  'views.ProjectViewer.mount.mountRollerBlindSystem': 'Тканинних ролет',
  'views.ProjectViewer.mount.mountJalousieSystem': 'Жалюзі',
  'views.ProjectViewer.mount.mountCafeSystem': 'Міні-карнизу "Кафе"',
  'views.ProjectViewer.mount.mountHookDrapery': 'Гачків драпірувальних (без карнизів)',
  'views.ProjectViewer.mount.mountCustom': 'Нестандартний',
  'views.ProjectViewer.disassembling': 'Демонтаж',
  'views.ProjectViewer.disassembling.disassemblingCurtainRod': 'старих карнизів для встановлення нових',
  'views.ProjectViewer.disassembling.disassemblingCustom': 'нестандартний',
  'views.ProjectViewer.customServices': 'Довільні послуги',
  'views.ProjectViewer.products': 'Вироби',
  'views.ProjectViewer.products.itemsCount': '(кількість окремих елементів у виробництво - {count})',
  'views.ProjectViewer.productDrafts': 'Чернетки виробів',
  'views.ProjectViewer.documents': 'Документи до замовлення',
  'views.ProjectViewer.documents.autogenerateNotice': 'Готовий документ був відсутнім - створюю новий',

  'documents.generateNew': 'Згенерувати новий документ',
  'documents.client': 'Клієнтський',
  'documents.common': 'Загальний виробничий',
  'documents.mounter': 'Монтажний',

  'views.EditClient.notFound': 'Клієнт не знайдений. Заповніть його картку самостійно.',
  'views.EditClient.selectAmongDuplicates': 'Клієнт (оберіть серед знайдених дублікатів)',

  'views.ProductViewer.note.sewing': 'Спосіб пошиття та обробки',
  'views.ProductViewer.note.hanging': 'Примітки щодо навішування',
  'views.ProductViewer.note.mounting': 'Примітки щодо монтажу',

  'views.ProjectEditor.pageTitle': 'Редактор замовлення',
  'views.ProjectEditor.discardProjectChanges.question': 'Відмінити всі здійснені та ще не збережені зміни в замовлення?',
  'views.ProjectEditor.saveProjectChanges.preConfirmationInfo': 'Ви плануєте зберегти зміни у вироби, приміщення та супровідні послуги замовлення. Система оновить документи та відправить їх у всі потрібні чати.',
  'views.ProjectEditor.saveProjectChanges.preConfirmationWarning': 'Зверніть увагу, що зміни в дані оформлення цією дією не зберігаються! Якщо необхідно змінити статус, знижку чи інші параметри даних оформлення або додати платіж, то зберігати потрібно на екрані «Дані оформлення».',
  'views.ProjectEditor.registrationData': 'Дані оформлення',
  'views.ProjectEditor.displayProductDrafts': `{count, plural, one {Показати # чернетку} =2 {Показати # чернетки} =3 {Показати # чернетки} =4 {Показати # чернетки} =5 {Показати # чернеток} =21 {Показати # чернетку} =23 {Показати # чернетки} =24 {Показати # чернетки} =25 {Показати # чернеток} other {Показати # чернетки} }`,
  'views.ProjectEditor.crmIdSave.title': 'Зберегти ідентифікатор угоди',
  'views.ProjectEditor.crmIdSave.notice': 'Введіть ідентифікатор угоди та натисніть «Зберегти». Запит на збереження ідентифікатора відбудеться негайно. Навіть в результаті успіху жодних повідомлень у чати не надходитимуть! Зверніть увагу, що збереженню підлягає лише унікальний ідентифікатор: до однієї угоди може бути поєднаний лише один проєкт.',
  'views.ProjectEditor.crmIdSave.success': 'ID угоди успішно збережений',

  'views.SubProjectExtractor.pageTitle': 'Створення нового замовлення з обраних виробів',
  'views.SubProjectExtractor.returnToBaseProject': 'Повернутись до основного замовлення',
  'views.SubProjectExtractor.editNewProject': 'Редагувати нове замовлення',
  'views.SubProjectExtractor.starterButton': 'Зібрати в окреме замовлення',
  'views.SubProjectExtractor.starterButton.preConfirmationInfo': 'Після підтвердження буде створене нове замовлення, що складатиметься з обраних виробів поточного, а також отримає аналогічні послуги та знижки. Нове замовлення стосуватиметься того самого клієнта, за тою ж адресою.',
  'views.SubProjectExtractor.starterButton.preConfirmationWarning': 'Нове замовлення розглядається як незалежне від поточного. Воно отримає початковий статус «Вимір» і рахується, що по ньому не було здійснено жодних платежів.',
  'views.SubProjectExtractor.error.notice': 'Процес перервано через помилку. Опис помилки має виникнути внизу екрану. Залежно від його змісту, нове замовлення можливо вже було частково створене. Проте для впевненості в повноті та правильності даних краще спробувати повторити запит одразу або попередньо повернутись до основного замовлення й виправити недоліки які спричинили проблему.',
  'views.SubProjectExtractor.success.notice': 'Нове замовлення даного клієнта створено успішно! Ви можете одразу перейти в редактор новоствореного замовлення кнопкою нижче або знайти його пізніше серед проєктів у статусі «Вимір». Нове замовлення отримало всі зображення що стосуються його виробів, активних приміщень та самого замовлення в цілому. Рахується що жодних платежів не було здійснено.',
  'views.SubProjectExtractor.success.info': 'Ви також можете повернутись до редагування основного замовлення кнопкою вгорі екрану. Зауважте що всі вироби в основному замовленні залишились без змін. Зокрема якщо ви редагували їх але не зберігали зміни — нове замовлення побудоване вже з нових версій, а основне досі містить старі варіанти. Єдина зміна яка відбулась в редакторі основного замовлення (проте не збережена) це вимкнення активності виробів які винесені у нове замовлення та обрання протилежних.',

  'views.ProductEditor.pageTitle': 'Редактор виробу',
  'views.ProductEditor.material.notFound': 'Матеріал не знайдено. Змініть параметри запиту або заповніть його картку самостійно.',
  'views.ProductEditor.material.history': 'Історія вибору матеріалів',

  'views.SummaryImports.date': 'Дата',
  'views.SummaryImports.mode': 'Режим імпорту',
  'views.SummaryImports.dateSearch.from': 'Пошук від',
  'views.SummaryImports.dateSearch.to': 'Пошук до',
  'views.SummaryImports.mode.update': 'Доповнення / Оновлення',
  'views.SummaryImports.mode.reCreate': 'Частковий перезапис',
  'views.SummaryImports.mode.allReset': 'Повний перезапис',
  'views.SummaryImports.errors': 'Проблеми',
  'views.SummaryImports.warnings': 'Зауваження',
  'views.SummaryImports.success': 'Успішно',
  'views.SummaryImports.tables': 'Таблиці матеріалів',
  'views.SummaryImports.searchSku': 'Пошук Артикулів',
  'views.SummaryImports.summaryImport': 'Підсумок імпорту',
  'views.SummaryImports.summaryImport.add': 'Додані',
  'views.SummaryImports.summaryImport.update': 'Оновлені',
  'views.SummaryImports.summaryImport.duration': 'Час виконання',
  'views.SummaryImports.summaryImport.sheetImported': 'Таблиці що імпортувались',
  'views.SummaryImports.summaryImport.sheetImported.success': 'Успішно розглянуті',
  'views.SummaryImports.summaryImport.sheetImported.error': 'Помилка читання',

  'views.payments.title': 'Платежі по замовленню',
  'views.payments.existedPayments': 'Здійснені платежі',
  'views.payments.changeStatus': 'Змінити статус',
  'views.payments.addPayment': 'Додати платіж',
  'views.payments.addPayments': `{count, plural, one {Додати платіж} other {Додати платежі}}`,
  'views.payments.updateProjectNote': 'Доповнити нотатку по замовленню',
  'views.payments.updatePaymentsNote': 'Доповнити нотатки щодо платежів',
  'views.payments.crmIdRequired': 'Введіть ідентифікатор угоди адже він обов\'язково має бути в призначенні платежу!',
  'views.payments.noPayOfficesNotice': 'Відсутні варіанти каси — перезавантажте будь-ласка сторінку.',
  'views.payments.enterpriseNotice': 'Платникам ФОП чи юридичним особам обовʼязково формувати «Рахунок-фактуру». Зверніться до відповідального менеджера за формуванням!',
  'views.payments.invoiceLink': 'Посилання на оплату',
  'views.payments.copyInvoiceDetails': 'Копіювати реквізити',
  'views.payments.success': 'Платіж успішно зафіксований',

  'views.materials.title': 'Матеріали',
  'views.materials.newOffer': 'Новий матеріал',
  'views.materials.searchOffers': 'Пошук матеріалів',
  'views.materials.platform.sku': 'Артикул',
  'views.materials.supplier.sku': 'Артикул постачальника',
  'views.materials.supplier.price': 'Ціна постачальника',
  'views.materials.export.title': 'Експорт таблиці цін на матеріали',
  'views.materials.export.fromAllSuppliers': 'від усіх постачальників',
  'views.materials.export.fromSupplier': 'від постачальника «{name}»',
  'views.materials.noBrandsAvailable': 'Немає доступних брендів',
  'views.materials.noColorsAvailable': 'Немає доступних кольорів',
  'views.materials.noColoursAvailable': 'Немає доступних кольорів',
  'views.materials.noWidthsAvailable': 'Немає доступних ширин',
  'views.materials.noCompositionsAvailable': 'Немає доступних складів',
  'views.materials.noPatternsAvailable': 'Немає доступних малюнків',
  'views.materials.noTexturesAvailable': 'Немає доступних текстур',
  'views.materials.noTypesAvailable': 'Немає доступних типів',
  'views.materials.noCategoriesAvailable': 'Немає доступних категорій',
  'views.materials.noMaterialsAvailable': 'Немає доступних матеріалів',
  'views.materials.noMaterialsFound': 'Матеріали не знайдені',
  'views.materials.noMaterialsSelected': 'Матеріали не обрані',
  'views.materials.noSuppliersAvailable': 'Немає доступних постачальників',
  'views.materials.noSuppliersSelected': 'Постачальники не обрані',
  'views.materials.noSuppliersFound': 'Постачальники не знайдені',
  'views.materials.noOffersFound': 'Пропозиції не знайдені',
  'views.materials.noOffersSelected': 'Пропозиції не обрані',
  'views.materials.noOffersAvailable': 'Немає доступних пропозицій',
  'views.materials.noOffersForSelectedSuppliers': 'Немає пропозицій від обраних постачальників',
  'views.materials.noOffersForSelectedBrands': 'Немає пропозицій від обраних брендів',
  'views.materials.noOffersForSelectedMaterials': 'Немає пропозицій від обраних матеріалів',
  'views.materials.noOffersForSelectedCategories': 'Немає пропозицій від обраних категорій',
  'views.materials.noOffersForSelectedTypes': 'Немає пропозицій від обраних типів',
  'views.materials.noOffersForSelectedColors': 'Немає пропозицій від обраних кольорів',
  'views.materials.noOffersForSelectedTextures': 'Немає пропозицій від обраних текстур',
  'views.materials.noOffersForSelectedPatterns': 'Немає пропозицій від обраних малюнків',
  'views.materials.noOffersForSelectedWidths': 'Немає пропозицій від обраних ширин',
  'views.materials.noOffersForSelectedHeights': 'Немає пропозицій від обраних висот',
  'views.materials.noOffersForSelectedDensities': 'Немає пропозицій від обраних густин',
  'views.materials.noOffersForSelectedCompositions': 'Немає пропозицій від обраних складів',
  'views.materials.noOffersForSelectedProperties': 'Немає пропозицій від обраних властивостей',
  'views.materials.noOffersForSelectedFeatures': 'Немає пропозицій від обраних функцій',
  'views.materials.noOffersForSelectedFinishes': 'Немає пропозицій від обраних обробок',


  'document.selector.label': 'Шаблон документу',
  'document.template.TextileMaterialPricelist': 'Таблиця цін на тканини',
  'document.template.WebbingMaterialPricelist': 'Таблиця цін на тасьму',
  'document.template.FurnitureMaterialPricelist': 'Таблиця цін на фурнітуру',
  'document.template.CurtainRodMaterialPricelist': 'Таблиця цін на карнизи та аксесуари',
  'document.restriction.noAllSuppliersForTextileCurtainRod': 'Недопустимо виконання запиту на таблицю цін тканин, карнизів чи аксесуарів від всіх постачальників разом через технічне обмеження. Якщо потрібні всі таблиці асортименту - виконайте запити по кожному постачальникові окремо. Вибір постачальника у фільтрі таблиці.',
  'document.state.creation': 'створення документу',
  'document.state.queued': 'перебуває у черзі',
  'document.state.recreation': 'створення оновленого документу',
  'document.state.done': 'готовий до завантаження',
  'document.state.failure': 'помилка генерації',
  'document.awaiting.estimatedTimeRemaining': 'орієнтовний час готовності: {time}',
  'document.awaiting.completedAt': 'виконано {dateTime}',

  'product.total.materialsPrice': 'Матеріали (всього)',
  'product.total.systemAndAccessoriesPrice': 'Карниз та аксесуари (всього)',
  'product.total.productPrice': 'Вартість виробу',
  'product.total.optionalServicesPrice': 'Додаткові послуги (всього)',

  'order.total.disassembling.total': 'Демонтаж (всього)',
  'order.total.disassembling.disassemblingCurtainRod': 'Демонтаж старих карнизів для встановлення нових',
  'order.total.disassembling.disassemblingCustom': 'Нестандартний демонтаж',
  'order.total.sewing.curtains': 'Пошиття штор',
  'order.total.sewing.romanBlinds': 'Пошиття римських штор',
  'order.total.sewing.sewingBlanket': 'Пошиття покривал',
  'order.total.sewing.sewingCustom': 'Нестандартне пошиття',
  'order.total.sewing.total': 'Пошиття (всього)',
  'order.total.hanging.curtains': 'Навіска тюлі/штори',
  'order.total.hanging.romanBlinds': 'Навіска римської штори',
  'order.total.hanging.blanketTotal': 'Прасування і вкладання покривал',
  'order.total.hanging.chairCoverTotal': 'Прасування і вкладання чохлів на стільці',
  'order.total.hanging.hangingCustom': 'Нестандартна навіска',
  'order.total.hanging.total': 'Навіска (всього)',
  'order.total.mount.curtainRodTotal': 'Монтаж шторного карнизу',
  'order.total.mount.romanBlindTotal': 'Монтаж римського карнизу',
  'order.total.mount.romanBlindInhortTotal': 'Монтаж готової римської "Стандарт"',
  'order.total.mount.jalousieAndRollerBlindTotal': 'Монтаж тканинних ролет та жалюзі',
  'order.total.mount.cafeSystemTotal': 'Монтаж міні-карнизу "кафе"',
  'order.total.mount.hookDraperyTotal': 'Монтаж гачків драпірувальних (без карнизів)',
  'order.total.mount.mountCustom': 'Нестандартний монтаж',
  'order.total.mount.total': 'Монтаж (всього)',
  'order.total.custom.total': 'Довільні послуги (всього)',
  'order.total.projectServices.onlyDeliveryTotal': 'Доставка по місту',
  'order.total.projectServices.suburbanTransferForMeasuring': 'Виїзд за місто на вимір',
  'order.total.projectServices.suburbanTransferOtherTotal': 'Виїзди за місто (майбутні)',
  'order.total.projectServices.total': 'Супровідні послуги (всього)',
  'order.total.materials': 'Матеріали (всього)',
  'order.total.systemAndAccessories': 'Карнизи та аксесуари (всього)',
  'order.total.productsPrice': 'Сума вартості виробів',
  'order.total.services': 'Загальна вартість послуг',
  'order.total.prepaid': 'Передоплата ({value}%)',
  'order.total.expectedPayments': 'Очікувані платежі',
  'order.total.expectedPayments.no': 'Замовлення сплачене в повному розмірі.',
  'order.total.expectedPayments.prepaid': 'Передоплата ({value}%)',
  'order.total.expectedPayments.notCompletePrepaidRemainder': 'Очікувана передоплата ₴{wholePrepayment} не внесена цілком, нестача',
  'order.total.expectedPayments.suburbanTransferForMeasuring': 'Виїзд за місто на вимір',
  'order.total.expectedPayments.surcharge': 'Доплата',
  'order.total.expectedPayments.surchargeCombined': 'Залишок до повної оплати',
  'order.total.inAll': 'Всього',
  'order.total.splitByRoom': "(окремо по приміщенням)",
  'order.total.economyOnCompoundOrder': "Економія об'єднання",
  'order.total.discounts': 'Знижки',
  'order.total.discounts.custom': 'Індивідуальна знижка',
  'order.total.discounts.flyer': 'Знижка за флаєром',
  'order.total.discounts.referral': 'Партнерська знижка',
  'order.total.discounts.compensatory': 'Компенсація дорожчання замовлення',
  'order.total.discounts.warranty': 'Компенсація по гарантії',
  'order.total.totalAfterDiscount': 'Всього зі знижками',
  'order.total.VAT': 'ПДВ ({percent}%)',
  'order.total.VAT.including': 'у т.ч. ПДВ {percent}%',
  'order.total.totalWithVAT': 'Всього з ПДВ',
  'order.total.general': 'Загальна вартість замовлення',

  'images.imagesAndSketches.title': 'Зображення та ескізи',
  'images.error.rejectedFetching': "Проблема завантаження зображень. Перевірте наявність інтернет зв'язку.",
  'images.error.rejectedUpload': "Проблема збереження",
  'images.error.unknownUser': "Проблема авторизації користувача для додавання зображення",
  'images.error.unknownProject': "Проблема визначення проєкту для додавання зображення до нього",
  'images.printInDocuments.set.true': 'Зображення не друкується у документах. Натисніть щоб включити',
  'images.printInDocuments.set.false': 'Зображення потрапляє у документи. Натисніть щоб виключити з них',

  'project.status.new': 'Консультація',
  'project.status.scheduledVisit': 'Призначено вимір',
  'project.status.measuring': 'Вимір',
  'project.status.hover': 'Думають',
  'project.status.reserve': 'Резерв',
  'project.status.prepaid': 'Оплата',
  'project.status.started': 'В роботі',
  'project.status.production': 'У виробництві',
  'project.status.completeSet': 'Комплектація',
  'project.status.mount': 'Монтаж',
  'project.status.mountDone': 'Монтаж здійснено',
  'project.status.hanging': 'Навішування',
  'project.status.delivery': 'Доставка',
  'project.status.refactoring': 'Переробка',
  'project.status.repairing': 'Ремонт',
  'project.status.repairOrRefactorDone': 'Переробку/Ремонт Здійснено',
  'project.status.surchargeReceived': 'Отримана доплата',
  'project.status.completed': 'Завершено',
  'project.status.refusal': 'Відмова',

  'project.subStatus.hesitates': 'Вагається',
  'project.subStatus.commercialProposal': 'Підготувати комерційну пропозицію',
  'project.subStatus.invoice': 'Надати рахунок',
  'project.subStatus.contract': 'Скласти договір',

  'project.status': 'Статус',
  'project.subStatus': 'Уточнення статусу',
  'project.isRemote': 'Дистанційне',
  'project.deliveryAndMount.title': 'Доставка і монтаж',
  'project.supplyTimeClass': 'Термін виконання, р.д.',
  'project.supplyTimeClass.withoutUnit': 'Термін виконання',
  'project.created': 'Дата створення',
  'project.modified': 'Дата останньої зміни',
  'project.measuringDate': 'Дата виміру',
  'project.measuringDateTime': 'Дата, час виміру',
  'project.startDate': 'Дата запуску',
  'project.deliveryDate': 'Дата доставки',
  'project.mountingDate': 'Дата монтажу',
  'project.finishDate': 'Дата завершення',
  'project.finishDateTime': 'Дата, час завершення',
  'project.productionItemsCount': 'К-ть виробів',
  'project.productionItemsCount.full': 'Кількість виробів',
  'project.address.full': 'Повна адреса',
  'project.addressOfClient': 'Адреса доставки збігається з адресою клієнта',
  'project.crmId': 'Ідентифікатор угоди',
  'project.note': 'Загальні нотатки',
  'project.paymentsNote': 'Нотатки щодо платежів',
  'project.isPrepaidOnTheSpot': 'Передоплата на замірі',
  'project.clientKind': 'Вид клієнта як платника',
  'project.clientKind.undefinedNotice': 'спочатку оберіть вид клієнта як платника',

  'clientKind.private-person': 'Фізична особа',
  'clientKind.private-entrepreneur-VAT-non-payer': 'ФОП без ПДВ',
  'clientKind.private-entrepreneur-VAT-payer': 'ФОП з ПДВ',
  'clientKind.LLC-VAT-non-payer': 'ТОВ без ПДВ',
  'clientKind.LLC-VAT-payer': 'ТОВ з ПДВ',

  'projectServices.title': 'Супровідні послуги',
  'projectServices.onlyDelivery': 'Доставка',
  'projectServices.suburbanTransfer': 'Виїзд за місто',
  'projectServices.suburbanTransfer.distanceLimit.desc':
    'Введіть кілометраж по лічильнику понад {distanceLimit} км від КПП в обидві сторони',
  'projectServices.suburbanTransfer.transferType.measuring': 'Вимір',
  'projectServices.suburbanTransfer.transferType.hangingAndMount': 'Навішування і монтаж (одним виїздом)',
  'projectServices.suburbanTransfer.transferType.hanging': 'Навішування (окремий виїзд)',
  'projectServices.suburbanTransfer.transferType.mount': 'Монтаж (окремий виїзд)',
  'projectServices.suburbanTransfer.transferType.delivery': 'Доставка',
  'projectServices.suburbanTransfer.deliveryId.notAvailable':
    'Для кожної з наявних доставок вже призначено трансфер. Даний виїзд зайвий!',
  'projectServices.view.measuringTransferNotice': 'оплачується окремим платежем',
  'projectServices.view.delivery': 'Доставка по місту',
  'projectServices.view.delivery.all': 'всіх виробів',
  'projectServices.view.delivery.selected': 'обраних виробів',
  'projectServices.view.suburbanTransfer':
    'Виїзд за місто на {distance} км понад {distanceLimit} км від КП.',
  'projectServices.view.suburbanTransfer.transferType.title': 'Призначення',

  'projectDiscounts.custom.title': 'Індивідуальна знижка',
  'projectDiscounts.flyer.title': 'Знижка за флаєром',
  'projectDiscounts.referral.title': 'Партнерська знижка',
  'projectDiscounts.compensatory.title': 'Компенсація дорожчання замовлення',
  'projectDiscounts.warranty.title': 'Компенсація по гарантії',
  'projectDiscounts.custom': 'Індивідуальна',
  'projectDiscounts.flyer': 'Флаєр',
  'projectDiscounts.referral': 'Партнерська',
  'projectDiscounts.compensatory': 'Компенсаторна',
  'projectDiscounts.warranty': 'Гарантійна',

  'finance.amount': 'Сума, грн',
  'finance.when': 'Дата здійснення',
  'finance.paymentDetails': 'Реквізити',
  'finance.purposeOfPayment': 'Призначення платежу',
  'finance.payOffice': 'Каса',
  'finance.paymentType': 'Тип платежу',
  'finance.paymentType.full': 'Повний',
  'finance.paymentType.prepaid': 'Передоплата',
  'finance.paymentType.collateral': 'Аванс',
  'finance.paymentType.surcharge': 'Доплата',
  'finance.paymentType.suburbanTransfer': 'Виїзд за місто',
  'finance.paymentForm': 'Форма',
  'finance.paymentForm.cash': 'Готівкою',
  'finance.paymentForm.cash.short': 'гот.',
  'finance.paymentForm.non-cash': 'Безготівково',
  'finance.paymentForm.non-cash.short': 'б/г',
  'finance.notCompletePrepaidRemainder': 'Введена сума менша за очікувану передоплату на ₴{remainder}',
  'finance.notCompleteSurchargeRemainder': 'Введена сума менша за очікувану доплату на ₴{remainder}',
  'finance.notCompleteFullRemainder': 'Введена сума менша за очікувану повну оплату на ₴{remainder}',
  'finance.notCompleteTransferRemainder': 'Введена сума менша за очікувану вартість виїзду на ₴{remainder}',

  'company': 'Компанія',
  'company.companyCode': 'ЄДРПОУ',

  'client.title': 'Клієнт',
  'client.created': 'Дата реєстрації',
  'client.modified': 'Останнє оновлення',
  'client.address.main': 'Основна адреса',

  'participant.started': 'Дата залучення',
  'participant.city': 'Регіон',

  'materialUsage.isManualAmount.label': 'Вводити кількість вручну',
  'materialUsage.isManualPrice.label': 'Вводити вартість вручну',

  'material.textile.label.collection': 'Колекція',
  'material.textile.label.design': 'Дизайн',
  'material.textile.label.textileType': 'Вид тканини',
  'material.textile.label.fabricComposition': 'Склад тканини',
  'material.textile.label.rapport': 'Раппорт',
  'material.textile.label.weighted': 'Обтяжувач',
  'material.textile.label.supplyTimeClass': 'Термін виробництва з тканини',
  'material.webbing.label.supplyTimeClass': 'Термін виробництва з тасьми',
  'material.label.colourSet': 'Доступні кольори',
  'material.label.unitDivider': 'Кратність поділу',
  'material.label.supplyTimeClass': 'Термін виробу',
  'material.label.fabricComposition': 'Склад',
  'material.label.itemComposition': 'Комплектація',
  'material.label.forProductCategory': 'Категорії продукції призначення',
  'material.label.forProductCategory.short': 'Призначення',
  'material.label.materialType': 'Вид матеріалу',
  'material.label.corniceSystem': 'Система карнизу (механізму)',
  'material.label.corniceSystem.short': 'Система',
  'material.label.motorizedSystem': 'Моторизована система',
  'material.label.diameterCompatibility': 'Сумісний із карнизами діаметрів',
  'material.label.bracketProfilesConf': 'Конфігурація кронштейну',
  'material.label.madeIn': 'Країна виробництва',
  'material.label.madeIn.short': 'Країна',
  'material.label.minOrder': 'Мінімальне замовлення',
  'material.label.minOrder.short': 'Мін. замовлення',
  'material.label.notes': 'Примітки',

  'model.romanBlindSolo': 'СОЛО',
  'model.romanBlindLine': 'ЛАЙН',
  'model.romanBlindStella': 'СТЕЛЛА',
  'model.romanBlindPrizma': 'ПРІЗМА',
  'model.romanBlindOlimpia': 'ОЛІМПІЯ',
  'model.romanBlindVenice': 'ВЕНЕЦІЯ',
  'model.romanBlindMilano': 'МІЛАНО',
  'model.romanBlindQuadro': 'КВАДРО',

  'systemType.standard-cord-roman': 'Стандарт мотузковий',
  'systemType.standard-chain-roman': 'Стандарт ланцюгово-роторний',
  'systemType.standard-double-cord-roman': 'Стандарт подвійний мотузковий',
  'systemType.standard-double-chain-roman': 'Стандарт подвійний ланцюгово-роторний',

  'systemColour.white': 'Білий',
  'systemColour.brown': 'Коричневий',

  'fixation.none': 'Немає',

  'decoration.none': 'Немає',

  'controlSide.left': 'Ліва',
  'controlSide.right': 'Права',

  'controlChainLength.25': '25 см',
  'controlChainLength.50': '50 см',
  'controlChainLength.75': '75 см',
  'controlChainLength.100': '100 см',
  'controlChainLength.125': '125 см',
  'controlChainLength.150': '150 см',
  'controlChainLength.175': '175 см',
  'controlChainLength.200': '200 см',
  'controlChainLength.225': '225 см',
  'controlChainLength.250': '250 см',

  'nonStandardForm.none': 'Немає',
  'nonStandardForm.triangle': 'Трикутник',
  'nonStandardForm.trapeze': 'Трапеція',

  'rooms.Bedroom': 'Спальня',
  'rooms.LivingRoom': 'Вітальня',
  'rooms.Kitchen': 'Кухня',
  'rooms.DiningRoom': 'Столова',
  'rooms.ChildRoom': 'Дитяча',
  'rooms.Cabinet': 'Кабінет',
  'rooms.GuestRoom': 'Кімната для гостей',
  'rooms.Corridor': 'Коридор',
  'rooms.Wardrobe': 'Гардеробна',
  'rooms.Balcony': 'Балкон',
  'rooms.Loggia': 'Лоджія',
  'rooms.Terrace': 'Тераса',
  'rooms.Bathroom': 'Ванна кімната',
  'rooms.Studio': 'Студія',
  'rooms.Office': 'Офіс',
  'rooms.Gazebo': 'Альтанка',
  'rooms.Bedroom.1': 'Спальня 1',
  'rooms.Bedroom.2': 'Спальня 2',
  'rooms.Bedroom.3': 'Спальня 3',
  'rooms.Bedroom.son': 'Спальня сина',
  'rooms.Bedroom.daughter': 'Спальня доньки',
  'rooms.ChildRoom.daughter': 'Дитяча доньки',
  'rooms.ChildRoom.son': 'Дитяча сина',

  'sms.send': 'Надіслати SMS',
  'sms.not_sent': 'SMS повідомлення перебуває в черзі надсилання',
  'sms.ok_sent': 'Повідомлення надіслане, проте статус доставки ще не відомий',
  'sms.ok_delivered': 'Повідомлення доставлене',
  'sms.err_src_invalid': 'Доставка SMS неможлива: невірні параметри відправника',
  'sms.err_dest_invalid': 'Невірний номер - доставка SMS неможлива',
  'sms.err_not_allowed': 'Доставка SMS неможлива: даний оператор зв\'язку не обслуговується',
  'sms.err_delivery_failed': 'Доставка SMS не вдалась. Перевірте правильність та доступність номеру телефону й повторіть спробу.',
  'sms.err_lost': 'Доставка SMS не відбулась (втрата повідомлення). Будь-ласка повторіть спробу.',
  'sms.err_internal': 'Доставка SMS не відбулась (внутрішній збій). Будь-ласка повторіть спробу.',

  'user.createdAt': 'Дата реєстрації',

  'views.userAdministration.title': 'Користувачі',
  'views.userAdministration.searchByName': 'Пошук за прізвищем',
  'views.userAdministration.filterByRoles': 'Ролі користувача',
  'views.userAdministration.filterBySkills': 'Здібності (уточнення ролі)',
  'views.userAdministration.sortByRoleAndLastNameAsc': 'Роль та прізвище',
  'views.userAdministration.authenticatedSession': 'Сесія',
  'views.userAdministration.closeSelectedSessions': 'Закрити сесії',
  'views.userAdministration.closeSelectedSessions.promptMessage': 'Ви плануєте закрити всі активні сесії наступних користувачів:',
  'views.userAdministration.closeSelectedSessions.promptWarning': 'Після підтвердження дії зазначені користувачі для продовження роботи у застосунку потребуватимуть повторної авторизації на всіх пристроях.',
  'views.userAdministration.block': 'Заблокувати',
  'views.userAdministration.block.promptMessage': 'Ви плануєте заблокувати (принипинити всі активні сесії та деактивувати з неможливістю повтроно авторизуватись в застосунку) наступних користувачів:',
  'views.userAdministration.block.promptWarning': 'Заблоковані користувачі зникають з переліків наявних взагалі, не можуть повторно авторизуватись в застосунку, проте дані їх акаунтів ще залишаються збереженими в системі для працездатності раніше повʼязаних з ними проєктів. За потреби повністю видалити дані або відновити активність акаунту зверніться до технічного адміністратора системи.',
  'views.userAdministration.bulkOperationResultMessage.failed': 'Невдалий результат виконання операції для всіх обраних користувачів.',
  'views.userAdministration.bulkOperationResultMessage.partiallyFailed': 'Частково невдалий результат виконання операції, зокрема для: {subjects}',
  'views.userAdministration.bulkOperationResultMessage.succeeded': 'Успішно застосовано для всіх обраних користувачів.',
};

export default messages;
